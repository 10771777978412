import React, { Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import { icons } from "atoms/icons";

// functions
import { capitalizeFirstLetter } from "atoms/utils/capitalizeFirstLetter";
import { isAuthenticated } from "authentication/authenticationApi";
import { logoutUser } from "pages/actions/auth";
import { authReducer } from "reducers/authReducers";
import { baseurl } from "pages/constants";

const CompHeader = ({ open, setOpen }) => {
  const { logoutUserResult } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const arrLocation = location.pathname.split("/");
  const title = capitalizeFirstLetter(arrLocation[arrLocation.length - 1]);

  const profile = {
    name: isAuthenticated().name,
    email: isAuthenticated().email,
    image: isAuthenticated().image,
  };

  const onLogout = (e) => {
    e.preventDefault();
    logoutUser(dispatch);
  };

  useEffect(() => {
    if (logoutUserResult) {
      dispatch(authReducer({ type: "LOGOUT_USER", payload: { data: false } }));
    }
  });

  return (
    <Fragment>
      <div className="w-full flex bg-white py-2 justify-between items-center px-3 shadow-md shadow-pink-200">
        <div className="flex items-center">
          <div>
            {/* Desktop */}
            <div
              onClick={() => setOpen(!open)}
              className={`${
                open ? "bg-amber-300" : "bg-pink-200"
              } hidden sm:flex p-2 rounded-full cursor-pointer hover:scale-125 transition-all`}
            >
              <span className="m-auto">
                {open ? (
                  <div className="h-4 w-4 text-grey-700">
                    {icons.aioutlinearrowleft}
                  </div>
                ) : (
                  <div className="h-4 w-4 text-grey-700">
                    {icons.aioutlinearrowright}
                  </div>
                )}
              </span>
            </div>

            {/* Mobile */}
            <div
              onClick={() => setOpen(!open)}
              className="flex sm:hidden p-2 rounded-md cursor-pointer hover:bg-grey-500/20 transition-all"
            >
              {icons.fimenu}
            </div>
          </div>

          {/* Title */}
          <div className="ml-4 font-medium">{title ? title : "Dashboard"}</div>
        </div>
        <div>
          <button
            className="transition-all flex items-center w-10 h-10 rounded-full cursor-pointer overflow-hidden"
            id="dropdownProfile"
            data-bs-toggle="dropdown"
          >
            <img
              className="object-cover h-full w-full"
              src={
                profile.image
                  ? baseurl + profile.image
                  : process.env.PUBLIC_URL + "/assets/default.jpg"
              }
              alt="User"
            />
          </button>
          <div
            className="dropdown-menu absolute hidden bg-white text-sm z-50 py-2 rounded-lg shadow-xl border-none"
            aria-labelledby="dropdownProfile"
          >
            <div className="flex flex-col">
              <div className="py-3 px-4">
                <span className="block text-sm text-grey-800 font-medium">
                  {profile.name}
                </span>
                <span className="block text-xs font-extralight text-grey-500 truncate">
                  {profile.email}
                </span>
              </div>
              <ul>
                <li>
                  <Link
                    to="/profil"
                    className="block py-2 px-4 pr-10 text-sm text-grey-800 hover:bg-grey-100"
                  >
                    Edit Profil
                  </Link>
                </li>
                <li>
                  <Link
                    to="/profil/password"
                    className="block py-2 px-4 pr-10 text-sm text-grey-800 hover:bg-grey-100"
                  >
                    Ubah Password
                  </Link>
                </li>
                <li>
                  <button
                    onClick={onLogout}
                    className="block py-2 px-4 pr-10 text-sm text-grey-800 hover:bg-grey-100 w-full text-left"
                  >
                    Keluar
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CompHeader;
