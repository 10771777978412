import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// components
import { CompMainLayout } from "components";

// pages
import {
  DashboardPage,
  TentangPage,
  PengurusPage,
  BaganPage,
  OrganPage,
  StrataPage,
  CabangPage,
  PegawaiPage,
  PayrollPage,
  PenugasanPage,
  RekrutmenPage,
  KehadiranPage,
  KinerjaPage,
  KarirPage,
  SebaranPage,
  ProgramPage,
  KegiatanPage,
  DokumentasiKegiatanPage,
  TransaksiPage,
  RaportPage,
  GajiPage,
  TunjanganPage,
  PotonganPage,
  AdministrarifPage,
  PerseoranganPage,
  PerusahaanPage,
  LoginPage,
  ProfilePage,
  AkunPage,
  NotFoundPage,
  ForbiddenPage,
  UbahPasswordPage,
  DetailKehadiranPage,
  CutiPage,
  EditProfilePegawaiPage,
  EditProfilePribadiPage,
  EditProfileKeluargaPage,
  EditProfilePendidikanPage,
  EditProfileLainnyaPage,
  KalenderPage,
  PrivacyPolicyPage,
  ApiPage,
  CallbackPage,
} from "pages";
import PrivateRoute from "pages/Login/PrivateRoute";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="login" element={<LoginPage />} />
          <Route path="forbidden" element={<ForbiddenPage />} />
          <Route path="privacy" element={<PrivacyPolicyPage />} />
          <Route
            element={
              <PrivateRoute>
                <CompMainLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route path="perusahaan">
              <Route path="tentang" element={<TentangPage />} />
              <Route path="pengurus" element={<PengurusPage />} />
            </Route>
            <Route path="masterdata">
              <Route path="bagan" element={<BaganPage />} />
              <Route path="organ" element={<OrganPage />} />
              <Route path="strata" element={<StrataPage />} />
              <Route path="cabang" element={<CabangPage />} />
              <Route path="gaji" element={<GajiPage />} />
              <Route path="Tunjangan" element={<TunjanganPage />} />
              <Route path="potongan" element={<PotonganPage />} />
              <Route path="kalender" element={<KalenderPage />} />
            </Route>
            <Route path="kepegawaian">
              <Route path="pegawai" element={<PegawaiPage />} />
              <Route path="payroll" element={<PayrollPage />} />
              <Route path="penugasan" element={<PenugasanPage />} />
              <Route path="rekrutmen" element={<RekrutmenPage />} />
            </Route>
            <Route path="asesmen">
              <Route path="cuti" element={<CutiPage />} />
              <Route path="kehadiran" element={<KehadiranPage />} />
              <Route
                path="kehadiran/detail"
                element={<DetailKehadiranPage />}
              />
              <Route path="kinerja" element={<KinerjaPage />} />
              <Route path="karir" element={<KarirPage />} />
            </Route>
            <Route path="kompetensi">
              <Route path="sebaran" element={<SebaranPage />} />
              <Route path="program" element={<ProgramPage />} />
              <Route path="kegiatan" element={<KegiatanPage />} />
            </Route>
            <Route path="kemitraan">
              <Route path="perseorangan" element={<PerseoranganPage />} />
              <Route path="perusahaan" element={<PerusahaanPage />} />
            </Route>
            <Route path="dokumentasi">
              <Route path="administratif" element={<AdministrarifPage />} />
              <Route path="kegiatan" element={<DokumentasiKegiatanPage />} />
              <Route path="transaksi" element={<TransaksiPage />} />
              <Route path="raport" element={<RaportPage />} />
            </Route>
            <Route path="api">
              <Route path="api" element={<ApiPage />} />
              <Route path="callback" element={<CallbackPage />} />
            </Route>
            <Route path="akun" element={<AkunPage />} />
            <Route path="profil" element={<ProfilePage />} />
            <Route path="profil/pegawai" element={<EditProfilePegawaiPage />} />
            <Route path="profil/pribadi" element={<EditProfilePribadiPage />} />
            <Route
              path="profil/keluarga"
              element={<EditProfileKeluargaPage />}
            />
            <Route
              path="profil/pendidikan"
              element={<EditProfilePendidikanPage />}
            />
            <Route path="profil/lainnya" element={<EditProfileLainnyaPage />} />
            <Route path="profil/password" element={<UbahPasswordPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;
