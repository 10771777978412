import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import {
  CompButton,
  CompCardContainer,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import { icons } from "atoms/icons";

// functions
import {
  addData,
  deleteData,
  getData,
  handleInputError,
  updateData,
} from "pages/actions";
import {
  API_URL_creategaji,
  API_URL_edelgaji,
  API_URL_getgaji,
} from "pages/constants";
import { gajiReducer } from "reducers/gajiReducers";

const GajiPage = () => {
  const {
    getGajiResult,
    getGajiLoading,
    getGajiError,
    addGajiResult,
    addGajiLoading,
    deleteGajiResult,
  } = useSelector((state) => state.gaji);
  const dispatch = useDispatch();

  // pagination
  const [limit, setLimit] = useState(10);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Gaji",
  });
  const [dataColumns] = useState([
    { name: "ID", value: "pk" },
    { name: "Nama Gaji", value: "nama" },
    { name: "Nominal", value: "nominal" },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama Gaji",
      name: "nama",
      type: "search-select",
      placeholder: "Input Gaji",
      value: "",
      error: "",
      required: true,
      option: [],
    },
    {
      label: "Nominal",
      name: "nominal",
      type: "number",
      placeholder: "Input Nominal",
      value: "",
      error: "",
      required: true,
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    get({ param: "?search=" + value });
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs(
      inputs.map((item) => {
        if (item.type === "hidden") {
          return { ...item, value: "" };
        } else {
          return { ...item, value: "", error: "" };
        }
      })
    );
  };

  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs([
      {
        ...inputs[0],
        value: item.id,
      },
      {
        ...inputs[1],
        value: item.nama,
        error: "",
      },
      {
        ...inputs[2],
        value: item.nominal,
        error: "",
      },
    ]);
  };

  const doDelete = (item) => {
    deleteData(
      { dispatch, redux: gajiReducer },
      item.pk,
      API_URL_edelgaji,
      "DELETE_GAJI"
    );
  };

  const doSubmit = () => {
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputs[0].value === "") {
        addData(
          { dispatch, redux: gajiReducer },
          { nama: inputs[1].value },
          API_URL_creategaji,
          "ADD_GAJI"
        );
      } else {
        updateData(
          { dispatch, redux: gajiReducer },
          { pk: inputs[0].value, nama: inputs[1].value },
          API_URL_edelgaji,
          "ADD_GAJI"
        );
      }
    }
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: gajiReducer },
        param,
        API_URL_getgaji,
        "GET_GAJI"
      );
    },
    [dispatch]
  );

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  const handleSearchSelect = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.value;
    setInputs(newInputs);
  };

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  const fetchData = useCallback(async () => {
    setModal({ ...modal, modalOpen: false });
    get({ param: "?limit=" + limit });
  }, [modal, limit, get]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addGajiResult) {
      fetchData();
    }
  }, [addGajiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (deleteGajiResult) {
      fetchData();
    }
  }, [deleteGajiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer>
        <CompTableController title="Gaji" doSearch={doSearch} onAdd={onAdd} />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={getGajiResult.count > 0 ? getGajiResult.results : null}
            isLoading={getGajiLoading}
            isError={getGajiError}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={getGajiResult.count > 0 ? getGajiResult.count : 0}
          limit={limit}
          setLimit={handleSelect}
        />
      </CompCardContainer>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form
            onKeyPress={(e) =>
              e.key === "Enter" && !addGajiLoading && doSubmit(e)
            }
          >
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
                handleSearchSelect={handleSearchSelect}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={doSubmit}
            onLoading={addGajiLoading}
          />
        </div>
      </CompModal>
    </div>
  );
};

export default GajiPage;
