import React, { Fragment } from "react";

// components
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import { icons } from "atoms/icons";

const CompModal = ({ children, modal, setModal }) => {
  return (
    <Fragment>
      <Dialog
        open={modal.modalOpen}
        size="xl"
        dismiss={{ outsidePointerDown: false }}
        className="h-[90vh] w-full outline-none"
        handler={() => setModal({ ...modal, modalOpen: !modal.modalOpen })}
      >
        <DialogHeader className="h-[12%] text-grey-700 flex justify-between items-center">
          <div>{modal.modalTitle}</div>
          <div
            className="cursor-pointer"
            onClick={() => setModal({ ...modal, modalOpen: !modal.modalOpen })}
          >
            {icons.fix}
          </div>
        </DialogHeader>
        <DialogBody
          divider
          className="h-[76%] overflow-auto custom-scroll bg-grey-100"
        >
          {children[0]}
        </DialogBody>
        <DialogFooter className="h-[12%]">{children[1]}</DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default CompModal;
