import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import { icons } from "atoms/icons";
import { PulseLoader } from "react-spinners";

// functions
import { loginUser } from "pages/actions/auth";
import { isAuthenticated } from "authentication/authenticationApi";

const LoginPage = () => {
  const {
    loginUserResult,
    loginUserLoading,
    // loginUserError
  } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(dispatch, { username, password });
  };

  useEffect(() => {
    if (
      loginUserResult ||
      (isAuthenticated() &&
        isAuthenticated().level.filter(
          (item) => item === "Super Admin" || item === "Admin"
        ).length > 0)
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUserResult]);

  return (
    <div
      className={`h-screen w-screen flex justify-center items-center bg-center bg-cover bg-clip-border`}
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/assets/Bg-Login.png"
        })`,
      }}
    >
      <div className="px-10 py-6 m-2 border-2 border-white rounded-3xl backdrop-blur-sm bg-white/30">
        <div className="flex justify-center">
          <img
            className="h-12"
            src={process.env.PUBLIC_URL + "/assets/Logo.png"}
            alt="LogoQNN"
          />
        </div>
        <div className="flex justify-center text-base text-center md:text-2xl text-white font-bold mt-2 mb-6">
          PT. QUEEN NETWORK NUSANTARA
        </div>
        <form className="flex flex-col" onSubmit={(e) => handleSubmit(e)}>
          <div className="relative">
            <span className="absolute text-grey-400 top-5 left-8">
              {icons.fauser}
            </span>
            <input
              type="text"
              placeholder="Username"
              className="w-full pl-16 pr-10 py-4 bg-white text-grey-600 font-medium shadow-lg rounded-full mb-4 outline-none"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="relative">
            <span className="absolute text-grey-400 top-5 left-8">
              {icons.faunlock}
            </span>
            <input
              type={visiblePassword ? "text" : "password"}
              placeholder="Password"
              className="w-full pl-16 pr-10 py-4 bg-white text-grey-600 font-medium shadow-lg rounded-full mb-4 outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {
              <div
                className="absolute text-grey-500 top-4 right-6 text-2xl cursor-pointer"
                onClick={() => setVisiblePassword(!visiblePassword)}
              >
                {visiblePassword ? icons.aifilleyeinvisible : icons.aifilleye}
              </div>
            }
          </div>
          <button
            onClick={(e) => handleSubmit(e)}
            type="submit"
            className="w-full py-4 bg-[#da4fd8d8] shadow-lg text-white rounded-full outline-none font-bold active:bg-[#DA4FD8] active:shadow-md"
            disabled={loginUserLoading}
          >
            {loginUserLoading ? <PulseLoader color="#FFF" /> : <div>Login</div>}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
