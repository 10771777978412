import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import {
  CompButton,
  CompCardContainer,
  CompCardContainer2,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import { icons } from "atoms/icons";

// functions
import {
  addData,
  deleteData,
  getData,
  handleInputError,
  updateData,
} from "pages/actions";
import {
  API_URL_createdepartemen,
  API_URL_edeldepartemen,
  API_URL_getdepartemen,
} from "pages/constants";
import { departemenReducers } from "reducers/organReducers";

const DepartemenSub = () => {
  const {
    getDepartemenResult,
    getDepartemenLoading,
    getDepartemenError,
    addDepartemenResult,
    addDivisiResult,
    addUnitResult,
    addDepartemenLoading,
    deleteDepartemenResult,
    deleteDivisiResult,
    deleteUnitResult,
  } = useSelector((state) => state.organ);
  const dispatch = useDispatch();

  // pagination
  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Departemen",
  });
  const [dataColumns] = useState([
    { name: "ID", value: "pk" },
    { name: "Nama Departemen", value: "nama" },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama Departemen",
      name: "nama_departemen",
      type: "text",
      placeholder: "Input Departemen",
      value: "",
      error: "",
      required: true,
      fullWidth: true,
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({ param: "?search=" + value });
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs(
      inputs.map((item) => {
        if (item.type === "hidden") {
          return { ...item, value: "" };
        } else {
          return { ...item, value: "", error: "" };
        }
      })
    );
  };

  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs([
      {
        ...inputs[0],
        value: item.pk,
      },
      {
        ...inputs[1],
        value: item.nama,
        error: "",
      },
    ]);
  };

  const doDelete = (item) => {
    deleteData(
      { dispatch, redux: departemenReducers },
      item.pk,
      API_URL_edeldepartemen,
      "DELETE_DEPARTEMEN"
    );
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputs[0].value === "") {
        addData(
          { dispatch, redux: departemenReducers },
          { nama: inputs[1].value },
          API_URL_createdepartemen,
          "ADD_DEPARTEMEN"
        );
      } else {
        updateData(
          { dispatch, redux: departemenReducers },
          { pk: inputs[0].value, nama: inputs[1].value },
          API_URL_edeldepartemen,
          "ADD_DEPARTEMEN"
        );
      }
    }
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: departemenReducers },
        param,
        API_URL_getdepartemen,
        "GET_DEPARTEMEN"
      );
    },
    [dispatch]
  );

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  const fetchData = useCallback(
    async (param = false) => {
      setModal({ ...modal, modalOpen: false });
      get(param ? param : { param: "?limit=" + limit });
    },
    [modal, limit, get]
  );

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      addDepartemenResult ||
      addDivisiResult ||
      addUnitResult ||
      deleteDepartemenResult ||
      deleteDivisiResult ||
      deleteUnitResult
    ) {
      const offset = pageActive * limit;
      const param =
        search === ""
          ? { param: "?limit=" + limit + "&offset=" + offset }
          : {
              param:
                "?search=" + search + "&limit=" + limit + "&offset=" + offset,
            };
      fetchData(param);
    }
  }, [
    addDepartemenResult,
    addDivisiResult,
    addUnitResult,
    deleteDepartemenResult,
    deleteDivisiResult,
    deleteUnitResult,
    dispatch,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer2>
        <CompTableController
          title="Departemen"
          doSearch={doSearch}
          onAdd={onAdd}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={
              getDepartemenResult.count > 0 ? getDepartemenResult.results : null
            }
            isLoading={getDepartemenLoading}
            isError={getDepartemenError}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={
            getDepartemenResult.count > 0 ? getDepartemenResult.count : 0
          }
          limit={limit}
          pageActive={pageActive}
          setLimit={handleSelect}
        />
      </CompCardContainer2>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form
            onKeyPress={(e) =>
              e.key === "Enter" && !addDepartemenLoading && doSubmit(e)
            }
          >
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={(e) => doSubmit(e)}
            onLoading={addDepartemenLoading}
          />
        </div>
      </CompModal>
    </div>
  );
};

export default DepartemenSub;
