import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// plugins
import Swal from "sweetalert2";

// components
import { CompButton, CompCardContainer, CompForm } from "components";

// functions
import { isAuthenticated } from "authentication/authenticationApi";
import { updateData } from "pages/actions";
import { API_URL_changepassword } from "pages/constants";
import { userReducer } from "reducers/authReducers";

const UbahPasswordPage = () => {
  const { addUserResult, addUserLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState([
    {
      label: "Ubah Password",
      type: "label",
    },
    {
      label: "Password Saat Ini",
      name: "password",
      type: "password",
      value: "",
      fullWidth: true,
      password: true,
    },
    {
      label: "Password Baru",
      name: "password_baru",
      type: "password",
      value: "",
      fullWidth: true,
      password: true,
    },
    {
      label: "Konfirmasi Password Baru",
      name: "konfirmasi_password_baru",
      type: "password",
      value: "",
      fullWidth: true,
      password: true,
    },
  ]);

  //   Funtion
  const doSubmit = () => {
    if (inputs[2].value !== inputs[3].value) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: "Password baru anda tidak sama!!",
      });
    } else {
      updateData(
        { dispatch, redux: userReducer },
        {
          pk: isAuthenticated().user_id,
          old_password: inputs[1].value,
          new_password: inputs[2].value,
        },
        API_URL_changepassword,
        "ADD_USER"
      );
    }
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  useEffect(() => {
    if (addUserResult) {
      const newInputs = [...inputs];
      newInputs[1]["value"] = "";
      newInputs[2]["value"] = "";
      newInputs[3]["value"] = "";
      setInputs(newInputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserResult, dispatch]);

  return (
    <div>
      <CompCardContainer>
        <form>
          <CompForm
            inputs={inputs}
            setInputs={setInputs}
            handleInput={handleInput}
          />
        </form>
        <div className="flex justify-end items-center mt-4">
          <CompButton
            btnName={"Submit"}
            doClick={doSubmit}
            onLoading={addUserLoading}
          />
        </div>
      </CompCardContainer>
    </div>
  );
};

export default UbahPasswordPage;
