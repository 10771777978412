import React, { Fragment, useState } from "react";

// components
import { CompTabs } from "components";

// sub
import ValidasiIzinSub from "./Sub/ValidasiIzinSub";
import TerimaSub from "./Sub/TerimaSub";
import TolakSub from "./Sub/TolakSub";

const CutiPage = () => {
  const [tabs] = useState({
    idTabs: "pengajuanTabs",
    idContents: "pengajuanTabs-Content",
    listTabs: [
      {
        nameTabs: "Validasi Cuti",
        linkTabs: "CutiValidasi",
        contentTabs: <ValidasiIzinSub />,
      },
      {
        nameTabs: "Cuti Diterima",
        linkTabs: "CutiTerima",
        contentTabs: <TerimaSub />,
      },
      {
        nameTabs: "Cuti Ditolak",
        linkTabs: "CutiTolak",
        contentTabs: <TolakSub />,
      },
    ],
  });

  return (
    <Fragment>
      <CompTabs tabs={tabs} />
    </Fragment>
  );
};

export default CutiPage;
