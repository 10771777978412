import { isAuthenticated } from "authentication/authenticationApi";
import { CompButton, CompCardContainer, CompForm } from "components";
import { handleInputError, updateData } from "pages/actions";
import { API_URL_edeluser } from "pages/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pegawaiReducer } from "reducers/kepegawaianReducers";

const EditProfileKeluargaPage = () => {
  const { addPegawaiResult, addPegawaiLoading } = useSelector(
    (state) => state.kepegawaian
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [inputsDataKeluarga, setInputsDataKeluarga] = useState(
    location.state.data
  );

  //   Funtion
  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsDataKeluarga);
    setInputsDataKeluarga(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (isAuthenticated().user_id !== "") {
        updateData(
          { dispatch, redux: pegawaiReducer },
          {
            pk: "datakeluarga",
            user_id: isAuthenticated().user_id,
            nama_ayah: inputsDataKeluarga[0].value,
            nama_ibu: inputsDataKeluarga[1].value,
            status_pernikahan: inputsDataKeluarga[3].value,
            nama_pasangan: inputsDataKeluarga[4].value,
            anak: inputsDataKeluarga[5].input.map((item) => ({
              anak: item.value,
            })),
            nama_kontak_emergency: inputsDataKeluarga[7].value,
            no_telepon_emergency: inputsDataKeluarga[8].value,
          },
          API_URL_edeluser,
          "ADD_PEGAWAI"
        );
      }
    }
  };

  // Handle Form
  const HandleInputsDataKeluarga = (e, index) => {
    const newInputs = [...inputsDataKeluarga];
    if (e.target.name === "status_pernikahan") {
      if (e.target.value === "menikah") {
        newInputs.forEach((item) => {
          if (item.name === "nama_pasangan") {
            item.disabled = false;
          }
          if (item.name === "jumlah_anak") {
            item.input[0].disabled = false;
          }
        });
      } else {
        newInputs.forEach((item) => {
          let newItem;
          if (item.name === "nama_pasangan") {
            item.disabled = true;
            item.value = "";
          }
          if (item.name === "jumlah_anak") {
            newItem = { ...item.input[0], disabled: true, value: "" };
            item.input = [newItem];
          }
        });
      }
    }
    newInputs[index]["value"] = e.target.value;
    setInputsDataKeluarga(newInputs);
  };

  useEffect(() => {
    if (addPegawaiResult) {
      dispatch(
        pegawaiReducer({
          type: "ADD_PEGAWAI",
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      navigate("/profil");
    }
  }, [addPegawaiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer>
        <span
          onClick={() => navigate(-1)}
          className="px-3 py-2 items-center rounded-lg bg-[#f3f4f6] text-xs cursor-pointer text-grey-600"
        >
          Back
        </span>
        <form className="mt-4" onSubmit={doSubmit}>
          <CompForm
            inputs={inputsDataKeluarga}
            setInputs={setInputsDataKeluarga}
            handleInput={HandleInputsDataKeluarga}
          />
          <div className="flex justify-end items-center mt-4">
            <CompButton
              btnName={"Save"}
              doClick={doSubmit}
              onLoading={addPegawaiLoading}
            />
          </div>
        </form>
      </CompCardContainer>
    </div>
  );
};

export default EditProfileKeluargaPage;
