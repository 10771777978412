import React, { useState } from "react";

// components
import {
  CompButton,
  CompCardContainer,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import { icons } from "atoms/icons";

const SebaranPage = () => {
  // pagination
  const [limit, setLimit] = useState(10);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Sebaran",
  });
  const [dataColumns] = useState([
    { name: "ID", value: "id" },
    { name: "Nama Pegawai", value: "nama_pegawai" },
    { name: "Jabatan", value: "jabatan" },
    { name: "Pendidikan", value: "pendidikan" },
    { name: "Kompetensi Aksi", value: "kompetensi_aksi" },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama Pegawai",
      name: "nama_pegawai",
      type: "text",
      value: "",
    },
    {
      label: "Jabatan",
      name: "jabatan",
      type: "text",
      value: "",
    },
    {
      label: "Pendidikan",
      name: "pendidikan",
      type: "text",
      value: "",
    },
    {
      label: "Kompetensi Aksi",
      name: "kompetensi_aksi",
      type: "text",
      value: "",
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs([
      {
        ...inputs[0],
        value: "",
      },
      {
        ...inputs[1],
        value: "",
      },
      {
        ...inputs[2],
        value: "",
      },
      {
        ...inputs[3],
        value: "",
      },
      {
        ...inputs[4],
        value: "",
      },
    ]);
  };

  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    console.log("edit", item);
  };

  const doDelete = (item) => {
    console.log("delete", item.id);
  };

  const doSubmit = () => {
    console.log("submit");
  };

  const get = (param) => {
    console.log(param);
  };

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  return (
    <div>
      <CompCardContainer>
        <CompTableController
          title="Sebaran"
          doSearch={doSearch}
          onAdd={onAdd}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={[]}
            isLoading={false}
            isError={false}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={0}
          limit={limit}
          setLimit={handleSelect}
        />
      </CompCardContainer>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <CompCardContainer>
            <CompForm
              inputs={inputs}
              setInputs={setInputs}
              handleInput={handleInput}
            />
          </CompCardContainer>
        </div>
        <div className="flex justify-end items-center">
          <CompButton btnName={"Submit"} doClick={doSubmit} onLoading={false} />
        </div>
      </CompModal>
    </div>
  );
};

export default SebaranPage;
