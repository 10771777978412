import React from "react";

const CompData = ({ inputs }) => {
  return (
    <div className="grid grid-cols-2 gap-4 text-xs md:text-sm">
      {inputs &&
        inputs.map((input, inputIdx) => {
          if (input.type === "multiple-input") {
            return (
              <div key={inputIdx} className="flex flex-col">
                <label className="font-medium">{input.label}</label>
                <div className="flex flex-wrap">
                  {input.input.map((item, itemIdx) => (
                    <div
                      key={itemIdx}
                      className="flex relative items-center mt-1"
                    >
                      <div>{item.value ? item.value : "-"}</div>
                    </div>
                  ))}
                </div>
              </div>
            );
          }

          if (input.type === "group-input") {
            return (
              <div key={inputIdx} className="w-full col-span-full">
                <label className="font-bold">{input.label}</label>
                <div className="w-full grid grid-cols-3 gap-2 mt-2">
                  {input.groupInput.map((itemGroup) =>
                    itemGroup.map((item, itemIdx) => {
                      return (
                        <div
                          key={itemIdx}
                          className={`w-full col-span-full text-grey-800 ${
                            item.fullWidth ? "" : "sm:col-span-1"
                          }`}
                        >
                          <div className="font-medium">{item.label}</div>
                          {item.type === "file" ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={item.link}
                              className="text-xs text-blue-500"
                            >
                              {item.value ? item.value : "-"}
                            </a>
                          ) : (
                            <div className="truncate">
                              {item.value ? item.value : "-"}
                            </div>
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            );
          }

          if (input.type === "select") {
            return (
              <div
                key={inputIdx}
                className={`w-full col-span-full text-grey-800 ${
                  input.fullWidth ? "" : "sm:col-span-1"
                }`}
              >
                <div className="font-medium">{input.label}</div>
                <div className="truncate">
                  {input.value
                    ? input.option.find((item) => item.value === input.value)
                        ?.label
                    : "-"}
                </div>
              </div>
            );
          }

          if (input.type === "hidden") {
            return null;
          }

          return (
            <div
              key={inputIdx}
              className={`w-full col-span-full text-grey-800 ${
                input.fullWidth ? "" : "sm:col-span-1"
              }`}
            >
              <div className="font-medium">{input.label}</div>
              <div className="truncate">{input.value ? input.value : "-"}</div>
            </div>
          );
        })}
    </div>
  );
};

export default CompData;
