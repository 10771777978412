import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import {
  CompButton,
  CompCardContainer,
  CompCardContainer2,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import { icons } from "atoms/icons";

// functions
import {
  addData,
  deleteData,
  getData,
  handleInputError,
  updateData,
} from "pages/actions";
import {
  API_URL_createunit,
  API_URL_edelunit,
  API_URL_getunit,
  API_URL_getspesifikdivisi,
  API_URL_getmasterpegawai,
} from "pages/constants";
import { unitReducers } from "reducers/organReducers";
import axiosAPI from "authentication/axiosApi";

const UnitSub = () => {
  const {
    getUnitResult,
    getUnitLoading,
    getUnitError,
    addDepartemenResult,
    addDivisiResult,
    addUnitResult,
    addUnitLoading,
    deleteDepartemenResult,
    deleteDivisiResult,
    deleteUnitResult,
  } = useSelector((state) => state.organ);
  const dispatch = useDispatch();

  // pagination
  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Unit",
  });
  const [dataColumns] = useState([
    { name: "ID", value: "pk" },
    { name: "Nama Departemen", value: "divisi.departemen.nama" },
    { name: "Nama Divisi", value: "divisi.nama" },
    { name: "Nama Unit", value: "nama" },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "pk",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama Unit",
      name: "nama_unit",
      type: "text",
      placeholder: "Input unit",
      value: "",
      error: "",
      required: true,
      fullWidth: true,
    },
    {
      label: "Nama Departemen",
      name: "departemen",
      type: "select",
      placeholder: "Input Departemen",
      value: "",
      error: "",
      required: true,
      option: [],
    },
    {
      label: "Nama Divisi",
      name: "divisi",
      type: "select",
      placeholder: "Input divisi",
      value: "",
      error: "",
      required: true,
      option: [],
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({ param: "?search=" + value });
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs(
      inputs.map((item) => {
        if (item.type === "hidden") {
          return { ...item, value: "" };
        } else {
          return { ...item, value: "", error: "" };
        }
      })
    );
  };

  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs([
      {
        ...inputs[0],
        value: item.pk,
      },
      {
        ...inputs[1],
        value: item.nama,
        error: "",
      },
      {
        ...inputs[2],
        value: item.divisi.departemen.id,
        error: "",
      },
      {
        ...inputs[3],
        value: item.divisi.id,
        error: "",
      },
    ]);
  };

  const doDelete = (item) => {
    deleteData(
      { dispatch, redux: unitReducers },
      item.pk,
      API_URL_edelunit,
      "DELETE_UNIT"
    );
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputs[0].value === "") {
        addData(
          { dispatch, redux: unitReducers },
          { nama: inputs[1].value, divisi_id: inputs[3].value },
          API_URL_createunit,
          "ADD_UNIT"
        );
      } else {
        updateData(
          { dispatch, redux: unitReducers },
          {
            pk: inputs[0].value,
            nama: inputs[1].value,
            divisi_id: inputs[3].value,
          },
          API_URL_edelunit,
          "ADD_UNIT"
        );
      }
    }
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: unitReducers },
        param,
        API_URL_getunit,
        "GET_UNIT"
      );
    },
    [dispatch]
  );

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  // Handle Form
  const handleInput = (e, index) => {
    if (index === 2) {
      axiosAPI.get(API_URL_getspesifikdivisi + e.target.value).then((res) => {
        const Inputs = [...inputs];
        Inputs[3]["option"] = res.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        setInputs(Inputs);
      });
    }
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  const fetchData = useCallback(
    async (param = false) => {
      setModal({ ...modal, modalOpen: false });
      get(param ? param : { param: "?limit=" + limit });

      const res_pegawai = await axiosAPI.get(API_URL_getmasterpegawai);
      const input = [...inputs];
      input.forEach((item, index) => {
        if (
          ["departemen", "divisi"].some(
            (itemMaster) => itemMaster === item.name
          )
        ) {
          input[index]["option"] = res_pegawai.data[item.name].map((item) => ({
            value: item.pk,
            label: item.nama,
          }));
        }
      });
    },
    [modal, limit, inputs, get]
  );

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      addDepartemenResult ||
      addDivisiResult ||
      addUnitResult ||
      deleteDepartemenResult ||
      deleteDivisiResult ||
      deleteUnitResult
    ) {
      const offset = pageActive * limit;
      const param =
        search === ""
          ? { param: "?limit=" + limit + "&offset=" + offset }
          : {
              param:
                "?search=" + search + "&limit=" + limit + "&offset=" + offset,
            };
      fetchData(param);
    }
  }, [
    addDepartemenResult,
    addDivisiResult,
    addUnitResult,
    deleteDepartemenResult,
    deleteDivisiResult,
    deleteUnitResult,
    dispatch,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer2>
        <CompTableController title="Unit" doSearch={doSearch} onAdd={onAdd} />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={getUnitResult.count > 0 ? getUnitResult.results : null}
            isLoading={getUnitLoading}
            isError={getUnitError}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={getUnitResult.count > 0 ? getUnitResult.count : 0}
          limit={limit}
          setLimit={handleSelect}
          pageActive={pageActive}
        />
      </CompCardContainer2>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form
            onKeyPress={(e) =>
              e.key === "Enter" && !addUnitLoading && doSubmit(e)
            }
          >
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={(e) => doSubmit(e)}
            onLoading={addUnitLoading}
          />
        </div>
      </CompModal>
    </div>
  );
};

export default UnitSub;
