import React, { Fragment, useState } from "react";
import { CompTabs } from "components";
import InformasiSakitSub from "./Sub/InformasiSakitSub";
import PengajuanIzinSub from "./Sub/PengajuanIzinSub";
import PengajuanCutiSub from "./Sub/PengajuanCutiSub";
import PengajuanTugasSub from "./Sub/PengajuanTugasSub";

const AdministratifPage = () => {
  const [tabs] = useState({
    idTabs: "administratifTabs",
    idContents: "administratifTabs-Content",
    listTabs: [
      {
        nameTabs: "Informasi Sakit",
        linkTabs: "administratifInformasiSakit",
        contentTabs: <InformasiSakitSub />,
      },
      {
        nameTabs: "Pengajuan Izin",
        linkTabs: "administratifPengajuanIzin",
        contentTabs: <PengajuanIzinSub />,
      },
      {
        nameTabs: "Pengajuan Cuti",
        linkTabs: "administratifPengajuanCuti",
        contentTabs: <PengajuanCutiSub />,
      },
      {
        nameTabs: "Pengajuan Tugas",
        linkTabs: "administratifPengajuanTugas",
        contentTabs: <PengajuanTugasSub />,
      },
    ],
  });

  return (
    <Fragment>
      <CompTabs tabs={tabs} />
    </Fragment>
  );
};

export default AdministratifPage;
