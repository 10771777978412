import { Dialog, DialogBody } from "@material-tailwind/react";
import {
  CompButton,
  CompCardContainer,
  CompCardContainer2,
  CompForm,
  CompModal,
} from "components";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { icons } from "atoms/icons";
import {
  API_URL_createabsensi,
  API_URL_getdetailkehadiran,
  baseurl,
} from "pages/constants";
import { updateFormData } from "pages/actions";
import { useDispatch, useSelector } from "react-redux";
import { userReducer } from "reducers/authReducers";
import { PulseLoader } from "react-spinners";
import moment from "moment";
import "moment/locale/id";
import axiosAPI from "authentication/axiosApi";

const DetailKehadiranPage = () => {
  const { addUserAbsensiResult, addUserAbsensiLoading } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [dataTables, setDataTables] = useState([]);
  const [modalEdit, setModalEdit] = useState({
    modalOpen: false,
    modalTitle: "Edit Kehadiran",
  });
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Detail Kehadiran",
  });
  const [detail, setDetail] = useState({});

  const [inputs, setInputs] = useState([
    {
      label: "Tanggal",
      name: "tanggal",
      type: "datetime-local",
      placeholder: "Input Tanggal",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Tipe Absen",
      name: "tipe_absen",
      type: "select",
      placeholder: "Pilih Tipe Absen",
      option: [
        { label: "Masuk", value: "masuk" },
        { label: "Keluar", value: "keluar" },
      ],
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Latitude",
      name: "latitude",
      type: "text",
      placeholder: "Input Latitude",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Longitude",
      name: "longitude",
      type: "text",
      placeholder: "Input Longitude",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Gambar",
      name: "gambar",
      type: "file",
      data: "",
      placeholder: "Input Gambar",
      value: "",
      error: "",
      required: true,
    },
  ]);

  const showDetail = (item) => {
    setModal({
      ...modal,
      modalOpen: !modal.modalOpen,
      modalTitle: moment(item.tanggal).format("dddd, DD MMMM YYYY"),
    });
    setDetail(item);
  };

  const onEdit = () => {
    setModalEdit({ ...modalEdit, modalOpen: !modalEdit.modalOpen });
    setInputs(
      inputs.map((item) => {
        if (item.type === "file") {
          return { ...item, value: "", data: "", error: "" };
        } else {
          return { ...item, value: "", error: "" };
        }
      })
    );
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const locationNow = {
      latitude: inputs[2].value,
      longitude: inputs[3].value,
    };

    const formData = new FormData();
    formData.append("waktu", inputs[0].value);
    formData.append("tipe_absen", inputs[1].value);
    formData.append("lokasi", JSON.stringify(locationNow));
    formData.append("image", inputs[4].data);
    updateFormData(
      { dispatch, redux: userReducer },
      formData,
      API_URL_createabsensi,
      "ADD_ABSENSI",
      location.state.user_id
    );
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    if (e.target.type === "file") {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result;

        newInputs[index]["data"] = base64String;
        // Logs wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(e.target.files[0]);
      newInputs[index]["value"] = e.target.value;
      setInputs(newInputs);
    } else {
      newInputs[index]["value"] = e.target.value;
      setInputs(newInputs);
    }
  };

  const fetchData = useCallback(async () => {
    setModalEdit({ ...modalEdit, modalOpen: false });
    setLoading(true);
    axiosAPI
      .post(API_URL_getdetailkehadiran, {
        "date-month": location.state.date,
        user_id: location.state.user_id,
      })
      .then((res) => {
        setLoading(false);
        setDataTables(res.data);
      })
      .catch(function (error) {
        setLoading(false);
        setError(error);
      });
  }, [modalEdit, location]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (addUserAbsensiResult) {
      fetchData();
    }
  }, [addUserAbsensiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CompCardContainer>
        <div className="flex justify-between items-center">
          <span
            onClick={() => navigate(-1)}
            className="px-3 py-2 items-center rounded-lg bg-[#f3f4f6] text-xs cursor-pointer text-grey-600"
          >
            Back
          </span>
          <div className="text-grey-600 cursor-pointer" onClick={onEdit}>
            {icons.fiedit}
          </div>
        </div>

        {/* Data */}
        <div className="pt-4 pb-2 w-auto">
          <div className="overflow-x-auto custom-scroll">
            <table className="w-full">
              <thead className="bg-transparent border-b border-grey-300">
                <tr>
                  <th className="text-xs text-left text-grey-800 p-2">
                    Tanggal
                  </th>
                  <th colSpan={3} className="text-xs text-grey-800 p-3">
                    Masuk
                  </th>
                  <th colSpan={3} className="text-xs text-grey-800 p-2">
                    Keluar
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataTables.length > 0 ? (
                  dataTables.map((item, itemIdx) => (
                    <tr
                      key={itemIdx}
                      className="bg-transparent md:text-sm border-b border-grey-300 transition duration-300 ease-in-out"
                    >
                      <td className="text-xs p-2 whitespace-nowrap text-grey-800">
                        {item.tanggal}
                      </td>
                      <td className="text-xs text-center p-2 whitespace-nowrap text-grey-800">
                        {item.waktu_masuk ? item.waktu_masuk : "-"}
                      </td>
                      <td className="text-xs p-2 text-left text-grey-800">
                        {item.alamat_masuk}
                      </td>
                      <td className="text-xs text-center p-2 whitespace-nowrap text-grey-800">
                        {item.waktu_keluar ? item.waktu_keluar : "-"}
                      </td>
                      <td className="text-xs p-2 text-left text-grey-800">
                        {item.alamat_keluar}
                      </td>
                      <td className="text-xs text-center p-2 text-grey-800">
                        <button
                          className="cursor-pointer flex text-base text-blue-500"
                          onClick={() => showDetail(item)}
                        >
                          {icons.aifilleye}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : loading ? (
                  <tr>
                    <td className="text-center py-5" colSpan={7}>
                      <div className="pt-10 pb-6 flex justify-center items-center">
                        <PulseLoader loading={loading} color="#111827" />
                      </div>
                    </td>
                  </tr>
                ) : error ? (
                  <tr>
                    <td className="text-center" colSpan={7}>
                      <div className="pt-10 pb-6 flex justify-center items-center text-xs text-red-500">
                        {error}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      className="text-center whitespace-nowrap text-grey-800"
                      colSpan={7}
                    >
                      <div className="pt-10 pb-6 flex justify-center items-center">
                        <div>
                          <img
                            className="w-32"
                            src={process.env.PUBLIC_URL + "/assets/nodata.svg"}
                            alt="LogoQNN"
                          />
                          <div className="font-medium mt-2 text-xs md:text-sm">
                            No Data
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </CompCardContainer>

      {/* Modal Image */}
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <CompCardContainer>
            <div className="flex justify-between items-center font-medium mb-2">
              <div>Masuk</div>
              <div>{detail.waktu_masuk}</div>
            </div>
            <div className="text-justify mb-2">
              Catatan : {detail.catatan_masuk ? detail.catatan_masuk : "-"}
            </div>
            <div className="text-justify mb-2">
              Alamat : {detail.alamat_masuk ? detail.alamat_masuk : "-"}
            </div>
            {detail.image_masuk && (
              <a
                href={baseurl + detail.image_masuk}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block"
              >
                <img
                  className="object-cover w-20 h-20 rounded-md "
                  src={baseurl + detail.image_masuk}
                  alt="masuk"
                />
              </a>
            )}
          </CompCardContainer>
          <br />
          <CompCardContainer>
            <div className="flex justify-between items-center font-medium mb-2">
              <div>Keluar</div>
              <div>{detail.waktu_keluar}</div>
            </div>
            <div className="text-justify mb-2">
              Catatan : {detail.catatan_keluar ? detail.catatan_keluar : "-"}
            </div>
            <div className="text-justify mb-2">
              Alamat : {detail.alamat_keluar ? detail.alamat_keluar : "-"}
            </div>
            {detail.image_keluar && (
              <a
                href={baseurl + detail.image_keluar}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block"
              >
                <img
                  className="object-cover w-20 h-20 rounded-md "
                  src={baseurl + detail.image_keluar}
                  alt="keluar"
                />
              </a>
            )}
          </CompCardContainer>
          <br />
        </div>
        <></>
      </CompModal>

      {/* Modal Edit */}
      <CompModal modal={modalEdit} setModal={setModalEdit}>
        <div className="w-full">
          <form onKeyPress={(e) => e.key === "Enter" && !false && doSubmit(e)}>
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={doSubmit}
            onLoading={addUserAbsensiLoading}
          />
        </div>
      </CompModal>
    </Fragment>
  );
};

export default DetailKehadiranPage;
