import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";

// components
import { MdKeyboardArrowRight } from "react-icons/md";
import { menuItem } from "atoms/menuItem";
import { isAuthenticated } from "authentication/authenticationApi";

const CompSidebar = ({ open, setOpen }) => {
  const initNav = {
    perusahaan: false,
    masterdata: false,
    kepegawaian: false,
    asesmen: false,
    kompetensi: false,
    kemitraan: false,
    dokumentasi: false,
  };
  const [navopen, setNavopen] = useState(initNav);

  const navOpen = (data) => {
    setNavopen({ ...initNav, [data]: !navopen[data] });
  };
  return (
    <Fragment>
      <div
        onClick={() => setOpen(!open)}
        className={`fixed w-screen h-screen z-20 sm:hidden ${
          open ? "" : "hidden"
        }`}
      ></div>
      <div
        className={`${
          open
            ? "w-52 bg-gradient-to-b from-gradient-yellow-100 via-gradient-pink-100 to-gradient-purple-100"
            : "w-16 bg-gradient-yellow-100 hidden sm:block"
        } transition-all ease-in-out duration-150 fixed z-50 sm:relative`}
      >
        <div className={`${open ? "ml-2" : " ml-0"}`}>
          <div className="py-5 flex items-center justify-center">
            <img
              className={`${open ? "" : "hidden"} h-16`}
              src={process.env.PUBLIC_URL + "/assets/LogoQNN.png"}
              alt="LogoQNN"
            />
            <img
              className={`${open ? "hidden" : "p-2"} h-16`}
              src={process.env.PUBLIC_URL + "/assets/Logo.svg"}
              alt="LogoQNN"
            />
          </div>

          <aside>
            <div
              id="accordionSidebar"
              className={`${
                open ? "border-r-0" : "border-r-0"
              } accordion overflow-y-auto hidden-scroll py-4 px-3 bg-white/30 border-2 border-white/70 rounded-tl-3xl h-[83vh]`}
            >
              <ul className="space-y-2">
                {menuItem.map((menu, menuIdx) => (
                  <li key={menuIdx}>
                    {(menu.isSuperAdmin && isAuthenticated().superuser) ||
                    !menu.isSuperAdmin ? (
                      <>
                        {menu.subMenu.length === 0 ? (
                          <NavLink
                            to={menu.menuLink}
                            className={`flex items-center px-2 py-1 text-white rounded-md hover:bg-white hover:text-grey-800`}
                          >
                            <span
                              className={`${
                                open ? "text-lg" : "text-2xl"
                              } transition duration-75`}
                            >
                              {menu.menuIcon}
                            </span>
                            <span
                              className={`${open ? "" : "hidden"} ml-3 text-sm`}
                            >
                              {menu.menuName}
                            </span>
                          </NavLink>
                        ) : (
                          <Fragment>
                            <button
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${menu.menuLink}`}
                              aria-expanded={navOpen[menu.menuLink]}
                              aria-controls={menu.menuLink}
                              className={`${
                                navopen[menu.menuLink]
                                  ? "bg-white text-grey-800"
                                  : "text-white"
                              } collapsed flex items-center px-2 py-1 w-full text-base font-normal rounded-lg transition duration-75 group hover:bg-white hover:text-grey-800`}
                              onClick={() => navOpen(menu.menuLink)}
                            >
                              <div
                                className={`${
                                  open ? "text-lg" : "text-2xl"
                                }  transition duration-75 group-hover:text-grey-800`}
                              >
                                {menu.menuIcon}
                              </div>
                              <span
                                className={`${
                                  open ? "" : "hidden"
                                } flex-1 ml-3 text-left whitespace-nowrap text-sm`}
                              >
                                {menu.menuName}
                              </span>
                              <MdKeyboardArrowRight
                                className={`${
                                  navopen[menu.menuLink] ? "rotate-90" : ""
                                } w-6 h6 transition-transform`}
                              />
                            </button>
                            <ul
                              id={menu.menuLink}
                              data-bs-parent="#accordionSidebar"
                              className={`${
                                open
                                  ? ""
                                  : "absolute z-50 text-white bg-gradient-to-b from-gradient-yellow-100 via-gradient-pink-100 to-gradient-purple-100 left-16 ml-2 rounded-md py-2"
                              } ${
                                navopen[menu.menuLink] ? "show opacity-100" : ""
                              } transition-all opacity-0 duration-300 accordion-collapse collapse`}
                            >
                              {menu.subMenu.map((subMenu, subMenuIdx) => (
                                <li key={subMenuIdx}>
                                  {subMenu.isSuperAdmin &&
                                  !isAuthenticated().superuser ? null : (
                                    <NavLink
                                      to={subMenu.subMenuLink}
                                      className={`${
                                        open
                                          ? "ml-7 pl-[10px] text-white rounded-lg my-1"
                                          : "text-whitehite rounded-none m-0 w-28"
                                      } flex items-center px-3 py-1 text-sm transition duration-75 group hover:bg-white hover:text-grey-800`}
                                    >
                                      {subMenu.subMenuName}
                                    </NavLink>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </Fragment>
                        )}
                      </>
                    ) : null}
                  </li>
                ))}
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </Fragment>
  );
};
export default CompSidebar;
