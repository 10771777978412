import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import {
  CompButton,
  CompCardContainer,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import { icons } from "atoms/icons";

// functions
import {
  addData,
  deleteData,
  getData,
  getItem,
  updateData,
} from "pages/actions";
import {
  API_URL_changeactive,
  API_URL_changeoutofarea,
  API_URL_changepassworduser,
  API_URL_createuser,
  API_URL_edeluser,
  API_URL_getdataakun,
  API_URL_getmasterpegawai,
  API_URL_getspesifikdivisi,
  API_URL_getspesifikjabatan,
  API_URL_getspesifikunit,
} from "pages/constants";
import { userReducer } from "reducers/authReducers";
import Swal from "sweetalert2";
import axiosAPI from "authentication/axiosApi";

const AkunPage = () => {
  const {
    getDataAkunResult,
    getDataAkunLoading,
    getDataAkunError,
    addAkunResult,
    addAkunLoading,
    deleteAkunResult,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // pagination
  const [firstFetch, setFirstFetch] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Akun",
  });

  const [modalPassword, setModalPassword] = useState({
    modalOpen: false,
    modalTitle: "Ubah Password",
  });

  // Tables
  const [dataColumns] = useState([
    { name: "ID", value: "datapribadi.user_id" },
    { name: "Nama Pegawai", value: "datapribadi.nama" },
    { name: "Username", value: "datapribadi.username" },
    { name: "Jabatan", value: "datapegawai.jabatan.nama" },
    { name: "Email", value: "datapribadi.email" },
    { name: "Nomor Telepon", value: "datapribadi.no_telepon" },
    { name: "Active", value: "datapribadi.is_staff", switch: true },
    { name: "Out of Area", value: "datapribadi.out_of_area", switch: true },
    // { name: "Terakhir Login", value: "last_login" },
  ]);

  // Form

  const [inputsPassword, setInputsPassword] = useState([
    {
      label: "ID",
      name: "pk",
      type: "hidden",
      value: "",
    },
    {
      label: "Password Baru",
      name: "password_baru",
      type: "password",
      value: "",
      fullWidth: true,
      password: true,
    },
    {
      label: "Konfirmasi Password Baru",
      name: "konfirmasi_password_baru",
      type: "password",
      value: "",
      fullWidth: true,
      password: true,
    },
  ]);

  const [inputsPegawai, setInputsPegawai] = useState([
    {
      label: "Pangkat",
      name: "pangkat",
      type: "select",
      placeholder: "Input Pangkat",
      value: "",
      option: [],
    },
    {
      label: "Jabatan",
      name: "jabatan",
      type: "select",
      placeholder: "Input Jabatan",
      value: "",
      option: [],
    },
    {
      label: "Departemen",
      name: "departemen",
      type: "select",
      placeholder: "Input Departemen",
      value: "",
      option: [],
    },
    {
      label: "Divisi",
      name: "divisi",
      type: "select",
      placeholder: "Input Divisi",
      value: "",
      option: [],
    },
    {
      label: "Unit",
      name: "unit",
      type: "select",
      placeholder: "Input Unit",
      value: "",
      option: [],
    },
  ]);

  const [inputsPribadi, setInputsPribadi] = useState([
    {
      label: "ID",
      name: "pk",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama",
      name: "nama",
      placeholder: "Input Nama",
      type: "text",
      value: "",
    },
    {
      label: "Email",
      name: "email",
      placeholder: "Input Email",
      type: "email",
      value: "",
    },
    {
      label: "Username",
      name: "username",
      placeholder: "Input Username",
      type: "text",
      value: "",
    },
  ]);

  // Function
  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: userReducer },
        param,
        API_URL_getdataakun,
        "GET_AKUN"
      );
    },
    [dispatch]
  );

  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
  };

  const onChange = (data) => {
    setModalPassword({ ...modalPassword, modalOpen: !modal.modalOpen });
    setInputsPassword(
      inputsPassword.map((item, index) => ({
        ...item,
        value: index === 0 ? data.datapribadi.user_id : "",
      }))
    );
  };

  // const onAdd = () => {
  //   setModal({ ...modal, modalOpen: !modal.modalOpen });
  //   setInputsPribadi(inputsPribadi.map((item) => ({ ...item, value: "" })));
  //   setInputsPegawai(inputsPegawai.map((item) => ({ ...item, value: "" })));
  // };

  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputsPribadi([
      {
        ...inputsPribadi[0],
        value: item.datapribadi.user_id,
      },
      {
        ...inputsPribadi[1],
        value: item.datapribadi.nama,
      },
      {
        ...inputsPribadi[2],
        value: item.datapribadi.email,
      },
      {
        ...inputsPribadi[3],
        value: item.datapribadi.username,
      },
    ]);
    const newInputsPegawai = inputsPegawai;
    newInputsPegawai[0].value = getItem("datapegawai.pangkat.id", item);
    newInputsPegawai[1].value = getItem("datapegawai.jabatan.id", item);
    newInputsPegawai[2].value = getItem("datapegawai.departemen.id", item);
    newInputsPegawai[3].value = getItem("datapegawai.divisi.id", item);
    newInputsPegawai[4].value = getItem("datapegawai.unit.id", item);
    setInputsPegawai(newInputsPegawai);
  };

  const doDelete = (item) => {
    deleteData(
      { dispatch, redux: userReducer },
      item.datapribadi.user_id,
      API_URL_edeluser,
      "DELETE_AKUN"
    );
  };

  const doChange = () => {
    if (inputsPassword[1].value !== inputsPassword[2].value) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
          container: "z-[99999]",
        },
        text: "Password baru anda tidak sama!!",
      });
    } else {
      updateData(
        { dispatch, redux: userReducer },
        {
          pk: inputsPassword[0].value,
          new_password: inputsPassword[1].value,
        },
        API_URL_changepassworduser,
        "ADD_AKUN"
      );
    }
  };

  const doSubmit = () => {
    if (inputsPribadi[0].value === "") {
      addData(
        { dispatch, redux: userReducer },
        {
          nama: inputsPribadi[1].value,
          email: inputsPribadi[2].value,
          username: inputsPribadi[3].value,
          jabatan_id: inputsPegawai[1].value,
          unit_id: inputsPegawai[4].value,
        },
        API_URL_createuser,
        "ADD_AKUN"
      );
    } else {
      updateData(
        { dispatch, redux: userReducer },
        {
          pk: inputsPribadi[0].value,
          nama: inputsPribadi[1].value,
          email: inputsPribadi[2].value,
          username: inputsPribadi[3].value,
          jabatan_id: inputsPegawai[1].value,
          unit_id: inputsPegawai[4].value,
        },
        API_URL_edeluser,
        "ADD_AKUN"
      );
    }
  };

  const handleSwitch = (e, item, index) => {
    if (index === 6) {
      updateData(
        { dispatch, redux: userReducer },
        {
          pk: item.datapribadi.user_id,
          is_staff: e.target.checked,
        },
        API_URL_changeactive,
        "ADD_AKUN"
      );
    } else if (index === 7) {
      updateData(
        { dispatch, redux: userReducer },
        {
          pk: item.datapribadi.user_id,
          out_of_area: e.target.checked,
        },
        API_URL_changeoutofarea,
        "ADD_AKUN"
      );
    }
  };

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const HandleInputsPassword = (e, index) => {
    const newInputs = [...inputsPassword];
    newInputs[index]["value"] = e.target.value;
    setInputsPassword(newInputs);
  };

  const HandleInputsPribadi = (e, index) => {
    const newInputs = [...inputsPribadi];
    newInputs[index]["value"] = e.target.value;
    setInputsPribadi(newInputs);
  };

  const HandleInputsPegawai = (e, index) => {
    if (e.target.name === "pangkat") {
      axiosAPI.get(API_URL_getspesifikjabatan + e.target.value).then((res) => {
        const Inputs = [...inputsPegawai];
        Inputs[1]["option"] = res.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        setInputsPegawai(Inputs);
      });
    } else if (e.target.name === "departemen") {
      axiosAPI.get(API_URL_getspesifikdivisi + e.target.value).then((res) => {
        const Inputs = [...inputsPegawai];
        Inputs[3]["option"] = res.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        setInputsPegawai(Inputs);
      });
    } else if (e.target.name === "divisi") {
      axiosAPI.get(API_URL_getspesifikunit + e.target.value).then((res) => {
        const Inputs = [...inputsPegawai];
        Inputs[4]["option"] = res.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        setInputsPegawai(Inputs);
      });
    }
    const newInputs = [...inputsPegawai];
    newInputs[index]["value"] = e.target.value;
    setInputsPegawai(newInputs);
  };

  const fetchData = useCallback(
    async (param = false) => {
      get(param ? param : { param: "?limit=" + limit });
      setModal({ ...modal, modalOpen: false });
      setModalPassword({ ...modalPassword, modalOpen: false });
      const newPegawai = [...inputsPegawai];
      const res_pegawai = await axiosAPI.get(API_URL_getmasterpegawai);
      newPegawai.forEach((item, index) => {
        newPegawai[index]["option"] = res_pegawai.data[item.name].map(
          (item) => ({
            value: item.pk,
            label: item.nama,
          })
        );
      });
      setInputsPegawai(newPegawai);
    },
    [get]
  );

  // useEffect
  useEffect(() => {
    fetchData();
    setFirstFetch(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addAkunResult || deleteAkunResult) {
      const offset = pageActive * limit;
      const param =
        search === ""
          ? { param: "?limit=" + limit + "&offset=" + offset }
          : {
              param:
                "?search=" + search + "&limit=" + limit + "&offset=" + offset,
            };
      fetchData(param);
    }
  }, [addAkunResult, deleteAkunResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (firstFetch) {
      const getData = setTimeout(() => {
        get({ param: "?search=" + search });
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [search]);

  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "changepassword",
      icon: icons.fakey,
      color: "text-yellow-500",
      func: onChange,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  return (
    <div>
      <CompCardContainer>
        <CompTableController doSearch={doSearch} />
        <div className="mt-2">
          <CompTable
            handleSwitch={handleSwitch}
            dataColumns={dataColumns}
            dataTables={
              getDataAkunResult.count > 0 ? getDataAkunResult.results : null
            }
            isLoading={getDataAkunLoading}
            isError={getDataAkunError}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={getDataAkunResult.count > 0 ? getDataAkunResult.count : 0}
          limit={limit}
          setLimit={handleSelect}
          pageActive={pageActive}
        />
      </CompCardContainer>

      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form onSubmit={doSubmit}>
            <CompCardContainer title={"Data Pribadi"}>
              <CompForm
                inputs={inputsPribadi}
                setInputs={setInputsPribadi}
                handleInput={HandleInputsPribadi}
              />
            </CompCardContainer>
            <br />
            <CompCardContainer title={"Data Pegawai"}>
              <CompForm
                inputs={inputsPegawai}
                setInputs={setInputsPegawai}
                handleInput={HandleInputsPegawai}
              />
            </CompCardContainer>
            <br />
          </form>
        </div>
        <div className="w-full flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={doSubmit}
            onLoading={addAkunLoading}
          />
        </div>
      </CompModal>

      <CompModal modal={modalPassword} setModal={setModalPassword}>
        <div className="w-full">
          <form onSubmit={doChange}>
            <CompCardContainer>
              <CompForm
                inputs={inputsPassword}
                setInputs={setInputsPassword}
                handleInput={HandleInputsPassword}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="w-full flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={doChange}
            onLoading={addAkunLoading}
          />
        </div>
      </CompModal>
    </div>
  );
};

export default AkunPage;
