import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { icons } from "atoms/icons";
import {
  CompButton,
  CompCardContainer,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";

// functions
import axiosAPI from "authentication/axiosApi";
import {
  addData,
  convertJSON,
  deleteData,
  getData,
  handleInputError,
  updateData,
} from "pages/actions";
import {
  API_URL_createcallback,
  API_URL_edelcallback,
  API_URL_getapiclientall,
  API_URL_getcallback,
  API_URL_gettypecallback,
} from "pages/constants";
import { callbackReducer } from "reducers/apiReducers";

const CallbackPage = () => {
  const {
    getCallbackResult,
    getCallbackLoading,
    getCallbackError,
    addCallbackResult,
    addCallbackLoading,
    deleteCallbackResult,
  } = useSelector((state) => state.api);
  const dispatch = useDispatch();

  // pagination
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  // States & Variables
  const [detail, setDetail] = useState({});
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "API",
  });
  const [modalDetail, setModalDetail] = useState({
    modalOpen: false,
    modalTitle: "Detail API",
  });
  const [dataColumns] = useState([
    { name: "No", value: "no" },
    { name: "Key", value: "key" },
    { name: "URL", value: "url" },
    { name: "Type Callback", value: "type.type" },
    { name: "Api Client", value: "api.nama_client" },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Api Key",
      name: "key",
      type: "text",
      placeholder: "Input Api Key",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "URL",
      name: "url",
      type: "text",
      placeholder: "Input URL",
      value: "",
      error: "",
    },
    {
      label: "Type Callback",
      name: "type",
      type: "search-select",
      placeholder: "Input Type Callback",
      value: "",
      error: "",
      option: [],
      required: true,
    },
    {
      label: "Api Client",
      name: "api",
      type: "search-select",
      placeholder: "Input Api Client",
      value: "",
      error: "",
      option: [],
      required: true,
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    get({ param: "?search=" + value });
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs(
      inputs.map((item) => {
        if (item.type === "hidden") {
          return { ...item, value: "" };
        } else {
          return { ...item, value: "", error: "" };
        }
      })
    );
  };

  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    const newInput = inputs.map((input, index) => {
      if (index === 3) {
        return {
          ...input,
          value: { value: item[input.name].id, label: item[input.name].type },
        };
      }
      if (index === 4) {
        return {
          ...input,
          value: {
            value: item[input.name].id,
            label: item[input.name].nama_client,
          },
        };
      }
      return { ...input, value: item[input.name] };
    });
    console.log(newInput);
    setInputs(newInput);
  };

  const doDelete = (item) => {
    deleteData(
      { dispatch, redux: callbackReducer },
      item.id,
      API_URL_edelcallback,
      "DELETE_CALLBACK"
    );
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputs[0].value === "") {
        addData(
          { dispatch, redux: callbackReducer },
          {
            key: inputs[1].value,
            url: inputs[2].value,
            type_id: inputs[3].value.value,
            api_id: inputs[4].value.value,
          },
          API_URL_createcallback,
          "ADD_CALLBACK"
        );
      } else {
        updateData(
          { dispatch, redux: callbackReducer },
          {
            pk: inputs[0].value,
            key: inputs[1].value,
            url: inputs[2].value,
            type_id: inputs[3].value.value,
            api_id: inputs[4].value.value,
          },
          API_URL_edelcallback,
          "ADD_CALLBACK"
        );
      }
    }
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: callbackReducer },
        param,
        API_URL_getcallback,
        "GET_CALLBACK"
      );
    },
    [dispatch]
  );

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    setOffset(offset);
    get(param);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
  };

  const getType = async () => {
    try {
      const newInputs = [...inputs];
      const res = await axiosAPI.get(API_URL_gettypecallback);
      newInputs[3]["option"] = res.data.map((item) => ({
        value: item.id,
        label: item.type,
      }));

      setInputs(newInputs);
    } catch (error) {
      console.log(error);
    }
  };

  const getApiClient = async () => {
    try {
      const newInputs = [...inputs];
      const res = await axiosAPI.get(API_URL_getapiclientall);
      newInputs[4]["option"] = res.data.map((item) => ({
        value: item.id,
        label: item.nama_client,
      }));

      setInputs(newInputs);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };
  const handleSearchSelect = (value, index) => {
    const newInputs = [...inputs];
    newInputs[index].value = value;
    setInputs(newInputs);
  };

  const fetchData = useCallback(async () => {
    setModal({ ...modal, modalOpen: false });
    get({ param: "?limit=" + limit });

    getType();
    getApiClient();
  }, [modal, limit, get]);

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addCallbackResult) {
      fetchData();
    }
  }, [addCallbackResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (deleteCallbackResult) {
      fetchData();
    }
  }, [deleteCallbackResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer>
        <CompTableController
          title="Potongan"
          doSearch={doSearch}
          onAdd={onAdd}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={
              getCallbackResult.count > 0 ? getCallbackResult.results : null
            }
            isLoading={getCallbackLoading}
            isError={getCallbackError}
            actions={actions}
            modal={modal}
            offset={offset}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={getCallbackResult.count > 0 ? getCallbackResult.count : 0}
          limit={limit}
          setLimit={handleSelect}
        />
      </CompCardContainer>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form
            onKeyPress={(e) =>
              e.key === "Enter" && !addCallbackLoading && doSubmit(e)
            }
          >
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
                handleSearchSelect={handleSearchSelect}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={doSubmit}
            onLoading={addCallbackLoading}
          />
        </div>
      </CompModal>

      <CompModal modal={modalDetail} setModal={setModalDetail}>
        <div className="w-full">
          <CompCardContainer>
            <div className="grid grid-cols-2 mb-2">
              <div className="">
                <div className="font-medium">Key</div>
                <div className="">{detail?.key ? detail?.key : "-"}</div>
              </div>
              <div className="">
                <div className="font-medium">Nama Client</div>
                <div className="">
                  {detail?.nama_client ? detail?.nama_client : "-"}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 mb-2">
              <div className="">
                <div className="font-medium">Keterangan</div>
                <div className="">
                  {detail?.keterangan ? detail?.keterangan : "-"}
                </div>
              </div>
              <div className="">
                <div className="font-medium">Perusahaan Access</div>
                <div className="">
                  {detail?.perusahaan?.map(
                    (item, idx) => `${idx !== 0 ? ", " : ""}${item.nama}`
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className="font-medium mb-2">Api Access</div>

              {detail?.api?.map((item, idx) => (
                <div className="border-2 mb-2 p-2 rounded-lg text-sm">
                  <div className="grid grid-cols-2 mb-2">
                    <div>
                      <div className="font-medium">Api</div>
                      <div>{item?.api ? item?.api : "-"}</div>
                    </div>
                    <div>
                      <div className="font-medium">method</div>
                      <div>{item?.method}</div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 mb-2">
                    <div>
                      <div className="font-medium">Url</div>
                      <div>{item?.url ? item?.url : "-"}</div>
                    </div>
                    <div>
                      <div className="font-medium">
                        {convertJSON(item.params).length > 0
                          ? "Params"
                          : "Data"}
                      </div>
                      <div>
                        {convertJSON(item.params).length > 0
                          ? convertJSON(item.params).map(
                              (item, idx) => `${idx !== 0 ? ", " : ""}${item}`
                            )
                          : convertJSON(item.data).map(
                              (item, idx) => `${idx !== 0 ? ", " : ""}${item}`
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </CompCardContainer>
        </div>
        <></>
      </CompModal>
    </div>
  );
};

export default CallbackPage;
