import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import {
  CompCardContainer,
  CompForm,
  CompTable,
  CompTableController,
  CompButton,
  CompPagination,
} from "components";
import { icons } from "atoms/icons";

// functions
import {
  addData,
  convertJSON,
  deleteData,
  getData,
  getItem,
  handleInputError,
  updateData,
  updateFormData,
} from "pages/actions";
import {
  API_URL_getdatapegawai,
  API_URL_getmasterpegawai,
  API_URL_createuser,
  API_URL_edeluser,
  baseurl,
  API_URL_getcabang,
  // API_URL_getspesifikjabatan,
  API_URL_getspesifikdivisi,
  API_URL_getspesifikunit,
} from "pages/constants";
import { pegawaiReducer } from "reducers/kepegawaianReducers";
import {
  Dialog,
  DialogBody,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import {
  FaCalendarAlt,
  FaUser,
  FaUserGraduate,
  FaUserPlus,
  FaUsers,
  FaUserTie,
} from "react-icons/fa";
import axiosAPI from "authentication/axiosApi";

const PegawaiPage = () => {
  const {
    getPegawaiResult,
    getPegawaiLoading,
    getPegawaiError,
    addPegawaiResult,
    addPegawaiLoading,
    deletePegawaiResult,
  } = useSelector((state) => state.kepegawaian);
  const dispatch = useDispatch();

  // pagination
  const [firstFetch, setFirstFetch] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);
  const [offset, setOffset] = useState(0);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Pegawai",
    modalType: "add",
  });

  // Tables
  const [dataColumns] = useState([
    { name: "No", value: "no" },
    { name: "ID Pegawai", value: "datapegawai.id_pegawai" },
    { name: "Nama Pegawai", value: "datapribadi.nama" },
    { name: "Pangkat", value: "datapegawai.pangkat.nama" },
    { name: "Jabatan", value: "datapegawai.jabatan.nama" },
    { name: "Departemen", value: "datapegawai.departemen.nama" },
    { name: "Divisi", value: "datapegawai.divisi.nama" },
    { name: "Unit", value: "datapegawai.unit.nama" },
  ]);

  // Form

  const [inputsDataPribadi, setInputsDataPribadi] = useState([
    {
      label: "ID",
      name: "user_id",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama",
      name: "nama",
      type: "text",
      value: "",
      // required: true,
    },
    {
      label: "Username",
      name: "username",
      type: "text",
      value: "",
      // required: true,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      value: "",
      // required: true,
    },
    {
      label: "No Identitas (KTP)",
      name: "no_identitas",
      type: "text",
      value: "",
    },
    {
      label: "Jenis Kelamin",
      name: "jenis_kelamin",
      type: "select",
      placeholder: "Pilih Jenis Kelamin",
      value: "",
      option: [
        { label: "Laki-laki", value: "Laki-laki" },
        { label: "Perempuan", value: "Perempuan" },
      ],
      // required: true,
    },
    {
      label: "No Telepon",
      name: "no_telepon",
      type: "text",
      value: "",
      // required: true,
    },
    {
      label: "Tempat Lahir",
      name: "tempat_lahir",
      type: "text",
      value: "",
    },
    {
      label: "Tanggal Lahir",
      name: "tgl_lahir",
      type: "date",
      value: "",
    },
    {
      label: "Agama",
      name: "agama",
      type: "select",
      placeholder: "Pilih Agama",
      value: "",
      option: [
        { label: "Islam", value: "Islam" },
        { label: "Protestan", value: "Protestan" },
        { label: "Katolik", value: "Katolik" },
        { label: "Hindu", value: "Hindu" },
        { label: "Buddha", value: "Buddha" },
        { label: "Khonghucu", value: "Khonghucu" },
      ],
      // required: true,
    },
    {
      label: "NPWP",
      name: "npwp",
      type: "text",
      value: "",
    },
    {
      label: "Alamat (KTP)",
      name: "alamat_ktp",
      type: "text",
      value: "",
    },
    {
      label: "Alamat (Domisili)",
      name: "alamat_domisili",
      type: "text",
      value: "",
    },
    {
      label: "Cabang",
      name: "cabang.id",
      type: "select",
      placeholder: "Pilih Cabang",
      value: "",
      option: [],
      required: true,
    },
    {
      label: "Titik Lokasi",
      name: "titik_lokasi",
      type: "search-select",
      value: "",
      error: "",
      isMulti: true,
      option: [],
      // required: true,
      isSuperAdmin: true,
    },
  ]);

  const [inputsPegawai, setInputsPegawai] = useState([
    {
      label: "ID Pegawai",
      name: "id_pegawai",
      type: "text",
      placeholder: "Input Id Pegawai",
      value: "",
      required: true,
    },
    {
      label: "Pangkat",
      name: "pangkat.id",
      type: "select",
      placeholder: "Input Pangkat",
      value: "",
      option: [],
      // required: true,
    },
    {
      label: "Jabatan",
      name: "jabatan.id",
      type: "select",
      placeholder: "Input Jabatan",
      value: "",
      option: [],
      // required: true,
    },
    {
      label: "Departemen",
      name: "departemen.id",
      type: "select",
      placeholder: "Input Departemen",
      value: "",
      option: [],
      // required: true,
    },
    {
      label: "Divisi",
      name: "divisi.id",
      type: "select",
      placeholder: "Input Divisi",
      value: "",
      option: [],
      // required: true,
    },
    {
      label: "Unit",
      name: "unit.id",
      type: "select",
      placeholder: "Input Unit",
      value: "",
      option: [],
      // required: true,
    },
    {
      label: "Tanggal Bergabung",
      name: "tgl_bergabung",
      type: "date",
      value: "",
      // required: true,
    },
    {
      label: "Tanggal Resign",
      name: "tgl_resign",
      type: "date",
      value: "",
    },
  ]);

  const [inputsDataKeluarga, setInputsDataKeluarga] = useState([
    {
      label: "Nama Ayah",
      name: "nama_ayah",
      type: "text",
      value: "",
    },
    {
      label: "Nama Ibu",
      name: "nama_ibu",
      type: "text",
      value: "",
    },
    {
      label: "Status",
      type: "label",
    },
    {
      label: "Status Pernikahan",
      name: "status_pernikahan",
      type: "select",
      value: "belum menikah",
      fullWidth: true,
      option: [
        {
          label: "Menikah",
          value: "menikah",
        },
        {
          label: "Belum Menikah",
          value: "belum menikah",
        },
        {
          label: "Cerai Hidup",
          value: "cerai hidup",
        },
        {
          label: "Cerai Meninggal",
          value: "cerai meninggal",
        },
      ],
    },
    {
      label: "Nama Pasangan",
      name: "nama_pasangan",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      label: "Anak",
      name: "jumlah_anak",
      type: "multiple-input",
      input: [
        {
          label: "Nama Anak",
          name: "nama_anak",
          type: "text",
          value: "",
          disabled: true,
        },
      ],
    },
    {
      label: "Emergency Kontak",
      type: "label",
    },
    {
      label: "Nama Kontak",
      name: "nama_kontak_emergency",
      type: "text",
      value: "",
    },
    {
      label: "Nomor Telepon",
      name: "no_telepon_emergency",
      type: "text",
      value: "",
    },
  ]);

  const [inputsDataPendidikan, setInputsDataPendidikan] = useState([
    {
      label: "Pendidikan Formal",
      type: "group-input",
      addButton: true,
      groupInput: [
        [
          {
            label: "Asal Sekolah",
            name: "asal_sekolah",
            type: "text",
            value: "",
          },
          {
            label: "Masa Waktu",
            name: "masa_waktu",
            type: "text",
            value: "",
          },
          {
            label: "Keterangan Pendidikan",
            name: "keterangan_pendidikan",
            type: "text",
            value: "",
          },
        ],
      ],
    },
    {
      label: "Pendidikan Non Formal",
      type: "group-input",
      addButton: true,
      groupInput: [
        [
          {
            label: "Nama Lembaga",
            name: "nama_lembaga",
            type: "text",
            value: "",
          },
          {
            label: "Tahun Lulus",
            name: "tahun_lulus",
            type: "text",
            value: "",
          },
          {
            label: "Sertifikat",
            name: "sertifikat",
            type: "file",
            data: null,
            value: "",
            link: "",
          },
        ],
      ],
    },
  ]);

  const [inputsDataLainnya, setInputsDataLainnya] = useState([
    {
      label: "Input data lainnya",
      type: "group-input",
      addButton: true,
      groupInput: [
        [
          {
            name: "data_lainnya",
            type: "file",
            value: "",
            data: null,
            fullWidth: true,
            link: "",
          },
        ],
      ],
    },
  ]);

  const [inputsDataJamMasuk, setInputsDataJamMasuk] = useState([
    {
      label: "Senin",
      name: "senin",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Selasa",
      name: "selasa",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Rabu",
      name: "rabu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Kamis",
      name: "kamis",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Jumat",
      name: "jumat",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Sabtu",
      name: "sabtu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Minggu",
      name: "minggu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
  ]);

  const [inputsDataJamKeluar, setInputsDataJamKeluar] = useState([
    {
      label: "Senin",
      name: "senin",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Selasa",
      name: "selasa",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Rabu",
      name: "rabu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Kamis",
      name: "kamis",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Jumat",
      name: "jumat",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Sabtu",
      name: "sabtu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Minggu",
      name: "minggu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen, modalType: "add" });
    setInputsDataPribadi(
      inputsDataPribadi.map((item) => ({ ...item, value: "" }))
    );

    setInputsPegawai(inputsPegawai.map((item) => ({ ...item, value: "" })));

    setInputsDataKeluarga(
      inputsDataKeluarga.map((item) => ({ ...item, value: "" }))
    );
    const InputKeluarga = [...inputsDataKeluarga];
    inputsDataKeluarga.forEach((item, i) => {
      if (item.type !== "label") {
        if (item.name === "nama_pasangan") {
          InputKeluarga[i]["value"] = "";
          InputKeluarga[i]["disabled"] = true;
        } else if (item.name === "jumlah_anak") {
          InputKeluarga[i].input = [
            {
              ...inputsDataKeluarga[i].input[0],
              value: "",
              disabled: true,
            },
          ];
        } else {
          InputKeluarga[i]["value"] = "";
        }
      }
    });
    setInputsDataKeluarga(InputKeluarga);

    const InputPendidikan = [...inputsDataPendidikan];
    inputsDataPendidikan.forEach((item, index) => {
      const newItem = item["groupInput"][0].map((subItem) => {
        if (subItem.name === "sertifikat") {
          return { ...subItem, value: "", data: null, link: "" };
        } else {
          return { ...subItem, value: "" };
        }
      });
      InputPendidikan[index]["groupInput"] = [newItem];
    });
    setInputsDataPendidikan(InputPendidikan);

    const InputLainnya = [...inputsDataLainnya];
    inputsDataLainnya.forEach((item, index) => {
      const newItem = item["groupInput"][0].map((subItem) => {
        return { ...subItem, value: "", data: null, link: "" };
      });
      InputLainnya[index]["groupInput"] = [newItem];
    });
    setInputsDataLainnya(InputLainnya);
  };
  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen, modalType: "edit" });

    const datapribadi = (data) => {
      if (data) {
        setInputsDataPribadi(
          inputsDataPribadi.map((item) => ({
            ...item,
            value: getItem(item.name, data),
          }))
        );
      } else {
        setInputsDataPribadi(
          inputsDataPribadi.map((item) => ({ ...item, value: "" }))
        );
      }
    };

    const datapegawai = (data) => {
      if (data) {
        setInputsPegawai(
          inputsPegawai.map((item) => ({
            ...item,
            value: getItem(item.name, data),
          }))
        );
      } else {
        setInputsPegawai(inputsPegawai.map((item) => ({ ...item, value: "" })));
      }
    };

    const datakeluarga = (data) => {
      if (data) {
        const InputKeluarga = [...inputsDataKeluarga];
        inputsDataKeluarga.forEach((item, i) => {
          if (item.type !== "label") {
            if (data.status_pernikahan === "menikah") {
              if (item.name === "nama_pasangan") {
                InputKeluarga[i]["value"] = getItem(item.name, data);
                InputKeluarga[i]["disabled"] = false;
              } else if (item.name === "jumlah_anak") {
                let anak = convertJSON(data.anak);
                InputKeluarga[i].input = anak.map((itemAnak) => ({
                  ...InputKeluarga[i].input[0],
                  value: itemAnak.anak,
                  disabled: false,
                }));
              } else {
                InputKeluarga[i]["value"] = getItem(item.name, data);
              }
            } else {
              if (item.name === "nama_pasangan") {
                InputKeluarga[i]["value"] = "";
                InputKeluarga[i]["disabled"] = true;
              } else if (item.name === "jumlah_anak") {
                InputKeluarga[i].input = [
                  {
                    ...inputsDataKeluarga[i].input[0],
                    value: "",
                    disabled: true,
                  },
                ];
              } else {
                InputKeluarga[i]["value"] = getItem(item.name, data);
              }
            }
          }
        });
        setInputsDataKeluarga(InputKeluarga);
      } else {
        const InputKeluarga = [...inputsDataKeluarga];
        inputsDataKeluarga.forEach((item, i) => {
          if (item.type !== "label") {
            if (item.name === "nama_pasangan") {
              InputKeluarga[i]["value"] = "";
              InputKeluarga[i]["disabled"] = false;
            } else if (item.name === "jumlah_anak") {
              InputKeluarga[i].input = [
                {
                  ...inputsDataKeluarga[i].input[0],
                  value: "",
                  disabled: false,
                },
              ];
            } else {
              InputKeluarga[i]["value"] = "";
            }
          }
        });
        setInputsDataKeluarga(InputKeluarga);
      }
    };

    const datapendidikan = (data) => {
      if (data) {
        const InputPendidikan = [...inputsDataPendidikan];
        const newData = (dataPendidikan, index) => {
          const newItem = inputsDataPendidikan[index]["groupInput"][0].map(
            (item) => {
              if (item.name === "sertifikat") {
                return { ...item, value: "", data: null, link: "" };
              } else {
                return { ...item, value: "" };
              }
            }
          );
          let itemGroup = dataPendidikan.map((item) => [...newItem]);
          dataPendidikan.forEach((item, index) => {
            itemGroup[index] = itemGroup[index].map((itemSub, indexSub) => {
              if (itemSub.name === "sertifikat") {
                return {
                  ...itemSub,
                  link:
                    item[itemSub.name] === ""
                      ? ""
                      : baseurl + item[itemSub.name],
                };
              } else {
                return { ...itemSub, value: item[itemSub.name] };
              }
            });
          });
          InputPendidikan[index]["groupInput"] = itemGroup;
        };
        const formal = convertJSON(data.formal);
        const non_formal = convertJSON(data.non_formal);
        newData(formal, 0);
        newData(non_formal, 1);
        setInputsDataPendidikan(InputPendidikan);
      } else {
        const InputPendidikan = [...inputsDataPendidikan];
        inputsDataPendidikan.forEach((item, index) => {
          const newItem = item["groupInput"][0].map((subItem) => {
            if (subItem.name === "sertifikat") {
              return { ...subItem, value: "", data: null, link: "" };
            } else {
              return { ...subItem, value: "" };
            }
          });
          InputPendidikan[index]["groupInput"] = [newItem];
        });
        setInputsDataPendidikan(InputPendidikan);
      }
    };

    const datalainnya = (data) => {
      if (data) {
        const InputLainnya = [...inputsDataLainnya];
        const files = convertJSON(data.data);
        const newItem = inputsDataLainnya[0]["groupInput"][0].map((item) => {
          return { ...item, value: "", data: null, link: "" };
        });
        let itemGroup = files.map((item) => [...newItem]);
        files.forEach((item, index) => {
          itemGroup[index] = itemGroup[index].map((itemSub, indexSub) => {
            return {
              ...itemSub,
              link: item.data === "" ? "" : baseurl + item.data,
            };
          });
        });
        InputLainnya[0]["groupInput"] = itemGroup;
        setInputsDataLainnya(InputLainnya);
      } else {
        const InputLainnya = [...inputsDataLainnya];
        inputsDataLainnya.forEach((item, index) => {
          const newItem = item["groupInput"][0].map((subItem) => {
            return { ...subItem, value: "", data: null, link: "" };
          });
          InputLainnya[index]["groupInput"] = [newItem];
        });
        setInputsDataLainnya(InputLainnya);
      }
    };

    const datajadwal = (data) => {
      if (data) {
        setInputsDataJamMasuk(
          inputsDataJamMasuk.map((item) => ({
            ...item,
            value: convertJSON(data.jadwal)[item.name].masuk,
          }))
        );
        setInputsDataJamKeluar(
          inputsDataJamKeluar.map((item) => ({
            ...item,
            value: convertJSON(data.jadwal)[item.name].keluar,
          }))
        );
      } else {
        setInputsDataJamMasuk(
          inputsDataJamMasuk.map((item) => ({ ...item, value: "" }))
        );
        setInputsDataJamKeluar(
          inputsDataJamKeluar.map((item) => ({ ...item, value: "" }))
        );
      }
    };

    datapribadi(item.datapribadi);
    datapegawai(item.datapegawai);
    datakeluarga(item.datakeluarga);
    datapendidikan(item.datapendidikan);
    datalainnya(item.datalainnya);
    datajadwal(item.datajadwal);
  };
  const doDelete = (item) => {
    deleteData(
      { dispatch, redux: pegawaiReducer },
      item.datapribadi.user_id,
      API_URL_edeluser,
      "DELETE_PEGAWAI"
    );
  };

  const doSubmitPribadi = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsDataPribadi);
    setInputsDataPribadi(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputsDataPribadi[0].value === "") {
        addData(
          { dispatch, redux: pegawaiReducer },
          {
            nama: inputsDataPribadi[1].value,
            username: inputsDataPribadi[2].value,
            email: inputsDataPribadi[3].value,
            no_identitas: inputsDataPribadi[4].value,
            jenis_kelamin: inputsDataPribadi[5].value,
            no_telepon: inputsDataPribadi[6].value,
            tempat_lahir: inputsDataPribadi[7].value,
            tgl_lahir: inputsDataPribadi[8].value,
            agama: inputsDataPribadi[9].value,
            npwp: inputsDataPribadi[10].value,
            alamat_ktp: inputsDataPribadi[11].value,
            alamat_domisili: inputsDataPribadi[12].value,
            cabang_id: inputsDataPribadi[13].value,
            titik_lokasi: JSON.stringify(inputsDataPribadi[14].value),
          },
          API_URL_createuser,
          "ADD_PEGAWAI"
        );
      } else {
        updateData(
          { dispatch, redux: pegawaiReducer },
          {
            pk: "datapribadi",
            user_id: inputsDataPribadi[0].value,
            nama: inputsDataPribadi[1].value,
            username: inputsDataPribadi[2].value,
            email: inputsDataPribadi[3].value,
            no_identitas: inputsDataPribadi[4].value,
            jenis_kelamin: inputsDataPribadi[5].value,
            no_telepon: inputsDataPribadi[6].value,
            tempat_lahir: inputsDataPribadi[7].value,
            tgl_lahir: inputsDataPribadi[8].value,
            agama: inputsDataPribadi[9].value,
            npwp: inputsDataPribadi[10].value,
            alamat_ktp: inputsDataPribadi[11].value,
            alamat_domisili: inputsDataPribadi[12].value,
            cabang_id: inputsDataPribadi[13].value,
            titik_lokasi: JSON.stringify(inputsDataPribadi[14].value),
          },
          API_URL_edeluser,
          "ADD_PEGAWAI"
        );
      }
    }
  };
  const doSubmitPegawai = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsPegawai);
    setInputsPegawai(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputsDataPribadi[0].value !== "") {
        updateData(
          { dispatch, redux: pegawaiReducer },
          {
            pk: "datapegawai",
            user_id: inputsDataPribadi[0].value,
            id_pegawai: inputsPegawai[0].value,
            pangkat_id: inputsPegawai[1].value,
            jabatan_id: inputsPegawai[2].value,
            departemen_id: inputsPegawai[3].value,
            divisi_id: inputsPegawai[4].value,
            unit_id: inputsPegawai[5].value,
            tgl_bergabung: inputsPegawai[6].value,
            tgl_resign: inputsPegawai[7].value,
          },
          API_URL_edeluser,
          "ADD_PEGAWAI"
        );
      }
    }
  };
  const doSubmitKeluarga = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsDataKeluarga);
    setInputsDataKeluarga(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputsDataPribadi[0].value !== "") {
        updateData(
          { dispatch, redux: pegawaiReducer },
          {
            pk: "datakeluarga",
            user_id: inputsDataPribadi[0].value,
            nama_ayah: inputsDataKeluarga[0].value,
            nama_ibu: inputsDataKeluarga[1].value,
            status_pernikahan: inputsDataKeluarga[3].value,
            nama_pasangan: inputsDataKeluarga[4].value,
            anak: inputsDataKeluarga[5].input.map((item) => ({
              anak: item.value,
            })),
            nama_kontak_emergency: inputsDataKeluarga[7].value,
            no_telepon_emergency: inputsDataKeluarga[8].value,
          },
          API_URL_edeluser,
          "ADD_PEGAWAI"
        );
      }
    }
  };
  const doSubmitPendidikan = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsDataPendidikan);
    setInputsDataPendidikan(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputsDataPribadi[0].value !== "") {
        const formData = new FormData();
        formData.append("user_id", inputsDataPribadi[0].value);
        formData.append(
          "formal",
          JSON.stringify(
            inputsDataPendidikan[0].groupInput.map((item) => ({
              asal_sekolah: item[0].value,
              masa_waktu: item[1].value,
              keterangan_pendidikan: item[2].value,
            }))
          )
        );
        formData.append(
          "non_formal",
          JSON.stringify(
            inputsDataPendidikan[1].groupInput.map((item) => ({
              nama_lembaga: item[0].value,
              tahun_lulus: item[1].value,
              sertifikat: item[2].data
                ? ""
                : item[2].link
                ? item[2].link
                : null,
            }))
          )
        );
        inputsDataPendidikan[1].groupInput.map((item) =>
          formData.append(
            "sertifikat",
            item[2].data ? item[2].data : item[2].link
          )
        );
        updateFormData(
          { dispatch, redux: pegawaiReducer },
          formData,
          API_URL_edeluser,
          "ADD_PEGAWAI",
          "datapendidikan"
        );
      }
    }
  };
  const doSubmitLainnya = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsDataLainnya);
    setInputsDataLainnya(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputsDataPribadi[0].value !== "") {
        const formData = new FormData();
        formData.append("user_id", inputsDataPribadi[0].value);
        formData.append(
          "lainnya",
          JSON.stringify(
            inputsDataLainnya[0].groupInput.map((item) => ({
              lainnya: item[0].data ? "" : item[0].link ? item[0].link : null,
            }))
          )
        );
        inputsDataLainnya[0].groupInput.map((item) =>
          formData.append(
            "datalainnya",
            item[0].data ? item[0].data : item[0].link
          )
        );
        updateFormData(
          { dispatch, redux: pegawaiReducer },
          formData,
          API_URL_edeluser,
          "ADD_PEGAWAI",
          "datalainnya"
        );
      }
    }
  };
  const doSubmitJadwal = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsDataPribadi);
    setInputsDataPribadi(newInput);
    const err = newInput.filter((item) => item.error !== "");

    const newInputMasuk = handleInputError(inputsDataJamMasuk);
    setInputsDataJamMasuk(newInputMasuk);
    const err1 = newInputMasuk.filter((item) => item.error !== "");
    const newInputKeluar = handleInputError(inputsDataJamKeluar);
    setInputsDataJamKeluar(newInputKeluar);
    const err2 = newInputKeluar.filter((item) => item.error !== "");
    if (err.length === 0 || err1.length === 0 || err2.length === 0) {
      if (inputsDataPribadi[0].value !== "") {
        updateData(
          { dispatch, redux: pegawaiReducer },
          {
            pk: "datajadwal",
            user_id: inputsDataPribadi[0].value,
            jadwal: JSON.stringify({
              senin: {
                masuk: inputsDataJamMasuk[0].value,
                keluar: inputsDataJamKeluar[0].value,
              },
              selasa: {
                masuk: inputsDataJamMasuk[1].value,
                keluar: inputsDataJamKeluar[1].value,
              },
              rabu: {
                masuk: inputsDataJamMasuk[2].value,
                keluar: inputsDataJamKeluar[2].value,
              },
              kamis: {
                masuk: inputsDataJamMasuk[3].value,
                keluar: inputsDataJamKeluar[3].value,
              },
              jumat: {
                masuk: inputsDataJamMasuk[4].value,
                keluar: inputsDataJamKeluar[4].value,
              },
              sabtu: {
                masuk: inputsDataJamMasuk[5].value,
                keluar: inputsDataJamKeluar[5].value,
              },
              minggu: {
                masuk: inputsDataJamMasuk[6].value,
                keluar: inputsDataJamKeluar[6].value,
              },
            }),
          },
          API_URL_edeluser,
          "ADD_PEGAWAI"
        );
      }
    }
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: pegawaiReducer },
        param,
        API_URL_getdatapegawai,
        "GET_PEGAWAI"
      );
    },
    [dispatch]
  );

  // Handle Form
  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    setOffset(offset);
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
    setPageActive(e.selected);
  };
  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
    setPageActive(0);
  };
  const handleSearchSelect = (value, index) => {
    const newInputs = [...inputsDataPribadi];
    newInputs[index]["value"] = value;
    setInputsDataPribadi(newInputs);
  };
  const HandleInputsDataPribadi = (e, index) => {
    const { value, name } = e.target;
    const newInputs = [...inputsDataPribadi];
    if (newInputs[0].value === "") {
      if (name === "nama") {
        newInputs[2]["value"] = value.toLowerCase().replaceAll(" ", "");
      }
    }
    newInputs[index]["value"] = value;
    setInputsDataPribadi(newInputs);
  };
  const HandleInputsPegawai = async (e, index) => {
    if (index === 3) {
      const Inputs = [...inputsPegawai];
      Inputs[index]["value"] = e.target.value;
      if (e.target.value !== "") {
        const res_data = await axiosAPI.get(
          API_URL_getspesifikdivisi + e.target.value
        );
        Inputs[4]["option"] = await res_data.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        Inputs[4]["option"].unshift({
          value: "",
          label: "Tidak ada data",
        });
        Inputs[4]["value"] = "";
        Inputs[5]["option"] = [];
        Inputs[5]["value"] = "";
      } else {
        Inputs[4]["option"] = [];
        Inputs[4]["value"] = "";
        Inputs[5]["option"] = [];
        Inputs[5]["value"] = "";
      }
      setInputsPegawai(Inputs);
    } else if (index === 4) {
      const Inputs = [...inputsPegawai];
      Inputs[index]["value"] = e.target.value;
      if (e.target.value !== "") {
        const res_data = await axiosAPI.get(
          API_URL_getspesifikunit + e.target.value
        );
        Inputs[5]["option"] = await res_data.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        Inputs[5]["option"].unshift({
          value: "",
          label: "Tidak ada data",
        });
        Inputs[5]["value"] = "";
      } else {
        Inputs[5]["option"] = [];
        Inputs[5]["value"] = "";
      }
      setInputsPegawai(Inputs);
    } else {
      const newInputs = [...inputsPegawai];
      newInputs[index]["value"] = e.target.value;
      setInputsPegawai(newInputs);
    }
  };
  const HandleInputsDataKeluarga = (e, index) => {
    const newInputs = [...inputsDataKeluarga];
    if (e.target.name === "status_pernikahan") {
      if (e.target.value !== "belum menikah") {
        newInputs.forEach((item) => {
          if (item.name === "nama_pasangan") {
            item.disabled = false;
          }
          if (item.name === "jumlah_anak") {
            item.input[0].disabled = false;
          }
        });
      } else {
        newInputs.forEach((item) => {
          let newItem;
          if (item.name === "nama_pasangan") {
            item.disabled = true;
            item.value = "";
          }
          if (item.name === "jumlah_anak") {
            newItem = { ...item.input[0], disabled: true, value: "" };
            item.input = [newItem];
          }
        });
      }
    }
    newInputs[index]["value"] = e.target.value;
    setInputsDataKeluarga(newInputs);
  };
  const handleJamMasuk = (e, index) => {
    const newInputs = [...inputsDataJamMasuk];
    newInputs[index]["value"] = e.target.value;
    console.log(e.target.value);
    setInputsDataJamMasuk(newInputs);
  };
  const handleJamKeluar = (e, index) => {
    const newInputs = [...inputsDataJamKeluar];
    newInputs[index]["value"] = e.target.value;
    setInputsDataJamKeluar(newInputs);
  };

  const fetchData = useCallback(
    async (param = false) => {
      setModal({ ...modal, modalOpen: false, modalType: "add" });
      get(param ? param : { param: "?limit=" + limit });
      const masterPegawai = [
        "id_pegawai",
        "pangkat",
        "jabatan",
        "departemen",
        "divisi",
        "unit",
      ];
      const newPegawai = [...inputsPegawai];
      const res_pegawai = await axiosAPI.get(API_URL_getmasterpegawai);
      masterPegawai.forEach((item, index) => {
        if (item !== "id_pegawai") {
          newPegawai[index]["option"] = res_pegawai.data[item].map((item) => ({
            value: item.pk,
            label: item.nama,
          }));
          newPegawai[index]["option"].unshift({
            value: "",
            label: "Tidak ada data",
          });
        }
      });
      setInputsPegawai(newPegawai);
      const newPribadi = [...inputsDataPribadi];
      const res_cabang = await axiosAPI.get(API_URL_getcabang);
      newPribadi[13]["option"] = res_cabang.data.map((item) => ({
        value: item.pk,
        label: item.nama,
      }));
      newPribadi[14]["option"] = res_cabang.data.map((item) => ({
        value: item.pk,
        label: item.nama,
      }));
      setInputsDataPribadi(newPribadi);
    },
    [modal, inputsPegawai, inputsDataPribadi, limit, get]
  );

  // useEffect
  useEffect(() => {
    fetchData();
    setFirstFetch(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addPegawaiResult || deletePegawaiResult) {
      const offset = pageActive * limit;
      const param =
        search === ""
          ? { param: "?limit=" + limit + "&offset=" + offset }
          : {
              param:
                "?search=" + search + "&limit=" + limit + "&offset=" + offset,
            };
      fetchData(param);
    }
  }, [addPegawaiResult, deletePegawaiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (firstFetch) {
      const getData = setTimeout(() => {
        get({ param: "?search=" + search });
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [search]);

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  return (
    <div>
      <CompCardContainer>
        <CompTableController
          title="Pegawai"
          doSearch={doSearch}
          onAdd={onAdd}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={
              getPegawaiResult.count > 0 ? getPegawaiResult.results : null
            }
            isLoading={getPegawaiLoading}
            isError={getPegawaiError}
            actions={actions}
            modal={modal}
            setModal={setModal}
            offset={offset}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={getPegawaiResult.count > 0 ? getPegawaiResult.count : 0}
          limit={limit}
          setLimit={handleSelect}
          pageActive={pageActive}
        />
      </CompCardContainer>

      <Dialog
        size="xl"
        open={modal.modalOpen}
        className="h-[95%] max-w-full w-[90%] sm:w-[80%] outline-none"
        handler={() => setModal({ ...modal, modalOpen: !modal.modalOpen })}
      >
        <DialogBody
          divider
          className="h-full overflow-auto custom-scroll p-0 border-none"
        >
          <Tabs className="flex w-full" value="pribadi">
            <div className="w-1/4">
              <div className="bg-gradient-yellow-100 h-full shadow text-left">
                <div className="text-xs sm:text-xl font-bold text-white flex justify-center py-3">
                  Detail Karyawan
                </div>
                <div className="h-full w-full bg-white/30 border-2 border-white/70 rounded-l-lg ml-2">
                  <TabsHeader className="w-full flex flex-col bg-transparent p-3">
                    <Tab
                      className="justify-items-start text-[8px] sm:text-sm md:text-base font-medium"
                      value="pribadi"
                    >
                      <FaUser className="float-left mx-2 mt-1" /> Pribadi
                    </Tab>
                    <Tab
                      disabled={modal.modalType === "edit" ? false : true}
                      className="justify-items-start text-[8px] sm:text-sm md:text-base font-medium"
                      value="pegawai"
                    >
                      <FaUserTie className="float-left mx-2 mt-1" /> Pegawai
                    </Tab>
                    <Tab
                      disabled={modal.modalType === "edit" ? false : true}
                      className="justify-items-start text-[8px] sm:text-sm md:text-base font-medium"
                      value="keluarga"
                    >
                      <FaUsers className="float-left mx-2 mt-1" /> Keluarga
                    </Tab>
                    <Tab
                      disabled={modal.modalType === "edit" ? false : true}
                      className="justify-items-start text-[8px] sm:text-sm md:text-base font-medium"
                      value="pendidikan"
                    >
                      <FaUserGraduate className="float-left mx-2 mt-1" />
                      Pendidikan
                    </Tab>
                    <Tab
                      disabled={modal.modalType === "edit" ? false : true}
                      className="justify-items-start text-[8px] sm:text-sm md:text-base font-medium"
                      value="lainnya"
                    >
                      <FaUserPlus className="float-left mx-2 mt-1" /> Lainnya
                    </Tab>
                    <Tab
                      disabled={modal.modalType === "edit" ? false : true}
                      className="justify-items-start text-[8px] sm:text-sm md:text-base font-medium"
                      value="jadwal"
                    >
                      <FaCalendarAlt className="float-left mx-2 mt-1" /> Jadwal
                    </Tab>
                  </TabsHeader>
                </div>
              </div>
            </div>
            <TabsBody
              animate={{
                mount: { x: 0 },
                unmount: { x: -250 },
              }}
              className="bg-white flex flex-col h-full justify-between overflow-y-auto custom-scroll"
            >
              {/* Panel Pribadi */}
              <TabPanel
                className="p-0 flex flex-col justify-between h-full"
                value="pribadi"
              >
                <div className="p-5">
                  <CompForm
                    inputs={inputsDataPribadi}
                    setInputs={setInputsDataPribadi}
                    handleInput={HandleInputsDataPribadi}
                    handleSearchSelect={handleSearchSelect}
                  />
                  <div className="w-full flex justify-end items-center mt-3">
                    <CompButton
                      btnName={"Submit"}
                      doClick={doSubmitPribadi}
                      onLoading={addPegawaiLoading}
                    />
                  </div>
                </div>
              </TabPanel>

              {/* Panel Pegawai */}
              <TabPanel
                className="p-0 flex flex-col justify-between h-full"
                value="pegawai"
              >
                <div className="p-5">
                  <CompForm
                    inputs={inputsPegawai}
                    setInputs={setInputsPegawai}
                    handleInput={HandleInputsPegawai}
                  />
                  <div className="w-full flex justify-end items-center mt-3">
                    <CompButton
                      btnName={"Submit"}
                      doClick={doSubmitPegawai}
                      onLoading={addPegawaiLoading}
                    />
                  </div>
                </div>
              </TabPanel>

              {/* Panel Keluarga */}
              <TabPanel
                className="p-0 flex flex-col justify-between h-full"
                value="keluarga"
              >
                <div className="p-5">
                  <CompForm
                    inputs={inputsDataKeluarga}
                    setInputs={setInputsDataKeluarga}
                    handleInput={HandleInputsDataKeluarga}
                  />
                  <div className="w-full flex justify-end items-center mt-3">
                    <CompButton
                      btnName={"Submit"}
                      doClick={doSubmitKeluarga}
                      onLoading={addPegawaiLoading}
                    />
                  </div>
                </div>
              </TabPanel>

              {/* Panel Pendidikan */}
              <TabPanel
                className="p-0 flex flex-col justify-between h-full"
                value="pendidikan"
              >
                <div className="p-5">
                  <CompForm
                    inputs={inputsDataPendidikan}
                    setInputs={setInputsDataPendidikan}
                  />
                  <div className="w-full flex justify-end items-center mt-3">
                    <CompButton
                      btnName={"Submit"}
                      doClick={doSubmitPendidikan}
                      onLoading={addPegawaiLoading}
                    />
                  </div>
                </div>
              </TabPanel>

              {/* Panel Lainnya */}
              <TabPanel
                className="p-0 flex flex-col justify-between h-full"
                value="lainnya"
              >
                <div className="p-5">
                  <CompForm
                    inputs={inputsDataLainnya}
                    setInputs={setInputsDataLainnya}
                  />
                  <div className="w-full flex justify-end items-center mt-3">
                    <CompButton
                      btnName={"Submit"}
                      doClick={doSubmitLainnya}
                      onLoading={addPegawaiLoading}
                    />
                  </div>
                </div>
              </TabPanel>

              {/* Panel Jadwal */}
              <TabPanel
                className="p-0 flex flex-col justify-between h-full"
                value="jadwal"
              >
                <div className="p-5">
                  <div>
                    <div className="font-semibold">Jam Masuk</div>
                    <CompForm
                      gridCols={4}
                      inputs={inputsDataJamMasuk}
                      setInputs={setInputsDataJamMasuk}
                      handleInput={handleJamMasuk}
                    />
                  </div>
                  <br />
                  <div>
                    <div className="font-semibold">Jam Keluar</div>
                    <CompForm
                      gridCols={4}
                      inputs={inputsDataJamKeluar}
                      setInputs={setInputsDataJamKeluar}
                      handleInput={handleJamKeluar}
                    />
                  </div>
                  <div className="w-full flex justify-end items-center mt-3">
                    <CompButton
                      btnName={"Submit"}
                      doClick={doSubmitJadwal}
                      onLoading={addPegawaiLoading}
                    />
                  </div>
                </div>
              </TabPanel>
            </TabsBody>
          </Tabs>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default PegawaiPage;
