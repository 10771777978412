import React, { useState } from "react";

// components
import { CompCard, CompCardContainer, CompModal } from "components";
import { dataKegiatan } from "atoms/dataDummy/dataKegiatan.js";

const KegiatanPage = () => {
	const [modal, setModal] = useState({
		modalOpen: false,
		modalTitle: "Kegiatan",
	});

	const [content, setContent] = useState("");

	const openModal = (item) => {
		setModal({
			...modal,
			modalOpen: !modal.modalOpen,
			modalTitle: item.modalTitle,
		});
		setContent(item.content);
	};

	return (
		<div>
			<div className="grid grid-cols-1 gap-4">
				<div className="grid grid-cols-6 gap-3">
					{/* {dataKegiatan.map((item, itemIdx) => (
            <div
              key={itemIdx}
              className="col-span-full sm:col-span-3 lg:col-span-2"
            >
              <CompCard onClick={() => openModal(item)} title={item.modalTitle}>
                {item.content}
              </CompCard>
            </div>
          ))} */}
					<CompModal modal={modal} setModal={setModal}>
						<CompCardContainer>
							<div className="text-justify p-4">{content}</div>
						</CompCardContainer>
						<div></div>
					</CompModal>
				</div>
			</div>
		</div>
	);
};

export default KegiatanPage;
