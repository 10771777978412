import { useCallback, useEffect, useState } from "react";

// components
import { CompCardContainer, CompCardContainer2, CompData } from "components";

// functions
import { isAuthenticated } from "authentication/authenticationApi";
import { convertJSON, getItem } from "pages/actions";
import {
  API_URL_getakun,
  API_URL_getcabang,
  API_URL_getmasterpegawai,
  baseurl,
} from "pages/constants";
import axiosAPI from "authentication/axiosApi";

const PengaturanProfilSub = () => {
  // Form;
  const [inputsPegawai, setInputsPegawai] = useState([
    {
      label: "Pangkat",
      name: "pangkat.id",
      type: "select",
      placeholder: "Input Pangkat",
      value: "",
      option: [],
      required: true,
    },
    {
      label: "Jabatan",
      name: "jabatan.id",
      type: "select",
      placeholder: "Input Jabatan",
      value: "",
      option: [],
      required: true,
    },
    {
      label: "Departemen",
      name: "departemen.id",
      type: "select",
      placeholder: "Input Departemen",
      value: "",
      option: [],
      required: true,
    },
    {
      label: "Divisi",
      name: "divisi.id",
      type: "select",
      placeholder: "Input Divisi",
      value: "",
      option: [],
      required: true,
    },
    {
      label: "Unit",
      name: "unit.id",
      type: "select",
      placeholder: "Input Unit",
      value: "",
      option: [],
      required: true,
    },
    {
      label: "Tanggal Bergabung",
      name: "tgl_bergabung",
      type: "date",
      value: "",
      required: true,
    },
    {
      label: "Tanggal Resign",
      name: "tgl_resign",
      type: "date",
      value: "",
    },
  ]);

  const [inputsDataPribadi, setInputsDataPribadi] = useState([
    {
      label: "ID",
      name: "user_id",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama",
      name: "nama",
      type: "text",
      value: "",
      required: true,
    },
    {
      label: "Username",
      name: "username",
      type: "text",
      value: "",
      required: true,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      value: "",
      required: true,
    },
    {
      label: "No Identitas (KTP)",
      name: "no_identitas",
      type: "text",
      value: "",
    },
    {
      label: "Jenis Kelamin",
      name: "jenis_kelamin",
      type: "select",
      placeholder: "Pilih Jenis Kelamin",
      value: "",
      option: [
        { label: "Laki-laki", value: "Laki-laki" },
        { label: "Perempuan", value: "Perempuan" },
      ],
      required: true,
    },
    {
      label: "No Telepon",
      name: "no_telepon",
      type: "text",
      value: "",
      required: true,
    },
    {
      label: "Tempat Lahir",
      name: "tempat_lahir",
      type: "text",
      value: "",
    },
    {
      label: "Tanggal Lahir",
      name: "tgl_lahir",
      type: "date",
      value: "",
    },
    {
      label: "Agama",
      name: "agama",
      type: "select",
      placeholder: "Pilih Agama",
      value: "",
      option: [
        { label: "Islam", value: "Islam" },
        { label: "Protestan", value: "Protestan" },
        { label: "Katolik", value: "Katolik" },
        { label: "Hindu", value: "Hindu" },
        { label: "Buddha", value: "Buddha" },
        { label: "Khonghucu", value: "Khonghucu" },
      ],
      required: true,
    },
    {
      label: "NPWP",
      name: "npwp",
      type: "text",
      value: "",
    },
    {
      label: "Alamat (KTP)",
      name: "alamat_ktp",
      type: "text",
      value: "",
    },
    {
      label: "Alamat (Domisili)",
      name: "alamat_domisili",
      type: "text",
      value: "",
    },
    {
      label: "Cabang",
      name: "cabang.id",
      type: "select",
      placeholder: "Pilih Cabang",
      value: "",
      option: [],
      required: true,
    },
  ]);

  const [inputsDataKeluarga, setInputsDataKeluarga] = useState([
    {
      label: "Nama Ayah",
      name: "nama_ayah",
      type: "text",
      value: "",
    },
    {
      label: "Nama Ibu",
      name: "nama_ibu",
      type: "text",
      value: "",
    },
    {
      label: "Status",
      type: "label",
    },
    {
      label: "Status Pernikahan",
      name: "status_pernikahan",
      type: "select",
      value: "belum menikah",
      fullWidth: true,
      option: [
        {
          label: "Menikah",
          value: "menikah",
        },
        {
          label: "Belum Menikah",
          value: "belum menikah",
        },
      ],
    },
    {
      label: "Nama Pasangan",
      name: "nama_pasangan",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      label: "Anak",
      name: "jumlah_anak",
      type: "multiple-input",
      input: [
        {
          label: "Nama Anak",
          name: "nama_anak",
          type: "text",
          value: "",
          disabled: true,
        },
      ],
    },
    {
      label: "Emergency Kontak",
      type: "label",
    },
    {
      label: "Nama Kontak",
      name: "nama_kontak_emergency",
      type: "text",
      value: "",
    },
    {
      label: "Nomor Telepon",
      name: "no_telepon_emergency",
      type: "text",
      value: "",
    },
  ]);

  const [inputsDataPendidikan, setInputsDataPendidikan] = useState([
    {
      label: "Pendidikan Formal",
      type: "group-input",
      addButton: true,
      groupInput: [
        [
          {
            label: "Asal Sekolah",
            name: "asal_sekolah",
            type: "text",
            value: "",
          },
          {
            label: "Masa Waktu",
            name: "masa_waktu",
            type: "text",
            value: "",
          },
          {
            label: "Keterangan Pendidikan",
            name: "keterangan_pendidikan",
            type: "text",
            value: "",
          },
        ],
      ],
    },
    {
      label: "Pendidikan Non Formal",
      type: "group-input",
      addButton: true,
      groupInput: [
        [
          {
            label: "Nama Lembaga",
            name: "nama_lembaga",
            type: "text",
            value: "",
          },
          {
            label: "Tahun Lulus",
            name: "tahun_lulus",
            type: "text",
            value: "",
          },
          {
            label: "Sertifikat",
            name: "sertifikat",
            type: "file",
            data: null,
            value: "",
            link: "",
          },
        ],
      ],
    },
  ]);

  const [inputsDataLainnya, setInputsDataLainnya] = useState([
    {
      label: "Input data lainnya",
      type: "group-input",
      addButton: true,
      groupInput: [
        [
          {
            name: "data_lainnya",
            type: "file",
            value: "",
            data: null,
            fullWidth: true,
            link: "",
          },
        ],
      ],
    },
  ]);

  const fetchData = useCallback(async () => {
    const response = await axiosAPI.get(
      API_URL_getakun + isAuthenticated().user_id
    );

    const masterPegawai = [
      "pangkat",
      "jabatan",
      "departemen",
      "divisi",
      "unit",
    ];
    const newPegawai = [...inputsPegawai];
    const res_pegawai = await axiosAPI.get(API_URL_getmasterpegawai);
    masterPegawai.forEach((item, index) => {
      newPegawai[index]["option"] = res_pegawai.data[item].map((item) => ({
        value: item.pk,
        label: item.nama,
      }));
    });
    setInputsPegawai(newPegawai);
    const newPribadi = [...inputsDataPribadi];
    const res_cabang = await axiosAPI.get(API_URL_getcabang);
    newPribadi[13]["option"] = res_cabang.data.map((item) => ({
      value: item.pk,
      label: item.nama,
    }));
    setInputsDataPribadi(newPribadi);

    const datapegawai = (data) => {
      if (data) {
        setInputsPegawai(
          inputsPegawai.map((item) => ({
            ...item,
            value: getItem(item.name, data),
          }))
        );
      } else {
        setInputsPegawai(inputsPegawai.map((item) => ({ ...item, value: "" })));
      }
    };

    const datapribadi = (data) => {
      if (data) {
        setInputsDataPribadi(
          inputsDataPribadi.map((item) => ({
            ...item,
            value: getItem(item.name, data),
          }))
        );
      } else {
        setInputsDataPribadi(
          inputsDataPribadi.map((item) => ({ ...item, value: "" }))
        );
      }
    };

    const datakeluarga = (data) => {
      if (data) {
        const InputKeluarga = [...inputsDataKeluarga];
        inputsDataKeluarga.forEach((item, i) => {
          if (item.type !== "label") {
            if (data.status_pernikahan === "menikah") {
              if (item.name === "nama_pasangan") {
                InputKeluarga[i]["value"] = getItem(item.name, data);
                InputKeluarga[i]["disabled"] = false;
              } else if (item.name === "jumlah_anak") {
                let anak = convertJSON(data.anak);
                InputKeluarga[i].input = anak.map((itemAnak) => ({
                  ...InputKeluarga[i].input[0],
                  value: itemAnak.anak,
                  disabled: false,
                }));
              } else {
                InputKeluarga[i]["value"] = getItem(item.name, data);
              }
            } else {
              if (item.name === "nama_pasangan") {
                InputKeluarga[i]["value"] = "";
                InputKeluarga[i]["disabled"] = true;
              } else if (item.name === "jumlah_anak") {
                InputKeluarga[i].input = [
                  {
                    ...inputsDataKeluarga[i].input[0],
                    value: "",
                    disabled: true,
                  },
                ];
              } else {
                InputKeluarga[i]["value"] = getItem(item.name, data);
              }
            }
          }
        });
        setInputsDataKeluarga(InputKeluarga);
      } else {
        const InputKeluarga = [...inputsDataKeluarga];
        inputsDataKeluarga.forEach((item, i) => {
          if (item.type !== "label") {
            if (item.name === "nama_pasangan") {
              InputKeluarga[i]["value"] = "";
              InputKeluarga[i]["disabled"] = false;
            } else if (item.name === "jumlah_anak") {
              InputKeluarga[i].input = [
                {
                  ...inputsDataKeluarga[i].input[0],
                  value: "",
                  disabled: false,
                },
              ];
            } else {
              InputKeluarga[i]["value"] = "";
            }
          }
        });
        setInputsDataKeluarga(InputKeluarga);
      }
    };

    const datapendidikan = (data) => {
      if (data) {
        const InputPendidikan = [...inputsDataPendidikan];
        const newData = (dataPendidikan, index) => {
          const newItem = inputsDataPendidikan[index]["groupInput"][0].map(
            (item) => {
              if (item.name === "sertifikat") {
                return { ...item, value: "", data: null, link: "" };
              } else {
                return { ...item, value: "" };
              }
            }
          );
          let itemGroup = dataPendidikan.map((item) => [...newItem]);
          dataPendidikan.forEach((item, index) => {
            itemGroup[index] = itemGroup[index].map((itemSub, indexSub) => {
              if (itemSub.name === "sertifikat") {
                return {
                  ...itemSub,
                  link:
                    item[itemSub.name] === ""
                      ? ""
                      : baseurl + item[itemSub.name],
                };
              } else {
                return { ...itemSub, value: item[itemSub.name] };
              }
            });
          });
          InputPendidikan[index]["groupInput"] = itemGroup;
        };
        const formal = convertJSON(data.formal);
        const non_formal = convertJSON(data.non_formal);
        newData(formal, 0);
        newData(non_formal, 1);
        setInputsDataPendidikan(InputPendidikan);
      } else {
        const InputPendidikan = [...inputsDataPendidikan];
        inputsDataPendidikan.forEach((item, index) => {
          const newItem = item["groupInput"][0].map((subItem) => {
            if (subItem.name === "sertifikat") {
              return { ...subItem, value: "", data: null, link: "" };
            } else {
              return { ...subItem, value: "" };
            }
          });
          InputPendidikan[index]["groupInput"] = [newItem];
        });
        setInputsDataPendidikan(InputPendidikan);
      }
    };

    const datalainnya = (data) => {
      if (data) {
        const InputLainnya = [...inputsDataLainnya];
        const files = convertJSON(data.data);
        console.log(files);
        const newItem = inputsDataLainnya[0]["groupInput"][0].map((item) => {
          return { ...item, value: "", data: null, link: "" };
        });
        let itemGroup = files.map((item) => [...newItem]);
        files.forEach((item, index) => {
          itemGroup[index] = itemGroup[index].map((itemSub, indexSub) => {
            return {
              ...itemSub,
              link: item.data === "" ? "" : baseurl + item.data,
            };
          });
        });
        InputLainnya[0]["groupInput"] = itemGroup;
        setInputsDataLainnya(InputLainnya);
      } else {
        const InputLainnya = [...inputsDataLainnya];
        inputsDataLainnya.forEach((item, index) => {
          const newItem = item["groupInput"][0].map((subItem) => {
            return { ...subItem, value: "", data: null, link: "" };
          });
          InputLainnya[index]["groupInput"] = [newItem];
        });
        setInputsDataLainnya(InputLainnya);
      }
    };

    datapegawai(response.data[0].datapegawai);
    datapribadi(response.data[0].datapribadi);
    datakeluarga(response.data[0].datakeluarga);
    datapendidikan(response.data[0].datapendidikan);
    datalainnya(response.data[0].datalainnya);
  }, [
    inputsPegawai,
    inputsDataPribadi,
    inputsDataKeluarga,
    inputsDataPendidikan,
    inputsDataLainnya,
  ]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer2>
        <div>
          <CompCardContainer
            title={"Data Pribadi"}
            onEdit={{ link: "/profil/pribadi", data: inputsDataPribadi }}
          >
            <CompData inputs={inputsDataPribadi} />
          </CompCardContainer>
          <br />
          <CompCardContainer
            title={"Data Pegawai"}
            onEdit={{ link: "/profil/pegawai", data: inputsPegawai }}
          >
            <CompData inputs={inputsPegawai} />
          </CompCardContainer>
          <br />
          <CompCardContainer
            title={"Data Keluarga"}
            onEdit={{ link: "/profil/keluarga", data: inputsDataKeluarga }}
          >
            <CompData inputs={inputsDataKeluarga} />
          </CompCardContainer>
          <br />
          <CompCardContainer
            title={"Data Pendidikan"}
            onEdit={{ link: "/profil/pendidikan", data: inputsDataPendidikan }}
          >
            <CompData inputs={inputsDataPendidikan} />
          </CompCardContainer>
          <br />
          <CompCardContainer
            title={"Data Lainnya"}
            onEdit={{ link: "/profil/lainnya", data: inputsDataLainnya }}
          >
            <CompData inputs={inputsDataLainnya} />
          </CompCardContainer>
        </div>
      </CompCardContainer2>
    </div>
  );
};

export default PengaturanProfilSub;
