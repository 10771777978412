import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import {
  CompButton,
  CompCardContainer,
  CompCardContainer2,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";

// functions
import { getData, handleInputError, status, updateData } from "pages/actions";
import {
  API_URL_getdatapengajuancuti,
  API_URL_responsepengajuan,
} from "pages/constants";
import { pengajuanIzinReducer } from "reducers/asesmenReducers";
import moment from "moment";
import { icons } from "atoms/icons";

const ValidasiIzinSub = () => {
  const {
    getIzinValidasiResult,
    getIzinValidasiLoading,
    getIzinValidasiError,
    updatePengajuanResult,
    updatePengajuanLoading,
  } = useSelector((state) => state.asesmen);
  const dispatch = useDispatch();

  // pagination
  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);

  // States & Variables
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState(moment(new Date()).format("YYYY-MM"));

  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Catatan Cuti",
  });

  const [dataColumns] = useState([
    { name: "No", value: "no" },
    { name: "Tanggal Pengajuan", value: "created_at" },
    { name: "Nama", value: "user.first_name" },
    { name: "Jenis Cuti", value: "jeniscuti.jenis" },
    { name: "Alasan Cuti", value: "alasan_cuti" },
    { name: "Tanggal Cuti", value: "tgl_cuti" },
    { name: "Lama Cuti (hari)", value: "lama_cuti" },
    { name: "File", value: "file" },
    {
      name: "Status",
      value: "status",
      status: [
        { label: "diproses", value: 1, color: "bg-cyan-500" },
        { label: "diterima", value: 2, color: "bg-green-500" },
        { label: "ditolak", value: 3, color: "bg-red-500" },
      ],
    },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "status",
      name: "status",
      type: "hidden",
      value: "",
    },
    {
      label: "Catatan",
      name: "catatan",
      type: "textarea",
      placeholder: "Input Catatan",
      value: "",
      error: "",
      required: true,
      fullWidth: true,
    },
  ]);

  // Function
  const onTerima = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs([
      {
        ...inputs[0],
        value: item.pk,
      },
      {
        ...inputs[1],
        value: 2,
        error: "",
      },
      {
        ...inputs[2],
        value: "",
        error: "",
      },
    ]);
  };
  const onTolak = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs([
      {
        ...inputs[0],
        value: item.pk,
      },
      {
        ...inputs[1],
        value: 3,
        error: "",
      },
      {
        ...inputs[2],
        value: "",
        error: "",
      },
    ]);
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      updateData(
        { dispatch, redux: pengajuanIzinReducer },
        {
          pk: inputs[0].value,
          status: inputs[1].value,
          catatan: inputs[2].value,
        },
        API_URL_responsepengajuan,
        "UPDATE_PENGAJUAN"
      );
    }
  };
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({
      param: "?search=" + value + "&date-month=" + filter + "&status=1",
    });
  };

  const handleFilterDate = (e) => {
    const param =
      search === ""
        ? { param: "?date-month=" + e + "&status=1&limit=" + limit }
        : {
            param:
              "?search=" +
              search +
              "&date-month=" +
              e +
              "&status=1&limit=" +
              limit,
          };
    setFilter(e);
    setPageActive(0);
    get(param);
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: pengajuanIzinReducer },
        param,
        API_URL_getdatapengajuancuti,
        "GET_IZIN_VALIDASI"
      );
    },
    [dispatch]
  );

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? {
            param:
              "?date-month=" +
              filter +
              "&status=1&limit=" +
              limit +
              "&offset=" +
              offset,
          }
        : {
            param:
              "?search=" +
              search +
              "&date-month=" +
              filter +
              "&status=1&limit=" +
              limit +
              "&offset=" +
              offset,
          };
    get(param);
    setOffset(offset);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?date-month=" + filter + "&status=1&limit=" + e }
        : {
            param:
              "?search=" +
              search +
              "&date-month=" +
              filter +
              "&status=1&limit=" +
              limit,
          };
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  const fetchData = useCallback(
    async (param = false) => {
      setModal({ ...modal, modalOpen: false });
      get(
        param
          ? param
          : { param: "?date-month=" + filter + "&status=1&limit=" + limit }
      );
    },
    [modal, filter, limit, get]
  );

  // Action Button
  const [actions] = useState([
    {
      name: "terima",
      icon: icons.mdcheckcircle,
      color: "text-green-500",
      func: onTerima,
    },
    {
      name: "tolak",
      icon: icons.riclosecirclefill,
      color: "text-red-500",
      func: onTolak,
    },
  ]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (updatePengajuanResult) {
      const param =
        search === ""
          ? {
              param:
                "?date-month=" +
                filter +
                "&status=1&limit=" +
                limit +
                "&offset=" +
                offset,
            }
          : {
              param:
                "?search=" +
                search +
                "&date-month=" +
                filter +
                "&status=1&limit=" +
                limit +
                "&offset=" +
                offset,
            };
      fetchData(param);
    }
  }, [updatePengajuanResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer2>
        <CompTableController
          title="Validasi Cuti"
          doSearch={doSearch}
          filter={filter}
          setFilter={handleFilterDate}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={
              getIzinValidasiResult.count > 0
                ? getIzinValidasiResult.results
                : null
            }
            isLoading={getIzinValidasiLoading}
            isError={getIzinValidasiError}
            actions={actions}
            offset={offset}
            status={status}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={
            getIzinValidasiResult.count > 0 ? getIzinValidasiResult.count : 0
          }
          limit={limit}
          setLimit={handleSelect}
          pageActive={pageActive}
        />
      </CompCardContainer2>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form
            onKeyPress={(e) =>
              e.key === "Enter" && !updatePengajuanLoading && doSubmit(e)
            }
          >
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={(e) => doSubmit(e)}
            onLoading={updatePengajuanLoading}
          />
        </div>
      </CompModal>
    </div>
  );
};

export default ValidasiIzinSub;
