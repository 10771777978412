import React, { Fragment, useState } from "react";
import { CompTabs } from "components";
import InfoSub from "./Sub/InfoSub";
import HarianSub from "./Sub/HarianSub";
import TugasSub from "./Sub/TugasSub";

const DokumentasiKegiatanPage = () => {
  const [tabs] = useState({
    idTabs: "kegiatanTabs",
    idContents: "kegiatanTabs-Content",
    listTabs: [
      {
        nameTabs: "Info",
        linkTabs: "kegiatanInfo",
        contentTabs: <InfoSub />,
      },
      {
        nameTabs: "Harian",
        linkTabs: "kegiatanHarian",
        contentTabs: <HarianSub />,
      },
      {
        nameTabs: "Tugas",
        linkTabs: "kegiatanTugas",
        contentTabs: <TugasSub />,
      },
    ],
  });

  return (
    <Fragment>
      <CompTabs tabs={tabs} />
    </Fragment>
  );
};

export default DokumentasiKegiatanPage;
