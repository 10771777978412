import { isAuthenticated } from "authentication/authenticationApi";
import axiosAPI from "authentication/axiosApi";
import { CompButton, CompCardContainer, CompForm } from "components";
import { handleInputError, updateData } from "pages/actions";
import {
  API_URL_edeluser,
  API_URL_getspesifikdivisi,
  API_URL_getspesifikjabatan,
  API_URL_getspesifikunit,
} from "pages/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pegawaiReducer } from "reducers/kepegawaianReducers";

const EditProfilePegawaiPage = () => {
  const { addPegawaiResult, addPegawaiLoading } = useSelector(
    (state) => state.kepegawaian
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [inputsPegawai, setInputsPegawai] = useState(location.state.data);

  //   Funtion
  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsPegawai);
    setInputsPegawai(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (isAuthenticated().user_id !== "") {
        updateData(
          { dispatch, redux: pegawaiReducer },
          {
            pk: "datapegawai",
            user_id: isAuthenticated().user_id,
            jabatan_id: inputsPegawai[1].value,
            unit_id: inputsPegawai[4].value,
            tgl_bergabung: inputsPegawai[5].value,
            tgl_resign: inputsPegawai[6].value,
          },
          API_URL_edeluser,
          "ADD_PEGAWAI"
        );
      }
    }
  };

  // Handle Form
  const HandleInputsPegawai = (e, index) => {
    if (index === 0) {
      axiosAPI.get(API_URL_getspesifikjabatan + e.target.value).then((res) => {
        const Inputs = [...inputsPegawai];
        Inputs[1]["option"] = res.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        setInputsPegawai(Inputs);
      });
    } else if (index === 2) {
      axiosAPI.get(API_URL_getspesifikdivisi + e.target.value).then((res) => {
        const Inputs = [...inputsPegawai];
        Inputs[3]["option"] = res.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        setInputsPegawai(Inputs);
      });
    } else if (index === 3) {
      axiosAPI.get(API_URL_getspesifikunit + e.target.value).then((res) => {
        const Inputs = [...inputsPegawai];
        Inputs[4]["option"] = res.data.map((item) => ({
          value: item.pk,
          label: item.nama,
        }));
        setInputsPegawai(Inputs);
      });
    }
    const newInputs = [...inputsPegawai];
    newInputs[index]["value"] = e.target.value;
    setInputsPegawai(newInputs);
  };

  useEffect(() => {
    if (addPegawaiResult) {
      dispatch(
        pegawaiReducer({
          type: "ADD_PEGAWAI",
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      navigate("/profil");
    }
  }, [addPegawaiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer>
        <span
          onClick={() => navigate(-1)}
          className="px-3 py-2 items-center rounded-lg bg-[#f3f4f6] text-xs cursor-pointer text-grey-600"
        >
          Back
        </span>
        <form className="mt-4" onSubmit={doSubmit}>
          <CompForm
            inputs={inputsPegawai}
            setInputs={setInputsPegawai}
            handleInput={HandleInputsPegawai}
          />
          <div className="flex justify-end items-center mt-4">
            <CompButton
              btnName={"Save"}
              doClick={doSubmit}
              onLoading={addPegawaiLoading}
            />
          </div>
        </form>
      </CompCardContainer>
    </div>
  );
};

export default EditProfilePegawaiPage;
