import React, { Fragment } from "react";

// components
import Select from "react-select";
import { icons } from "atoms/icons";
import { isAuthenticated } from "authentication/authenticationApi";

const CompForm = ({
  inputs,
  setInputs,
  handleInput,
  handleSearchSelect,
  gridCols = 2,
}) => {
  // Multiple
  const handleInputMultiple = (e, index, index2) => {
    const newInputs = [...inputs];
    newInputs[index]["input"][index2]["value"] = e.target.value;
    setInputs(newInputs);
  };
  const handleAddInputMultiple = (inputIdx, item) => {
    const newInputs = [...inputs];
    const newItem = { ...item, value: "" };
    newInputs[inputIdx]["input"] = [...newInputs[inputIdx]["input"], newItem];
    setInputs(newInputs);
  };
  const handleDeleteInputMultiple = (inputIdx, itemIdx) => {
    const newInputs = [...inputs];
    newInputs[inputIdx]["input"].splice(itemIdx, 1);
    setInputs(newInputs);
  };

  // Group
  const handleInputGroup = (e, index, index2, index3) => {
    const newInputs = [...inputs];
    if (e.target.type === "file") {
      newInputs[index]["groupInput"][index2][index3]["value"] = e.target.value;
      newInputs[index]["groupInput"][index2][index3]["data"] =
        e.target.files[0];
    } else {
      newInputs[index]["groupInput"][index2][index3]["value"] = e.target.value;
    }
    setInputs(newInputs);
  };
  const handleAddGroup = (inputIdx, items) => {
    const newInputs = [...inputs];
    const newItem = items.map(
      (item) => (item = { ...item, value: "", data: null, link: "" })
    );
    newInputs[inputIdx]["groupInput"] = [
      ...newInputs[inputIdx]["groupInput"],
      newItem,
    ];
    setInputs(newInputs);
  };
  const handleDeleteGroup = (inputIdx, itemIdx) => {
    const newInputs = [...inputs];
    newInputs[inputIdx]["groupInput"].splice(itemIdx, 1);
    setInputs(newInputs);
  };

  const handleVisiblePassword = (index) => {
    const newInputs = [...inputs];
    newInputs[index]["type"] = "text";
    setInputs(newInputs);
  };
  const handleInVisiblePassword = (index) => {
    const newInputs = [...inputs];
    newInputs[index]["type"] = "password";
    setInputs(newInputs);
  };

  return (
    <Fragment>
      <div
        style={{ gridTemplateColumns: `repeat(${gridCols}, minmax(0, 1fr))` }}
        className="grid gap-4 text-xs md:text-sm"
      >
        {inputs &&
          inputs.map((input, inputIdx) => {
            // Multiple Input
            if (input.isSuperAdmin && !isAuthenticated().superuser) {
              return null;
            }
            if (input.type === "multiple-input") {
              return (
                <div
                  key={inputIdx}
                  className={`w-full col-span-full text-grey-800 ${
                    input.fullWidth ? "" : "sm:col-span-1"
                  }`}
                >
                  <label>
                    {input.label}
                    {input.required && <span className="text-red-500">*</span>}
                  </label>
                  {input.input.map((item, itemIdx) => (
                    <div
                      key={itemIdx}
                      className="flex relative items-center mt-1"
                    >
                      <input
                        className="w-full border border-grey-300 py-1.5 px-3 outline-none rounded-md placeholder:text-grey-400"
                        type={item.type}
                        placeholder={item.placeholder}
                        name={item.name + itemIdx}
                        value={item.value}
                        onChange={(e) =>
                          handleInputMultiple(e, inputIdx, itemIdx)
                        }
                        disabled={item.disabled}
                        required={item.required}
                      />
                      {itemIdx === 0 ? (
                        <div
                          onClick={() =>
                            handleAddInputMultiple(inputIdx, input.input[0])
                          }
                          className={`absolute right-3 text-green-600 text-lg bg-white rounded-full outline-none cursor-pointer ${
                            item.disabled ? "hidden" : ""
                          }`}
                        >
                          {icons.fipluscircle}
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            handleDeleteInputMultiple(inputIdx, itemIdx)
                          }
                          className={`absolute right-3 text-red-600 text-lg bg-white rounded-full outline-none cursor-pointer ${
                            item.disabled ? "hidden" : ""
                          }`}
                        >
                          {icons.fixcircle}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              );
            }

            // Group Input
            if (input.type === "group-input") {
              return (
                <div key={inputIdx} className="w-full col-span-full">
                  <div
                    className={`w-full flex justify-between items-center mb-2 font-medium text-grey-800`}
                  >
                    <label>{input.label}</label>
                    {input.addButton && (
                      <div className="flex">
                        {input.groupInput.length > 1 && (
                          <div
                            className="p-1.5 flex items-center mr-2 rounded bg-[#f3f4f6] cursor-pointer"
                            onClick={() =>
                              handleDeleteGroup(
                                inputIdx,
                                input.groupInput.length - 1
                              )
                            }
                          >
                            <span>{icons.fix}</span>
                          </div>
                        )}
                        <div
                          className="p-1.5 flex items-center rounded bg-[#f3f4f6] cursor-pointer"
                          onClick={() =>
                            handleAddGroup(inputIdx, input.groupInput[0])
                          }
                        >
                          <span>{icons.aioutlineplus}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-full grid grid-cols-3 gap-2">
                    {input.groupInput.map((itemGroup, itemGroupIdx) =>
                      itemGroup.map((item, itemIdx) => {
                        // Group input
                        if (
                          item.type === "text" ||
                          item.type === "email" ||
                          item.type === "number" ||
                          item.type === "password" ||
                          item.type === "date" ||
                          item.type === "time"
                        ) {
                          return (
                            <div
                              key={itemIdx}
                              className={`w-full text-grey-800 ${
                                item.fullWidth ? "col-span-full" : ""
                              }`}
                            >
                              <label>
                                {item.label}
                                {item.required && (
                                  <span className="text-red-500">*</span>
                                )}
                              </label>
                              <div className="flex relative items-center mt-1">
                                <input
                                  className={`w-full py-1.5 px-3 outline-none rounded-md placeholder:text-grey-400 ${
                                    input.error
                                      ? "border border-red-500"
                                      : "border border-grey-300"
                                  }`}
                                  type={item.type}
                                  placeholder={item.placeholder}
                                  name={item.name}
                                  disabled={item.disabled}
                                  value={item.value}
                                  onChange={(e) =>
                                    handleInputGroup(
                                      e,
                                      inputIdx,
                                      itemGroupIdx,
                                      itemIdx
                                    )
                                  }
                                  required={item.required}
                                />
                              </div>
                              {item.error && (
                                <div className="text-xs text-red-500">
                                  {item.error}
                                </div>
                              )}
                            </div>
                          );
                        }

                        // Group File
                        if (item.type === "file") {
                          return (
                            <div
                              key={itemIdx}
                              className={`w-full text-grey-800 ${
                                item.fullWidth ? "col-span-full" : ""
                              }`}
                            >
                              <label>
                                {item.label}
                                {item.required && (
                                  <span className="text-red-500">*</span>
                                )}
                              </label>
                              <div className="flex relative items-center mt-1">
                                <input
                                  className="form-control w-full px-3 py-1.5 border border-grey-300 rounded transition ease-in-out m-0 focus:text-grey-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  type={item.type}
                                  placeholder={item.placeholder}
                                  name={item.name}
                                  disabled={item.disabled}
                                  value={item.value}
                                  onChange={(e) =>
                                    handleInputGroup(
                                      e,
                                      inputIdx,
                                      itemGroupIdx,
                                      itemIdx
                                    )
                                  }
                                  required={item.required}
                                />
                              </div>
                              {item.link ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={item.link}
                                  className="text-xs text-blue-500"
                                >
                                  *lihat file
                                </a>
                              ) : (
                                <div className="text-xs text-red-500">
                                  *tidak ada file
                                </div>
                              )}
                            </div>
                          );
                        }

                        if (item.type === "search-select") {
                          return (
                            <div
                              key={itemIdx}
                              className={`w-full text-grey-800 ${
                                item.fullWidth ? "col-span-full" : ""
                              }`}
                            >
                              <label>
                                {item.label}
                                {item.required && (
                                  <span className="text-red-500">*</span>
                                )}
                              </label>
                              <div className="flex relative items-center mt-1">
                                <Select
                                  className={`w-full items-center outline-none rounded-md ${
                                    item.error
                                      ? "border border-red-500"
                                      : "border border-grey-300"
                                  }`}
                                  isDisabled={item.disabled}
                                  options={item.option}
                                  name={item.name}
                                  defaultValue={item.value}
                                  isSearchable
                                  isMulti={item.isMulti}
                                  onChange={(value) =>
                                    handleSearchSelect(value, itemIdx)
                                  }
                                />
                              </div>
                              {input.error && (
                                <div className="text-xs text-red-500">
                                  {input.error}
                                </div>
                              )}
                            </div>
                          );
                        }

                        return <></>;
                      })
                    )}
                  </div>
                </div>
              );
            }

            // Label
            if (input.type === "label") {
              return (
                <div
                  key={inputIdx}
                  className={`w-full flex justify-between items-center font-medium col-span-full text-grey-800`}
                >
                  <label>{input.label}</label>
                </div>
              );
            }

            // Button
            if (input.type === "button") {
              return (
                <div
                  key={inputIdx}
                  className={`w-full col-span-full text-grey-800 ${
                    input.fullWidth ? "" : "sm:col-span-1"
                  }`}
                >
                  <label>{input.label}</label>
                  <div className="flex relative items-center mt-1">
                    <div
                      className="w-full text-center cursor-pointer py-[7px] outline-none rounded-md bg-blue-600 text-white font-semibold shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                      name={input.name}
                      disabled={input.disabled}
                      onClick={input.click}
                    >
                      {input.value}
                    </div>
                  </div>
                </div>
              );
            }

            // Input
            if (
              input.type === "text" ||
              input.type === "email" ||
              input.type === "number" ||
              input.type === "password" ||
              input.type === "date" ||
              input.type === "datetime-local" ||
              input.type === "time"
            ) {
              return (
                <div
                  key={inputIdx}
                  className={`w-full col-span-full text-grey-800 ${
                    input.fullWidth ? "" : "sm:col-span-1"
                  }`}
                >
                  <label>
                    {input.label}
                    {input.required && <span className="text-red-500">*</span>}
                  </label>
                  <div className="flex relative items-center mt-1">
                    <input
                      className={`w-full py-1.5 px-3 outline-none rounded-md placeholder:text-grey-400 ${
                        input.error
                          ? "border border-red-500"
                          : "border border-grey-300"
                      }`}
                      type={input.type}
                      placeholder={input.placeholder}
                      name={input.name}
                      disabled={input.disabled}
                      value={input.value}
                      onChange={(e) => handleInput(e, inputIdx)}
                      required={input.required}
                    />
                    {input.password &&
                      (input.type === "password" ? (
                        <div
                          className="absolute right-3 text-xl text-grey-600 cursor-pointer"
                          onClick={(e) => handleVisiblePassword(inputIdx)}
                        >
                          {icons.aifilleye}
                        </div>
                      ) : (
                        <div
                          className="absolute right-3 text-xl text-grey-600 cursor-pointer"
                          onClick={(e) => handleInVisiblePassword(inputIdx)}
                        >
                          {icons.aifilleyeinvisible}
                        </div>
                      ))}
                  </div>
                  {input.error && (
                    <div className="text-xs text-red-500">{input.error}</div>
                  )}
                </div>
              );
            }

            // Textarea
            if (input.type === "textarea") {
              return (
                <div
                  key={inputIdx}
                  className={`w-full col-span-full text-grey-800 ${
                    input.fullWidth ? "" : "sm:col-span-1"
                  }`}
                >
                  <label>
                    {input.label}
                    {input.required && <span className="text-red-500">*</span>}
                  </label>
                  <div className="flex relative items-center mt-1">
                    <textarea
                      className={`w-full py-3 px-3 outline-none custom-scroll rounded-md ${
                        input.error
                          ? "border border-red-500"
                          : "border border-grey-300"
                      }`}
                      type={input.type}
                      placeholder={input.placeholder}
                      name={input.name}
                      disabled={input.disabled}
                      value={input.value}
                      onChange={(e) => handleInput(e, inputIdx)}
                      required={input.required}
                    />
                  </div>
                  {input.error && (
                    <div className="text-xs text-red-500">{input.error}</div>
                  )}
                </div>
              );
            }

            // File
            if (input.type === "file") {
              return (
                <div
                  key={inputIdx}
                  className={`w-full col-span-full text-grey-800 ${
                    input.fullWidth ? "" : "sm:col-span-1"
                  }`}
                >
                  <label>
                    {input.label}
                    {input.required && <span className="text-red-500">*</span>}
                  </label>
                  <div className="flex relative items-center mt-1">
                    <input
                      className="form-control w-full px-3 py-1.5 border border-grey-300 rounded transition ease-in-out m-0 focus:text-grey-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      type={input.type}
                      placeholder={input.placeholder}
                      name={input.name}
                      disabled={input.disabled}
                      value={input.value}
                      onChange={(e) => handleInput(e, inputIdx)}
                      required={input.required}
                    />
                  </div>
                  {input.link ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={input.link}
                      className="text-xs text-blue-500"
                    >
                      *lihat file
                    </a>
                  ) : (
                    <div className="text-xs text-red-500">*tidak ada file</div>
                  )}
                </div>
              );
            }

            // Select
            if (input.type === "select") {
              return (
                <div
                  key={inputIdx}
                  className={`w-full col-span-full text-grey-800 ${
                    input.fullWidth ? "" : "sm:col-span-1"
                  }`}
                >
                  <label>
                    {input.label}
                    {input.required && <span className="text-red-500">*</span>}
                  </label>
                  <div className="flex relative items-center mt-1">
                    <select
                      className={`w-full appearance-none px-3 py-1.5 flex items-center outline-none rounded-md transition
                      ease-in-out duration-300 ${
                        input.value === "" ? "text-grey-400" : ""
                      } ${
                        input.error
                          ? "border border-red-500"
                          : "border border-grey-300"
                      }`}
                      type={input.type}
                      placeholder={input.placeholder}
                      name={input.name}
                      disabled={input.disabled}
                      value={input.value}
                      onChange={(e) => handleInput(e, inputIdx)}
                      required={input.required}
                    >
                      <option value="" disabled>
                        {input.placeholder}
                      </option>
                      {input.option.map((select, selectIdx) => (
                        <option
                          key={selectIdx}
                          value={select.value}
                          className="text-grey-800"
                        >
                          {select.label}
                        </option>
                      ))}
                    </select>
                    <div className="absolute right-3 outline-none">
                      {icons.bichevrondown}
                    </div>
                  </div>
                  {input.error && (
                    <div className="text-xs text-red-500">{input.error}</div>
                  )}
                </div>
              );
            }

            // Search Select
            if (input.type === "search-select") {
              return (
                <div
                  key={inputIdx}
                  className={`w-full col-span-full text-grey-800 ${
                    input.fullWidth ? "" : "sm:col-span-1"
                  }`}
                >
                  <label>
                    {input.label}
                    {input.required && <span className="text-red-500">*</span>}
                  </label>
                  <div className="flex relative items-center mt-1">
                    <Select
                      className={`w-full items-center outline-none rounded-md ${
                        input.error
                          ? "border border-red-500"
                          : "border border-grey-300"
                      }`}
                      options={input.option}
                      name={input.name}
                      defaultValue={input.value}
                      isSearchable
                      isDisabled={input.disabled}
                      isMulti={input.isMulti}
                      onChange={(value) => handleSearchSelect(value, inputIdx)}
                    />
                  </div>
                  {input.error && (
                    <div className="text-xs text-red-500">{input.error}</div>
                  )}
                </div>
              );
            }

            // Radio
            if (input.type === "radio") {
              return (
                <div
                  key={inputIdx}
                  className={`w-full col-span-full text-grey-800 ${
                    input.fullWidth ? "" : "sm:col-span-1"
                  }`}
                >
                  <label>
                    {input.label}
                    {input.required && <span className="text-red-500">*</span>}
                  </label>
                  <div className="grid grid-rows-2 grid-flow-col gap-1 mt-1 border border-grey-300 py-1.5 px-3 outline-none rounded-md">
                    {input.option.map((radio, radioIdx) => (
                      <div
                        key={radioIdx}
                        className="form-check flex w-full form-check relative items-center"
                      >
                        <input
                          className="form-check-input appearance-none rounded-full h-4 w-4 mr-2 border border-grey-300 bg-white checked:bg-blue-400 checked:border-blue-400 focus:outline-none transition duration-200 cursor-pointer"
                          type="radio"
                          id={radio.value}
                          name={input.name}
                          disabled={input.disabled}
                          value={radio.value}
                          onChange={(e) => handleInput(e, inputIdx)}
                        />
                        <label
                          className="form-check-label inline-block text-grey-800"
                          htmlFor={radio.value}
                        >
                          {radio.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              );
            }

            return <div className="hidden" key={inputIdx}></div>;
          })}
      </div>
    </Fragment>
  );
};

export default CompForm;
