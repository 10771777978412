import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { CompButton, CompCardContainer2, CompForm } from "components";

// functions
import { updateAkun, updateProfile } from "pages/actions/auth";
import { isAuthenticated } from "authentication/authenticationApi";
import {
  API_URL_updateakun,
  API_URL_updateprofile,
  baseurl,
} from "pages/constants";

const PengaturanAkunSub = () => {
  const { addUserResult, addUserLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState("");
  // const [imageData, setImageData] = useState("");

  const [inputs, setInputs] = useState([
    {
      label: "Username",
      name: "username",
      type: "text",
      placeholder: "Input Username",
      value: "",
      fullWidth: true,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Input Email",
      value: "",
      fullWidth: true,
    },
    // {
    //   label: "Password",
    //   name: "password",
    //   type: "password",
    //   placeholder: "Input Password",
    //   value: "",
    //   fullWidth: true,
    // },
  ]);

  //   Funtion
  const doSubmit = () => {
    const formData = new FormData();
    formData.append("username", inputs[0].value);
    formData.append("email", inputs[1].value);
    // formData.append("image", imageData);
    updateAkun(
      dispatch,
      "ADD_USER",
      formData,
      isAuthenticated().user_id,
      API_URL_updateakun
    );
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  const handleInputImg = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    updateProfile(
      dispatch,
      formData,
      API_URL_updateprofile,
      "UPDATE_PROFILE",
      isAuthenticated().user_id
    );

    // setImageData(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const fetchData = useCallback(async () => {
    const newInputs = [...inputs];
    newInputs[0]["value"] = isAuthenticated().username;
    newInputs[1]["value"] = isAuthenticated().email;
    setImagePreview(
      isAuthenticated().image ? baseurl + isAuthenticated().image : ""
    );
    setInputs(newInputs);
  }, [inputs]);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (addUserResult) {
      fetchData();
    }
  }, [addUserResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer2>
        <div className="w-full flex justify-center my-4">
          <div className="w-28 h-28 relative">
            <img
              className="object-cover w-full h-full rounded-full border-2 border-grey-300"
              src={
                imagePreview
                  ? imagePreview
                  : process.env.PUBLIC_URL + "/assets/default.jpg"
              }
              alt="imgPreview"
            />
            <div className="absolute right-0 bottom-0">
              <div className="h-8 w-8 rounded-full relative">
                <img
                  src={process.env.PUBLIC_URL + "/assets/imgPreview.png"}
                  alt=""
                />
                <input
                  className="form-control absolute top-0 left-0 h-full w-full rounded-full opacity-0 cursor-pointer"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleInputImg(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <form onKeyPress={(e) => e.key === "Enter" && doSubmit(e)}>
          <CompForm
            inputs={inputs}
            setInputs={setInputs}
            handleInput={handleInput}
          />
        </form>
        <div className="flex justify-end items-center mt-4">
          <CompButton
            btnName={"Save"}
            doClick={doSubmit}
            onLoading={addUserLoading}
          />
        </div>
      </CompCardContainer2>
    </div>
  );
};

export default PengaturanAkunSub;
