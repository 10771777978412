const foto =
	"https://eitrawmaterials.eu/wp-content/uploads/2016/09/person-icon.png";

// export const pengurus = {
// 	id: "A",
// 	nama: "Struktur Organisasi",
// 	children: [
// 		{
// 			id: "AA",
// 			nama: "Tresna Fuji Lestari",
// 			jabatan: "Direktur Utama",
// 			fotoProfil: foto,
// 			children: [
// 				{
// 					id: "AAA",
// 					nama: "Berliana Yuni Sari",
// 					jabatan: "Finance",
// 					fotoProfil: foto,
// 					children: [
// 						{
// 							id: "AAAA",
// 							nama: "Siti Hartati",
// 							jabatan: "Staf Akunting",
// 							fotoProfil: foto,
// 							children: [],
// 						},
// 					],
// 				},
// 				{
// 					id: "AAB",
// 					nama: "Lisca Juita",
// 					jabatan: "Direktur",
// 					fotoProfil: foto,
// 					children: [
// 						{
// 							id: "AABA",
// 							nama: "Diana Rika H",
// 							jabatan: "Operasional",
// 							fotoProfil: foto,
// 							children: [
// 								{
// 									id: "AABAA",
// 									nama: "Muhadi",
// 									jabatan: "Spv FOP",
// 									fotoProfil: foto,
// 									children: [
// 										{
// 											id: "AABAAA",
// 											nama: "Asep Gunawan",
// 											jabatan: "Ketua Tim",
// 											fotoProfil: foto,
// 											children: [
// 												{
// 													id: "AABAAAA",
// 													nama: "Noven",
// 													jabatan: "Teknisi",
// 													fotoProfil: foto,
// 													children: [],
// 												},
// 												{
// 													id: "AABAAAB",
// 													nama: "Kang Topo",
// 													jabatan: "Teknisi",
// 													fotoProfil: foto,
// 													children: [],
// 												},
// 											],
// 										},
// 										{
// 											id: "AABAAB",
// 											nama: "Jossy",
// 											jabatan: "Ketua Tim",
// 											fotoProfil: foto,
// 											children: [
// 												{
// 													id: "AABAABA",
// 													nama: "Adam",
// 													jabatan: "Teknisi",
// 													fotoProfil: foto,
// 													children: [],
// 												},
// 												{
// 													id: "AABAABB",
// 													nama: "Sherliansyah",
// 													jabatan: "Teknisi",
// 													fotoProfil: foto,
// 													children: [],
// 												},
// 											],
// 										},
// 										{
// 											id: "AABAAC",
// 											nama: "Maryudi",
// 											jabatan: "Staf",
// 											fotoProfil: foto,
// 											children: [],
// 										},
// 										{
// 											id: "AABAAD",
// 											nama: "Sugiyanto",
// 											jabatan: "Staf",
// 											fotoProfil: foto,
// 											children: [],
// 										},
// 									],
// 								},
// 								{
// 									id: "AABAB",
// 									nama: "Spv NOC",
// 									jabatan: "Indra Hartadi",
// 									fotoProfil: foto,
// 									children: [
// 										{
// 											id: "AABABA",
// 											nama: "Guntur",
// 											jabatan: "Staf NOC",
// 											fotoProfil: foto,
// 											children: [],
// 										},
// 									],
// 								},
// 							],
// 						},
// 						{
// 							id: "AABB",
// 							nama: "Rizky Yunianti",
// 							jabatan: "Marketing & GA",
// 							fotoProfil: foto,
// 							children: [
// 								{
// 									id: "AABBA",
// 									nama: "Nanda",
// 									jabatan: "Humas",
// 									fotoProfil: foto,
// 									children: [],
// 								},
// 								{
// 									id: "AABBB",
// 									nama: "Tegar Achmadi",
// 									jabatan: "Spc Sales",
// 									fotoProfil: foto,
// 									children: [
// 										{
// 											id: "AABBBA",
// 											nama: "Talita",
// 											jabatan: "Staf",
// 											fotoProfil: foto,
// 											children: [],
// 										},
// 									],
// 								},
// 							],
// 						},
// 					],
// 				},
// 			],
// 		},
// 		{
// 			id: "AB",
// 			nama: "Berliana Yuni Sari",
// 			jabatan: "Komisaris",
// 			fotoProfil: foto,
// 			children: [],
// 		},
// 	],
// };

export const pengurus = {
	id: 1,
	nama: "Pemegang Saham Utama",
	children: [
		{
			id: 2,
			nama: "Lisa Juita",
			fotoProfil: foto,
		},
		{
			id: 4,
			nama: "Tresna Fuji Lestari",
			fotoProfil: foto,
			children: [
				{
					id: 5,
					nama: "Komite Strategi",
					children: [
						{
							id: 6,
							nama: "Supriyanto",
							fotoProfil: foto,
						},
						{
							id: 7,
							nama: "Tresna Fuji Lestari",
							fotoProfil: foto,
						},
						{
							id: 8,
							nama: "Lisa Juita",
							fotoProfil: foto,
						},
						{
							id: 9,
							nama: "Ahmad Andi HS",
							fotoProfil: foto,
						},
						{
							id: 10,
							nama: "Berliana Yuni Sari",
							fotoProfil: foto,
						},
					],
				},
				{
					id: 11,
					nama: "Direktur Utama",
					children: [
						{
							id: 12,
							nama: "Tresna Fuji Lestari",
							fotoProfil: foto,
							children: [
								{
									id: 13,
									nama: "Direktur",
									children: [
										{
											id: 14,
											nama: "Lisca Juita",
											jabatan: "Pemasaran & Personalia",
											fotoProfil: foto,
											children: [
												{
													id: 15,
													nama: "Diana Rika Herdianto",
													jabatan: "Manager Produk & Pasar",
													fotoProfil: foto,
													children: [
														{
															id: 16,
															nama: "Nana Apriana",
															jabatan: "Supervisor Promosi",
															fotoProfil: foto,
															children: [
																{
																	id: 17,
																	nama: "Thalita A.",
																	jabatan: "Staff Penjualan",
																	fotoProfil: foto,
																},
															],
														},
														{
															id: 18,
															nama: "Tegar Achmadi",
															jabatan: "Supervisor Penjualan",
															fotoProfil: foto,
															children: [
																{
																	id: 19,
																	nama: "Dhea Dharika",
																	jabatan: "Staff Penjualan",
																	fotoProfil: foto,
																},
																{
																	id: 20,
																	nama: "Devi Novita S.",
																	jabatan: "Staff Penjualan",
																	fotoProfil: foto,
																},
															],
														},
													],
												},
												{
													id: 21,
													nama: "Lisa Juita",
													jabatan: "Direktur Personalia & Layanan Pelanggan",
													fotoProfil: foto,
													children: [
														{
															id: 22,
															nama: "Ade Veni Uddani",
															jabatan: "Staff Personalia",
															fotoProfil: foto,
														},
														{
															id: 23,
															nama: "Rizky Yunianti",
															jabatan: "Staff Layanan Pelanggan",
															fotoProfil: foto,
														},
														{
															id: 24,
															nama: "Siti Hartati",
															jabatan: "Staff Layanan Pelanggan",
															fotoProfil: foto,
														},
													],
												},
												{
													id: 25,
													nama: "Berliana Yuni Sari",
													jabatan: "Manager Keuangan & Investasi",
													fotoProfil: foto,
													children: [
														{
															id: 26,
															nama: "A.P. Syaputri",
															jabatan: "Staff Akuntansi & Logistik",
															fotoProfil: foto,
														},
														{
															id: 27,
															nama: "Diana Rika Herdianto",
															jabatan: "Staff Perbankan & G.A",
															fotoProfil: foto,
														},
													],
												},
											],
										},
										{
											id: 28,
											nama: "Ahmad Andi HS",
											jabatan: "Rekayasa & Teknik",
											fotoProfil: foto,
											children: [
												{
													id: 29,
													nama: "Susi Susanti",
													jabatan: "Manager Teknik Network",
													fotoProfil: foto,
													children: [
														{
															id: 30,
															nama: "Dimas Wicaksono",
															jabatan: "Supervisor Network Operation Center",
															fotoProfil: foto,
															children: [
																{
																	id: 31,
																	nama: "Indra Hartadi",
																	jabatan: "Helpdesk",
																	fotoProfil: foto,
																	children: [
																		{
																			id: 32,
																			nama: "M. Taufiq Al-Qardafi",
																			jabatan: "Staff Helpdesk",
																			fotoProfil: foto,
																		},
																	],
																},
																{
																	id: 33,
																	nama: "Maryudi",
																	jabatan: "Staff Security & Wireless",
																	fotoProfil: foto,
																},
																{
																	id: 34,
																	nama: "Sugiyatno",
																	jabatan: "Staff Security & Wireless",
																	fotoProfil: foto,
																},
															],
														},
														{
															id: 35,
															nama: "Muhadi",
															jabatan: "Supervisor Field Operation Center",
															fotoProfil: foto,
															children: [
																{
																	id: 36,
																	nama: "Guntur",
																	jabatan: "Head Team Leader Instalasi",
																	fotoProfil: foto,
																	children: [
																		{
																			id: 37,
																			nama: "Asep Gunawan",
																			jabatan: "Team Leader",
																			fotoProfil: foto,
																			children: [
																				{
																					id: 38,
																					nama: "Nopen Diansyah",
																					jabatan: "Staff",
																					fotoProfil: foto,
																				},
																				{
																					id: 39,
																					nama: "Sutopo",
																					jabatan: "Staff",
																					fotoProfil: foto,
																				},
																				{
																					id: 40,
																					nama: "Gilang R.",
																					jabatan: "Staff",
																					fotoProfil: foto,
																				},
																			],
																		},
																		{
																			id: 41,
																			nama: "M. Zainudin",
																			jabatan: "Team Leader",
																			fotoProfil: foto,
																			children: [
																				{
																					id: 42,
																					nama: "M. Ridho",
																					jabatan: "Staff",
																					fotoProfil: foto,
																				},
																				{
																					id: 43,
																					nama: "Farhan Rizki P.",
																					jabatan: "Staff",
																					fotoProfil: foto,
																				},
																				{
																					id: 44,
																					nama: "Arbi Tri A.",
																					jabatan: "Staff",
																					fotoProfil: foto,
																				},
																			],
																		},
																	],
																},
																{
																	id: 45,
																	nama: "Yugo",
																	jabatan: "Head Team Leader Instalasi",
																	fotoProfil: foto,
																	children: [
																		{
																			id: 46,
																			nama: "Kosy Rantiarno",
																			jabatan: "Staff",
																			fotoProfil: foto,
																		},
																		{
																			id: 47,
																			nama: "Sherliansyah",
																			jabatan: "Staff",
																			fotoProfil: foto,
																		},
																		{
																			id: 48,
																			nama: "Adam Alfian",
																			jabatan: "Staff",
																			fotoProfil: foto,
																		},
																		{
																			id: 49,
																			nama: "Rizky Setiawan",
																			jabatan: "Staff",
																			fotoProfil: foto,
																		},
																	],
																},
															],
														},
													],
												},
												{
													id: 50,
													nama: "Anton Hermawan",
													jabatan: "Manager Rekayasa Strategi",
													fotoProfil: foto,
													children: [
														{
															id: 51,
															nama: "Prabu Santoso",
															jabatan: "Supervisor Software Developer",
															fotoProfil: foto,
															children: [
																{
																	id: 52,
																	nama: "Ramadhani AK",
																	jabatan: "Staff Desain Grafis",
																	fotoProfil: foto,
																},
																{
																	id: 53,
																	nama: "Geri Arisman",
																	jabatan: "Staff Web Apps",
																	fotoProfil: foto,
																},
																{
																	id: 54,
																	nama: "Deo Mardianto",
																	jabatan: "Staff Cloud & AI",
																	fotoProfil: foto,
																},
															],
														},
													],
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					id: 55,
					nama: "Komisaris",
					children: [
						{
							id: 56,
							nama: "Berliana Yuni Sari",
							fotoProfil: foto,
						},
					],
				},
			],
		},
		{
			id: 3,
			nama: "Ahmad Andi HS",
			fotoProfil: foto,
		},
	],
};
