import React, { useState } from "react";

// components
import {
  CompButton,
  CompCardContainer,
  CompCardContainer2,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import { icons } from "atoms/icons";

// functions
import { handleInputError } from "pages/actions";
import dataGaji from "atoms/dataDummy/dataGaji.json";
import dataPegawai from "atoms/dataDummy/dataPegawai.json";
import dataTunjangan from "atoms/dataDummy/dataTunjangan.json";
import dataPotongan from "atoms/dataDummy/dataPotongan.json";

const PengajuanGajiSub = () => {
  // pagination
  const [limit, setLimit] = useState(10);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Pengajuan Gaji",
  });
  const [data] = useState([
    {
      id: 1,
      gaji: dataGaji[1].nominal,
      pegawai: dataPegawai[0]["data_pribadi"].nama,
      tunjangan: dataTunjangan[0].nominal,
      potongan: dataPotongan[0].nominal,
    },
  ]);
  const [dataColumns] = useState([
    { name: "ID", value: "id" },
    { name: "Data Pegawai", value: "pegawai" },
    { name: "Gaji", value: "gaji" },
    { name: "Komponen Pajak", value: "komponen_pajak" },
    { name: "Tunjangan", value: "tunjangan" },
    { name: "Potongan", value: "potongan" },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Pegawai",
      name: "pegawai",
      type: "search-select",
      value: "",
      error: "",
      required: true,
      option: [{}],
    },
    {
      label: "Gaji",
      name: "gaji",
      type: "select",
      value: "",
      error: "",
      required: true,
      option: [{}],
    },
    {
      label: "Komponen Pajak",
      name: "komponen_pajak",
      type: "select",
      value: "",
      error: "",
      required: true,
      option: [{}],
    },
    {
      label: "Tunjangan",
      name: "tunjangan",
      type: "select",
      value: "",
      error: "",
      required: true,
      option: [{}],
    },
    {
      label: "Potongan",
      name: "potongan",
      type: "select",
      value: "",
      error: "",
      required: true,
      option: [{}],
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs([
      {
        ...inputs[0],
        value: "",
      },
      {
        ...inputs[1],
        value: "",
        error: "",
      },
      {
        ...inputs[2],
        value: "",
        error: "",
      },
      {
        ...inputs[3],
        value: "",
        error: "",
      },
      {
        ...inputs[4],
        value: "",
        error: "",
      },
      {
        ...inputs[5],
        value: "",
        error: "",
      },
    ]);
  };

  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    console.log(item);
  };

  const doDelete = (item) => {
    console.log("delete", item.id);
  };

  const doSubmit = () => {
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputs[0].value === "") {
        console.log("submit", inputs);
      } else {
        console.log("edit", inputs);
      }
    }
  };

  const get = (param) => {
    console.log(param);
  };

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };
  const handleSearchSelect = (value, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = value.value;
    setInputs(newInputs);
  };

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  return (
    <div>
      <CompCardContainer2>
        <CompTableController doSearch={doSearch} onAdd={onAdd} />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={data}
            isLoading={false}
            isError={false}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={100}
          limit={limit}
          setLimit={handleSelect}
        />
      </CompCardContainer2>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form onKeyPress={(e) => e.key === "Enter" && !false && doSubmit(e)}>
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                doSubmit={doSubmit}
                handleInput={handleInput}
                handleSearchSelect={handleSearchSelect}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton btnName={"Submit"} doClick={doSubmit} onLoading={false} />
        </div>
      </CompModal>
    </div>
  );
};

export default PengajuanGajiSub;
