import React, { useCallback, useEffect, useState } from "react";

// components
import {
  CompButton,
  CompCard,
  CompCardContainer,
  CompData,
  CompForm,
  CompModal,
  CompTableController,
} from "components";

// functions
import {
  addData,
  convertJSON,
  deleteData,
  getItem,
  handleInputError,
  updateData,
} from "pages/actions";
import {
  API_URL_createcabang,
  API_URL_edelcabang,
  API_URL_getcabang,
} from "pages/constants";
import { useDispatch, useSelector } from "react-redux";
import { cabangReducer } from "reducers/cabangReducers";
import { icons } from "atoms/icons";
import axiosAPI from "authentication/axiosApi";

const CabangPage = () => {
  const {
    // getCabangResult,
    // getCabangLoading,
    // getCabangError,
    addCabangResult,
    addCabangLoading,
    deleteCabangResult,
  } = useSelector((state) => state.cabang);
  const dispatch = useDispatch();
  // State & Variables
  // const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Cabang",
    modalType: "add",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [card, setCard] = useState([]);

  const [jadwalColumns] = useState([
    { name: "Senin", value: "senin" },
    { name: "Selasa", value: "selasa" },
    { name: "Rabu", value: "rabu" },
    { name: "Kamis", value: "kamis" },
    { name: "Jumat", value: "jumat" },
    { name: "Sabtu", value: "sabtu" },
    { name: "Minggu", value: "minggu" },
  ]);
  const [jadwalData, setJadwalData] = useState({
    senin: {
      masuk: "08:00",
      keluar: "17:00",
    },
    selasa: {
      masuk: "08:00",
      keluar: "17:00",
    },
    rabu: {
      masuk: "08:00",
      keluar: "17:00",
    },
    kamis: {
      masuk: "08:00",
      keluar: "17:00",
    },
    jumat: {
      masuk: "08:00",
      keluar: "17:00",
    },
    sabtu: {
      masuk: "00:00",
      keluar: "00:00",
    },
    minggu: {
      masuk: "00:00",
      keluar: "00:00",
    },
  });

  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "pk",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama",
      name: "nama",
      type: "text",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "No Telepon",
      name: "no_telepon",
      type: "text",
      value: "",
    },
    {
      label: "Latitude",
      name: "latitude",
      type: "text",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Longitude",
      name: "longitude",
      type: "text",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Radius",
      name: "radius",
      type: "number",
      value: 25,
      error: "",
      required: true,
    },
  ]);

  const [inputsDataJamMasuk, setInputsDataJamMasuk] = useState([
    {
      label: "Senin",
      name: "senin",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Selasa",
      name: "selasa",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Rabu",
      name: "rabu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Kamis",
      name: "kamis",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Jumat",
      name: "jumat",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Sabtu",
      name: "sabtu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Minggu",
      name: "minggu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
  ]);

  const [inputsDataJamKeluar, setInputsDataJamKeluar] = useState([
    {
      label: "Senin",
      name: "senin",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Selasa",
      name: "selasa",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Rabu",
      name: "rabu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Kamis",
      name: "kamis",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Jumat",
      name: "jumat",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Sabtu",
      name: "sabtu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
    {
      label: "Minggu",
      name: "minggu",
      type: "time",
      value: "",
      required: true,
      error: "",
    },
  ]);

  // Function
  const doSearch = (e) => {
    // const { value } = e.target;
    // setSearch(value);
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen, modalType: "add" });
    setInputs(
      inputs.map((item) => {
        if (item.type === "hidden") {
          return { ...item, value: "" };
        } else {
          return { ...item, value: "", error: "" };
        }
      })
    );
    setInputsDataJamMasuk(
      inputsDataJamMasuk.map((item) => ({ ...item, value: "", error: "" }))
    );
    setInputsDataJamKeluar(
      inputsDataJamKeluar.map((item) => ({ ...item, value: "", error: "" }))
    );
  };

  const onDetail = (data) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen, modalType: "edit" });
    setIsEdit(false);
    setJadwalData(convertJSON(data.jadwal));
    setInputs(
      inputs.map((item) => {
        if (item.name === "latitude" || item.name === "longitude") {
          return {
            ...item,
            value: getItem(item.name, convertJSON(data.cordinate)),
            error: "",
          };
        } else {
          return { ...item, value: getItem(item.name, data), error: "" };
        }
      })
    );
    setInputsDataJamMasuk(
      inputsDataJamMasuk.map((item) => ({
        ...item,
        value: convertJSON(data.jadwal)[item.name].masuk,
        error: "",
      }))
    );
    setInputsDataJamKeluar(
      inputsDataJamKeluar.map((item) => ({
        ...item,
        value: convertJSON(data.jadwal)[item.name].keluar,
        error: "",
      }))
    );
  };

  const doDelete = (id) => {
    deleteData(
      { dispatch, redux: cabangReducer },
      id,
      API_URL_edelcabang,
      "DELETE_CABANG"
    );
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    const newInputMasuk = handleInputError(inputsDataJamMasuk);
    setInputsDataJamMasuk(newInputMasuk);
    const err1 = newInputMasuk.filter((item) => item.error !== "");
    const newInputKeluar = handleInputError(inputsDataJamKeluar);
    setInputsDataJamKeluar(newInputKeluar);
    const err2 = newInputKeluar.filter((item) => item.error !== "");
    if (err.length === 0 || err1.length === 0 || err2.length === 0) {
      if (inputs[0].value === "") {
        addData(
          { dispatch, redux: cabangReducer },
          {
            nama: inputs[1].value,
            no_telepon: inputs[2].value,
            latitude: inputs[3].value,
            longitude: inputs[4].value,
            radius: inputs[5].value,
            jadwal: JSON.stringify({
              senin: {
                masuk: inputsDataJamMasuk[0].value,
                keluar: inputsDataJamKeluar[0].value,
              },
              selasa: {
                masuk: inputsDataJamMasuk[1].value,
                keluar: inputsDataJamKeluar[1].value,
              },
              rabu: {
                masuk: inputsDataJamMasuk[2].value,
                keluar: inputsDataJamKeluar[2].value,
              },
              kamis: {
                masuk: inputsDataJamMasuk[3].value,
                keluar: inputsDataJamKeluar[3].value,
              },
              jumat: {
                masuk: inputsDataJamMasuk[4].value,
                keluar: inputsDataJamKeluar[4].value,
              },
              sabtu: {
                masuk: inputsDataJamMasuk[5].value,
                keluar: inputsDataJamKeluar[5].value,
              },
              minggu: {
                masuk: inputsDataJamMasuk[6].value,
                keluar: inputsDataJamKeluar[6].value,
              },
            }),
          },
          API_URL_createcabang,
          "ADD_CABANG"
        );
      } else {
        updateData(
          { dispatch, redux: cabangReducer },
          {
            pk: inputs[0].value,
            nama: inputs[1].value,
            no_telepon: inputs[2].value,
            latitude: inputs[3].value,
            longitude: inputs[4].value,
            radius: inputs[5].value,
            jadwal: JSON.stringify({
              senin: {
                masuk: inputsDataJamMasuk[0].value,
                keluar: inputsDataJamKeluar[0].value,
              },
              selasa: {
                masuk: inputsDataJamMasuk[1].value,
                keluar: inputsDataJamKeluar[1].value,
              },
              rabu: {
                masuk: inputsDataJamMasuk[2].value,
                keluar: inputsDataJamKeluar[2].value,
              },
              kamis: {
                masuk: inputsDataJamMasuk[3].value,
                keluar: inputsDataJamKeluar[3].value,
              },
              jumat: {
                masuk: inputsDataJamMasuk[4].value,
                keluar: inputsDataJamKeluar[4].value,
              },
              sabtu: {
                masuk: inputsDataJamMasuk[5].value,
                keluar: inputsDataJamKeluar[5].value,
              },
              minggu: {
                masuk: inputsDataJamMasuk[6].value,
                keluar: inputsDataJamKeluar[6].value,
              },
            }),
          },
          API_URL_edelcabang,
          "ADD_CABANG"
        );
      }
    }
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  const handleJamMasuk = (e, index) => {
    const newInputs = [...inputsDataJamMasuk];
    newInputs[index]["value"] = e.target.value;
    console.log(e.target.value);
    setInputsDataJamMasuk(newInputs);
  };

  const handleJamKeluar = (e, index) => {
    const newInputs = [...inputsDataJamKeluar];
    newInputs[index]["value"] = e.target.value;
    setInputsDataJamKeluar(newInputs);
  };

  const fetchData = useCallback(async () => {
    setModal({ ...modal, modalOpen: false });
    const response = await axiosAPI.get(API_URL_getcabang);
    setCard(response.data);
  }, [modal]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addCabangResult) {
      fetchData();
    }
  }, [addCabangResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (deleteCabangResult) {
      fetchData();
    }
  }, [deleteCabangResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="grid grid-cols-1 gap-4">
        <CompCardContainer>
          <CompTableController
            title="Cabang"
            doSearch={doSearch}
            onAdd={onAdd}
          />
        </CompCardContainer>
        <div className="grid grid-cols-6 gap-3">
          {card.map((item, itemIdx) => (
            <div
              key={itemIdx}
              className="col-span-full sm:col-span-3 lg:col-span-2"
            >
              <CompCard
                onClick={onDetail}
                deleted
                doDelete={doDelete}
                data={item}
              >
                {item.alamat}
              </CompCard>
            </div>
          ))}
        </div>
      </div>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          {/* Form */}
          {modal.modalType === "add" ? (
            // Add
            <div className="w-full">
              <form
                onKeyPress={(e) => e.key === "Enter" && !false && doSubmit(e)}
              >
                <CompCardContainer>
                  <CompForm
                    inputs={inputs}
                    setInputs={setInputs}
                    handleInput={handleInput}
                  />
                </CompCardContainer>
                <br />
                <CompCardContainer>
                  <div>
                    <div className="font-bold text-grey-800 mb-2">
                      Jam Masuk
                    </div>
                    <CompForm
                      gridCols={4}
                      inputs={inputsDataJamMasuk}
                      setInputs={setInputsDataJamMasuk}
                      handleInput={handleJamMasuk}
                    />
                  </div>
                  <br />
                  <div>
                    <div className="font-bold text-grey-800 mb-2">
                      Jam Keluar
                    </div>
                    <CompForm
                      gridCols={4}
                      inputs={inputsDataJamKeluar}
                      setInputs={setInputsDataJamKeluar}
                      handleInput={handleJamKeluar}
                    />
                  </div>
                </CompCardContainer>
              </form>
              <br />
            </div>
          ) : (
            // Edit
            <div className="w-full">
              <form
                onKeyPress={(e) => e.key === "Enter" && !false && doSubmit(e)}
              >
                <CompCardContainer>
                  <div className="flex justify-between mb-2">
                    <div className="font-bold text-grey-800">Cabang</div>
                    <div
                      onClick={() => setIsEdit(!isEdit)}
                      className="cursor-pointer"
                    >
                      {icons.fiedit}
                    </div>
                  </div>
                  {isEdit ? (
                    <CompForm
                      inputs={inputs}
                      setInputs={setInputs}
                      handleInput={handleInput}
                    />
                  ) : (
                    <CompData inputs={inputs} />
                  )}
                </CompCardContainer>

                <br />

                {/* Jadwal */}
                {isEdit ? (
                  <CompCardContainer>
                    <div>
                      <div className="font-bold text-grey-800 mb-2">
                        Jam Masuk
                      </div>
                      <CompForm
                        gridCols={4}
                        inputs={inputsDataJamMasuk}
                        setInputs={setInputsDataJamMasuk}
                        handleInput={handleJamMasuk}
                      />
                    </div>
                    <br />
                    <div>
                      <div className="font-bold text-grey-800 mb-2">
                        Jam Keluar
                      </div>
                      <CompForm
                        gridCols={4}
                        inputs={inputsDataJamKeluar}
                        setInputs={setInputsDataJamKeluar}
                        handleInput={handleJamKeluar}
                      />
                    </div>
                  </CompCardContainer>
                ) : (
                  <CompCardContainer>
                    <div className="font-bold text-grey-800 mb-2">Jadwal</div>
                    <div className="overflow-x-auto custom-scroll">
                      <table className="table-auto w-full">
                        <thead className="bg-transparent border-b border-grey-300">
                          <tr>
                            {jadwalColumns.map((item, itemIdx) => (
                              <th
                                key={itemIdx}
                                className="text-grey-800 text-sm font-semibold"
                              >
                                {item.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={0}>
                            {jadwalColumns.map((item2, item2Idx) => (
                              <td
                                key={item2Idx}
                                className="text-center border-b border-grey-300 text-grey-800 text-sm p-2"
                              >
                                {jadwalData[item2.value].masuk} -{" "}
                                {jadwalData[item2.value].keluar}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CompCardContainer>
                )}
                <br />
              </form>
            </div>
          )}
        </div>

        {/* Button */}
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={doSubmit}
            onLoading={addCabangLoading}
          />
        </div>
      </CompModal>
    </div>
  );
};

export default CabangPage;
