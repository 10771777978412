import React from "react";
import { Link } from "react-router-dom";

// components
import { icons } from "atoms/icons";

const NotFoundPage = () => {
  return (
    <div className="h-screen w-screen flex justify-center flex-col items-center bg-base p-3">
      <img
        src={process.env.PUBLIC_URL + "/assets/NotFound.svg"}
        alt=""
        className="w-1/2 md:w-1/3"
      />
      <div className="text-sm mt-5 font-medium flex items-center">
        Oops, This Page Could Not Be Found.
      </div>
      <Link
        to="/"
        className="text-sm mt-5 font-medium flex items-center hover:text-red-300"
      >
        <span className="mr-1">{icons.aioutlinearrowleft}</span> Go Back
      </Link>
    </div>
  );
};

export default NotFoundPage;
