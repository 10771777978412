import React, { useCallback, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { API_URL_createkalender, API_URL_getkalender } from "pages/constants";
import { CompButton, CompCardContainer, CompForm, CompModal } from "components";
import { useDispatch, useSelector } from "react-redux";
import { kalenderReducer } from "reducers/kalenderReducers";
import { addData, handleInputError } from "pages/actions";
import axiosAPI from "authentication/axiosApi";
import moment from "moment";

const KalenderPage = () => {
  const { addKalenderResult, addKalenderLoading } = useSelector(
    (state) => state.kalender
  );
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const [kalender, setKalender] = useState([]);
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "tambah Event",
  });

  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama Event",
      name: "nama_event",
      type: "text",
      placeholder: "Nama Event",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Tanggal Mulai",
      name: "tgl_mulai",
      type: "date",
      placeholder: "Tanggal Mulai",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Tanggal Berakhir",
      name: "tgl_berakhir",
      type: "date",
      placeholder: "Tanggal Berakhir",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Type Event",
      name: "type_event",
      type: "select",
      placeholder: "Type Event",
      value: "",
      error: "",
      required: true,
      option: [
        { label: "Libur", value: true },
        { label: "Event ", value: false },
      ],
    },
  ]);

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputs[0].value === "") {
        addData(
          { dispatch, redux: kalenderReducer },
          {
            title: inputs[1].value,
            start_date: inputs[2].value,
            end_date: inputs[3].value,
            is_national_holiday: Boolean(inputs[4].value),
          },
          API_URL_createkalender,
          "ADD_KALENDER"
        );
      }
    }
  };

  const getEvent = async (month_year = false) => {
    var params = {};
    if (month_year) {
      params = { "month-year": month_year };
    }

    const response = await axiosAPI.get(API_URL_getkalender, { params });
    setKalender(response.data);
  };

  const fetchData = useCallback(async () => {
    setModal({ ...modal, modalOpen: false });
    setInputs(inputs.map((item) => ({ ...item, value: "", error: "" })));
    getEvent();
  }, [modal, inputs]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addKalenderResult) {
      fetchData();
    }
  }, [addKalenderResult]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <FullCalendar
        ref={calendarRef}
        customButtons={{
          buttonAdd: {
            text: "Tambah +",
            click: function () {
              setModal({ ...modal, modalOpen: !modal.modalOpen });
            },
          },
          prev: {
            click: (e) => {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.prev();
              const date = calendarApi.currentDataManager.data.currentDate;
              getEvent(moment(date).format("YYYY-MM"));
            },
          },
          next: {
            click: (e) => {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.next();
              const date = calendarApi.currentDataManager.data.currentDate;
              getEvent(moment(date).format("YYYY-MM"));
            },
          },
          today: {
            text: "Today",
            click: (e) => {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.today();
              const date = calendarApi.currentDataManager.data.currentDate;
              getEvent(moment(date).format("YYYY-MM"));
            },
          },
        }}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next,today",
          center: "title",
          right: "buttonAdd",
        }}
        eventColor="#FF2300"
        events={kalender}
      />
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form
            onKeyPress={(e) =>
              e.key === "Enter" && !addKalenderLoading && doSubmit(e)
            }
          >
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={(e) => doSubmit(e)}
            onLoading={addKalenderLoading}
          />
        </div>
      </CompModal>
    </div>
  );
};

export default KalenderPage;
