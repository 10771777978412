import React, { Fragment, useState } from "react";
import { CompTabs } from "components";
import KinerjaSub from "./Sub/KinerjaSub";
import PenghasilanSub from "./Sub/PenghasilanSub";

const RaportPage = () => {
  const [tabs] = useState({
    idTabs: "raportTabs",
    idContents: "raportTabs-Content",
    listTabs: [
      {
        nameTabs: "Kinerja",
        linkTabs: "raportKinerja",
        contentTabs: <KinerjaSub />,
      },
      {
        nameTabs: "Penghasilan",
        linkTabs: "raportPenghasilan",
        contentTabs: <PenghasilanSub />,
      },
    ],
  });

  return (
    <Fragment>
      <CompTabs tabs={tabs} />
    </Fragment>
  );
};

export default RaportPage;
