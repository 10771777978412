import React, { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";

// components
import CompSidebar from "./CompSidebar";
import CompHeader from "./CompHeader";

const CompMainLayout = () => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <div className="flex ">
        <CompSidebar open={open} setOpen={setOpen} />
        <div className="w-full h-screen overflow-clip">
          <CompHeader open={open} setOpen={setOpen} />
          <div className="p-6 h-[90vh] overflow-y-auto bg-grey-100 custom-scroll">
            <Outlet />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CompMainLayout;
