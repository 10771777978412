import React, { Fragment, useState } from "react";
import { CompTabs } from "components";
import ReimbusementSub from "./Sub/ReimbusementSub";
import CashAdvantageSub from "./Sub/CashAdvantageSub";
import KasbonSub from "./Sub/KasbonSub";

const TransaksiPage = () => {
  const [tabs] = useState({
    idTabs: "transaksiTabs",
    idContents: "transaksiTabs-Content",
    listTabs: [
      {
        nameTabs: "Reimbusement",
        linkTabs: "transaksiReimbusement",
        contentTabs: <ReimbusementSub />,
      },
      {
        nameTabs: "Cash Advantage",
        linkTabs: "transaksiCashAdvantage",
        contentTabs: <CashAdvantageSub />,
      },
      {
        nameTabs: "Kasbon",
        linkTabs: "transaksiKasbon",
        contentTabs: <KasbonSub />,
      },
    ],
  });

  return (
    <Fragment>
      <CompTabs tabs={tabs} />
    </Fragment>
  );
};

export default TransaksiPage;
