import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className="text-grey-800 h-screen w-full">
      <div className="text-2xl text-white shadow-lg text-center font-bold py-10 bg-gradient-to-r from-gradient-pink-100 to-gradient-yellow-100">
        Kebijakan dan Privasi
      </div>
      <div className="p-8 overflow-auto h-screen">
        <div className="mb-5">
          <div className="text-xl font-bold mb-3">Kebijakan Pribadi</div>
          <div className="mb-2">
            PT Queen Network Nusantara membangun aplikasi E-Gawi sebagai
            aplikasi absensi dan Task Pekerjaan. Layanan ini disediakan oleh PT
            Queen Network Nusantara tanpa biaya dan dimaksudkan untuk digunakan
            apa adanya.
          </div>
          <div className="mb-2">
            Halaman ini digunakan untuk memberi tahu pengunjung mengenai
            kebijakan kami dengan pengumpulan, penggunaan, dan pengungkapan
            Informasi Pribadi jika ada yang memutuskan untuk menggunakan Layanan
            kami.
          </div>
          <div className="mb-2">
            Jika Anda memilih untuk menggunakan Layanan kami, maka Anda
            menyetujui pengumpulan dan penggunaan informasi sehubungan dengan
            kebijakan ini. Informasi Pribadi yang kami kumpulkan digunakan untuk
            menyediakan dan meningkatkan Layanan. Kami tidak akan menggunakan
            atau membagikan informasi Anda dengan siapa pun kecuali seperti yang
            dijelaskan dalam Kebijakan Privasi ini.
          </div>
          <div className="mb-2">
            Istilah yang digunakan dalam Kebijakan Privasi ini memiliki arti
            yang sama seperti dalam Syarat dan Ketentuan kami, yang dapat
            diakses di E-Gawi kecuali ditentukan lain dalam Kebijakan Privasi
            ini.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">
            Pengumpulan dan Penggunaan Informasi
          </div>
          <div className="mb-2">
            Untuk pengalaman yang lebih baik, saat menggunakan Layanan kami,
            kami mungkin meminta Anda untuk memberikan kami informasi identitas
            pribadi tertentu, termasuk namun tidak terbatas pada Lokasi dan Data
            Biometrik. Informasi yang kami minta akan disimpan oleh kami dan
            digunakan seperti yang dijelaskan dalam kebijakan privasi ini.
          </div>
          <div className="mb-2">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Data Pribadi: E-Gawi mengumpulkan data pribadi terkait pekerjaan
                seperti nama lengkap, ID karyawan, jadwal karyawan, nama cabang
                dan lokasi kantor. Saat menggunakan aplikasi, kami mengumpulkan
                foto selfie dan lokasi GPS pengguna.
              </li>
              <li>
                Berbagi Pihak Ketiga: E-Gawi membagikan data check in dan check
                out karyawan kepada perusahaan tempat pengguna berada. Data
                pribadi hanya dapat dikonsumsi oleh perusahaan tempat pengguna
                bekerja, dan pengguna itu sendiri. E-Gawi tidak memiliki akses
                ke data pribadi, kecuali diminta oleh perusahaan untuk membantu
                dalam kegiatan dukungan (reorganisasi karyawan, integrasi ke
                sistem perusahaan yang ada).
              </li>
              <li>
                Penyimpanan Data: Data pribadi disimpan dalam database. Data
                pribadi dienkripsi dan hanya dapat diakses oleh personel yang
                berwenang dari perusahaan, dan pengguna itu sendiri.
              </li>
              <li>
                Perlindungan Data: Data pribadi yang disimpan dienkripsi dengan
                hanya orang yang berwenang dari perusahaan yang relevan dan
                pengguna itu sendiri yang dapat mengaksesnya. Kami menerapkan
                praktik terbaik keamanan standar dalam melindungi API dan
                database kami, termasuk penggunaan kontrol akses pengguna,
                enkripsi database, dan kunci API berbasis token.
              </li>
              <li>
                Persetujuan: Proses pencabutan izin dan penghapusan data dapat
                dilakukan melalui perusahaan tempat pengguna bekerja. Setiap
                perusahaan yang bekerja dengan kami memiliki kebijakan berbeda
                terkait data pribadi dan prosedur kerja, dan kami akan membantu
                pengguna melalui saluran masing-masing di tempat kerja mereka
                sendiri.
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">Data Log</div>
          <div className="mb-2">
            Kami ingin memberi tahu Anda bahwa setiap kali Anda menggunakan
            Layanan kami, jika terjadi kesalahan dalam aplikasi, kami
            mengumpulkan data dan informasi (melalui produk pihak ketiga) di
            ponsel Anda yang disebut Data Log. Data Log ini dapat mencakup
            informasi seperti alamat Protokol Internet ("IP") perangkat Anda,
            nama perangkat, versi sistem operasi, konfigurasi aplikasi saat
            menggunakan Layanan kami, waktu dan tanggal penggunaan Anda atas
            Layanan, dan statistik lainnya.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">Cookies</div>
          <div className="mb-2">
            Cookie adalah file dengan sejumlah kecil data yang biasanya
            digunakan sebagai pengenal unik anonim. Ini dikirim ke browser Anda
            dari situs web yang Anda kunjungi dan disimpan di memori internal
            perangkat Anda.
          </div>
          <div className="mb-2">
            Layanan ini tidak menggunakan "cookie" ini secara eksplisit. Namun,
            aplikasi dapat menggunakan kode dan pustaka pihak ketiga yang
            menggunakan "cookie" untuk mengumpulkan informasi dan meningkatkan
            layanan mereka. Anda memiliki opsi untuk menerima atau menolak
            cookie ini dan mengetahui kapan cookie dikirim ke perangkat Anda.
            Jika Anda memilih untuk menolak cookie kami, Anda mungkin tidak
            dapat menggunakan beberapa bagian dari Layanan ini.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">Penyedia Layanan</div>
          <div className="mb-2">
            Kami dapat mempekerjakan perusahaan pihak ketiga dan individu karena
            alasan berikut:
          </div>
          <ul className="list-disc list-inside space-y-2">
            <li>Untuk memfasilitasi Layanan kami;</li>
            <li>Untuk menyediakan Layanan atas nama kami;</li>
            <li>Untuk melakukan layanan terkait Layanan; atau</li>
            <li>
              Untuk membantu kami dalam menganalisis bagaimana Layanan kami
              digunakan.
            </li>
          </ul>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">Keamanan</div>
          <div className="mb-2">
            Kami menghargai kepercayaan Anda dalam memberikan Informasi Pribadi
            Anda kepada kami, oleh karena itu kami berusaha untuk menggunakan
            cara yang dapat diterima secara komersial untuk melindunginya. Namun
            perlu diingat bahwa tidak ada metode transmisi melalui internet,
            atau metode penyimpanan elektronik yang 100% aman dan andal, dan
            kami tidak dapat menjamin keamanan absolutnya.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">
            Penggunaan Informasi Anda untuk Penggunaan Pemasaran - Hak Anda
          </div>
          <div className="mb-2">
            Dengan mengunjungi Situs Web dan aplikasi seluler kami, Anda
            mengetahui dan menyetujui bahwa kami dapat menghubungi Anda melalui
            nomor telepon atau alamat email yang Anda masukkan pada aplikasi
            untuk membantu Anda atau mendiskusikan produk dan layanan lain yang
            dapat disediakan untuk Anda.
          </div>
          <div className="mb-2">
            Situs kami setiap saat dapat berisi tautan ke dan dari situs web dan
            aplikasi seluler dari mitra jaringan, pengiklan, dan afiliasi kami.
            Jika Anda mengikuti salah satu tautan dari bagian mana pun dari
            situs web dan aplikasi seluler ini, harap dicatat bahwa situs web
            dan aplikasi seluler (pada tautan itu) memiliki kebijakan privasi
            dan kami tidak menerima tanggung jawab atau jaminan apa pun atas
            kebijakan di situs web dan aplikasi seluler itu. Pembukaan Informasi
            Anda
          </div>
          <div className="mb-2">
            Kami dapat mengungkapkan informasi pribadi Anda kepada
            subkontraktor, agen, penyedia layanan, peminjam, dan anggota grup
            perusahaan kami dengan tujuan menyediakan beberapa layanan untuk
            Anda untuk tujuan verifikasi saja. Ini bisa tentang informasi
            berikut:
          </div>
          <ul className="list-disc list-inside space-y-2">
            <li>
              Kami tidak pernah dan tidak akan pernah menjual data klien kami
              kepada pihak yang tidak berwenang karena kami menjaga privasi
              klien kami.
            </li>
            <li>
              Kami tidak memberikan atau menjual data Anda kepada pihak ketiga
              tanpa persetujuan dan tujuan yang jelas dari Anda.
            </li>
            <li>
              Kami membuka dan menggunakan data Anda hanya untuk tujuan
              verifikasi, di mana data ini diberikan kepada pihak ketiga yang
              kami tunjuk untuk melakukan proses verifikasi.
            </li>
            <li>
              Jika Anda memberi kami informasi yang salah dan tidak akurat dan /
              atau kami mencurigai adanya penipuan, kami dapat mencatatnya dalam
              sejarah kredibilitas Anda dan dapat membagikan informasi tersebut
              dengan badan hukum yang sesuai atau pihak berwenang dan / atau
              lembaga pencegahan penipuan.
            </li>
          </ul>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">Tautan ke Situs Lain</div>
          <div className="mb-2">
            Layanan ini mungkin berisi tautan ke situs lain. Jika Anda mengklik
            tautan pihak ketiga, Anda akan diarahkan ke situs itu. Perhatikan
            bahwa situs eksternal ini tidak dioperasikan oleh kami. Oleh karena
            itu, kami sangat menyarankan Anda untuk meninjau Kebijakan Privasi
            situs web ini. Kami tidak memiliki kendali atas dan tidak
            bertanggung jawab atas konten, kebijakan privasi, atau praktik situs
            atau layanan pihak ketiga mana pun.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">Privasi Anak-anak</div>
          <div className="mb-2">
            Layanan ini tidak ditujukan kepada siapa pun yang berusia di bawah
            13 tahun. Kami tidak dengan sengaja mengumpulkan informasi yang
            dapat diidentifikasi secara pribadi dari anak-anak di bawah 13
            tahun. Jika kami menemukan bahwa seorang anak di bawah 13 tahun
            telah memberi kami informasi pribadi, kami segera menghapusnya dari
            server kami. Jika Anda adalah orang tua atau wali dan Anda
            mengetahui bahwa anak Anda telah memberi kami informasi pribadi,
            silakan hubungi kami sehingga kami dapat melakukan tindakan yang
            diperlukan.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-xl font-bold mb-3">
            Perubahan Kebijakan Privasi Ini
          </div>
          <div className="mb-2">
            Kami dapat memperbarui Kebijakan Privasi kami dari waktu ke waktu.
            Dengan demikian, Anda disarankan untuk meninjau halaman ini secara
            berkala untuk setiap perubahan. Kami akan memberi tahu Anda tentang
            perubahan apa pun dengan memposting Kebijakan Privasi baru di
            halaman ini. Perubahan ini efektif segera setelah diposting di
            halaman ini.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
