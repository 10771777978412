import React from "react";
import { Navigate } from "react-router-dom";

// functions
import { isAuthenticated } from "authentication/authenticationApi";

const PrivateRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  } else if (
    !isAuthenticated().level.filter(
      (item) => item === "Super Admin" || item === "Admin"
    ).length > 0
  ) {
    return <Navigate to="/forbidden" replace />;
  }

  return children;
};

export default PrivateRoute;
