import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import {
  CompCardContainer2,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";

// functions
import { getData, status } from "pages/actions";
import { API_URL_getdatapengajuancuti } from "pages/constants";
import { pengajuanIzinReducer } from "reducers/asesmenReducers";
import moment from "moment";

const TolakSub = () => {
  const {
    getIzinDitolakResult,
    getIzinDitolakLoading,
    getIzinDitolakError,
    updatePengajuanResult,
  } = useSelector((state) => state.asesmen);
  const dispatch = useDispatch();

  // pagination
  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);

  // States & Variables
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState(moment(new Date()).format("YYYY-MM"));

  const [dataColumns] = useState([
    { name: "No", value: "no" },
    { name: "Tanggal Pengajuan", value: "created_at" },
    { name: "Nama", value: "user.first_name" },
    { name: "Jenis Cuti", value: "jeniscuti.jenis" },
    { name: "Alasan Cuti", value: "alasan_cuti" },
    { name: "Tanggal Cuti", value: "tgl_cuti" },
    { name: "Lama Cuti (hari)", value: "lama_cuti" },
    { name: "Catatan", value: "catatan" },
    { name: "File", value: "file" },
    {
      name: "Status",
      value: "status",
      status: [
        { label: "diproses", value: 1, color: "bg-cyan-500" },
        { label: "diterima", value: 2, color: "bg-green-500" },
        { label: "ditolak", value: 3, color: "bg-red-500" },
      ],
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
    get({
      param: "?search=" + value + "&date-month=" + filter + "&status=3",
    });
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: pengajuanIzinReducer },
        param,
        API_URL_getdatapengajuancuti,
        "GET_IZIN_DITOLAK"
      );
    },
    [dispatch]
  );

  const handleFilterDate = (e) => {
    const param =
      search === ""
        ? { param: "?date-month=" + e + "&status=3&limit=" + limit }
        : {
            param:
              "?search=" +
              search +
              "&date-month=" +
              e +
              "&status=3&limit=" +
              limit,
          };
    setFilter(e);
    setPageActive(0);
    get(param);
  };

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? {
            param:
              "?date-month=" +
              filter +
              "&status=3&limit=" +
              limit +
              "&offset=" +
              offset,
          }
        : {
            param:
              "?search=" +
              search +
              "&date=" +
              filter +
              "&status=3&limit=" +
              limit +
              "&offset=" +
              offset,
          };
    get(param);
    setOffset(offset);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?date-month=" + filter + "&status=3&limit=" + e }
        : {
            param:
              "?search=" +
              search +
              "&date-month=" +
              filter +
              "&status=3&limit=" +
              limit,
          };
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  const fetchData = useCallback(
    async (param = false) => {
      get(
        param
          ? param
          : { param: "?date-month=" + filter + "&status=3&limit=" + limit }
      );
    },
    [filter, limit, get]
  );

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (updatePengajuanResult) {
      const param =
        search === ""
          ? {
              param:
                "?date-month=" +
                filter +
                "&status=3&limit=" +
                limit +
                "&offset=" +
                offset,
            }
          : {
              param:
                "?search=" +
                search +
                "&date-month=" +
                filter +
                "&status=3&limit=" +
                limit +
                "&offset=" +
                offset,
            };
      fetchData(param);
    }
  }, [updatePengajuanResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer2>
        <CompTableController
          title="Cuti Ditolak"
          doSearch={doSearch}
          filter={filter}
          setFilter={handleFilterDate}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={
              getIzinDitolakResult.count > 0
                ? getIzinDitolakResult.results
                : null
            }
            isLoading={getIzinDitolakLoading}
            isError={getIzinDitolakError}
            offset={offset}
            status={status}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={
            getIzinDitolakResult.count > 0 ? getIzinDitolakResult.count : 0
          }
          limit={limit}
          pageActive={pageActive}
          setLimit={handleSelect}
        />
      </CompCardContainer2>
    </div>
  );
};

export default TolakSub;
