import React from "react";

// components
import { icons } from "atoms/icons";
import { logoutUser } from "pages/actions/auth";
import { useDispatch } from "react-redux";

const ForbiddenPage = () => {
  const dispatch = useDispatch();
  const goBack = () => {
    logoutUser(dispatch);
  };
  return (
    <div className="h-screen w-screen flex justify-center flex-col items-center bg-base p-3">
      <img
        src={process.env.PUBLIC_URL + "/assets/Forbidden.svg"}
        alt=""
        className="w-1/2 md:w-1/3"
      />
      <div className="text-sm text-center mt-5 font-medium flex items-center">
        Oops, You Don't Have Permission To Access This Site.
      </div>
      <button
        onClick={goBack}
        className="text-sm mt-5 font-medium flex items-center hover:text-red-300"
      >
        <span className="mr-1">{icons.aioutlinearrowleft}</span> Go Back
      </button>
    </div>
  );
};

export default ForbiddenPage;
