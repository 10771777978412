import React, { useState } from "react";

// components
import {
  CompButton,
  CompCardContainer,
  CompCardContainer2,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import { icons } from "atoms/icons";
import dataRekrutmen from "atoms/dataDummy/dataRekrutmen.json";

const PengajuanSub = () => {
  // pagination
  const [limit, setLimit] = useState(10);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Pengajuan",
  });
  const [dataColumns] = useState([
    { name: "ID", value: "id" },
    { name: "Deskripsi", value: "deskripsi" },
    { name: "Kebutuhan", value: "kebutuhan" },
    { name: "Calon Pegawai", value: "calon_pegawai" },
    { name: "Jabatan", value: "jabatan" },
    { name: "Departemen", value: "departemen" },
    { name: "Divisi", value: "divisi" },
    { name: "Unit", value: "unit" },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Deskripsi",
      name: "deskripsi",
      type: "text",
      value: "",
    },
    {
      label: "Kebutuhan",
      name: "kebutuhan",
      type: "text",
      value: "",
    },
    {
      label: "Calon Pegawai",
      name: "calon_pegawai",
      type: "text",
      value: "",
    },
    {
      label: "Jabatan",
      name: "jabatan",
      type: "text",
      value: "",
    },
    {
      label: "Departemen",
      name: "departemen",
      type: "text",
      value: "",
    },
    {
      label: "Divisi",
      name: "divisi",
      type: "text",
      value: "",
    },
    {
      label: "Unit",
      name: "unit",
      type: "text",
      value: "",
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs([
      {
        ...inputs[0],
        value: "",
      },
      {
        ...inputs[1],
        value: "",
      },
      {
        ...inputs[2],
        value: "",
      },
      {
        ...inputs[3],
        value: "",
      },
      {
        ...inputs[4],
        value: "",
      },
      {
        ...inputs[5],
        value: "",
      },
      {
        ...inputs[6],
        value: "",
      },
      {
        ...inputs[7],
        value: "",
      },
    ]);
  };

  const onEdit = (id) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    console.log("edit", id);
  };

  const doDelete = (id) => {
    console.log("delete", id);
  };

  const doSubmit = () => {
    if (inputs[0].value === "") {
      console.log("submit", inputs);
    } else {
      console.log("edit", inputs);
    }
  };

  const get = (param) => {
    console.log(param);
  };

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  return (
    <div>
      <CompCardContainer2>
        <CompTableController
          title="Pengajuan"
          doSearch={doSearch}
          onAdd={onAdd}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={dataRekrutmen}
            isLoading={false}
            isError={false}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={100}
          limit={limit}
          setLimit={handleSelect}
        />
      </CompCardContainer2>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form onSubmit={doSubmit}>
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton btnName={"Submit"} doClick={doSubmit} onLoading={false} />
        </div>
      </CompModal>
    </div>
  );
};

export default PengajuanSub;
