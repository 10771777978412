import { useCallback, useEffect, useState } from "react";

// components
import { icons } from "atoms/icons";
import {
  CompButton,
  CompCardContainer,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";

// functions
import {
  addFormData,
  deleteData,
  getData,
  handleInputError,
  updateFormData,
} from "pages/actions";
import {
  API_URL_createtugas,
  API_URL_edeltugas,
  API_URL_getpegawai,
  API_URL_getriwayattugas,
  API_URL_gettugas,
  baseurl,
} from "pages/constants";
import { useDispatch, useSelector } from "react-redux";
import { penugasanReducer } from "reducers/penugasanReducers";
import moment from "moment";
import { Chip } from "@material-tailwind/react";
import { isAuthenticated } from "authentication/authenticationApi";
import axiosAPI from "authentication/axiosApi";

const PenugasanPage = () => {
  const {
    getTugasResult,
    getTugasLoading,
    getTugasError,
    addTugasResult,
    addTugasLoading,
    deleteTugasResult,
  } = useSelector((state) => state.tugas);
  const dispatch = useDispatch();

  // pagination
  const [firstFetch, setFirstFetch] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageActive, setPageActive] = useState(0);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Penugasan",
  });
  const [modalDetail, setModalDetail] = useState({
    modalOpen: false,
    modalTitle: "Detail Penugasan",
  });
  const [detail, setDetail] = useState({});
  const [dataRiwayat, setDataRiwayat] = useState([]);
  const [dataColumns] = useState([
    { name: "ID", value: "id" },
    { name: "Judul Tugas", value: "judul" },
    {
      name: "Pengirim Tugas",
      value: "pengirim.nama",
    },
    {
      name: "Penerima Tugas",
      value: "penerima",
      array: "nama",
    },
    {
      name: "Prioritas",
      value: "prioritas",
      status: [
        { label: "Tinggi", value: "1", color: "bg-red-500" },
        { label: "Sedang", value: "2", color: "bg-amber-500" },
        { label: "Rendah", value: "3", color: "bg-green-500" },
      ],
    },
    { name: "Tanggal Mulai", value: "start_date" },
    { name: "Tanggal Selesai", value: "end_date" },
    {
      name: "Status",
      value: "status",
      status: [
        { label: "Menunggu Persetujuan", value: 0, color: "bg-amber-500" },
        { label: "Proses", value: 1, color: "bg-blue-500" },
        { label: "Meminta Ulasan", value: 2, color: "bg-amber-500" },
        { label: "Selesai", value: 3, color: "bg-green-500" },
        { label: "Ditolak", value: 4, color: "bg-red-500" },
      ],
    },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Judul Tugas",
      name: "judul",
      placeholder: "Masukkan Judul Tugas",
      type: "text",
      value: "",
      required: true,
    },
    {
      label: "Prioritas",
      name: "prioritas",
      placeholder: "Pilih Prioritas",
      type: "select",
      value: "",
      option: [
        { value: "1", label: "Tinggi" },
        { value: "2", label: "Sedang" },
        { value: "3", label: "Rendah" },
      ],
      required: true,
    },
    {
      label: "Deskripsi Tugas",
      name: "deskripsi",
      placeholder: "Masukkan Deskripsi Tugas",
      type: "textarea",
      value: "",
      fullWidth: true,
      required: true,
    },
    {
      label: "Penerima Tugas",
      name: "penerima",
      type: "search-select",
      value: "",
      option: [],
      required: true,
      isMulti: true,
      fullWidth: true,
    },
    {
      label: "File Pendukung",
      name: "file_pendukung",
      type: "file",
      value: "",
      data: null,
      fullWidth: true,
      link: "",
    },
    {
      label: "Tanggal Mulai",
      name: "start_date",
      type: "datetime-local",
      value: "",
      required: true,
    },
    {
      label: "Tanggal Berakhir",
      name: "end_date",
      type: "datetime-local",
      value: "",
      required: true,
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
    setPageActive(0);
  };

  const onAdd = () => {
    if (inputs[4].option.length > 0) {
      setModal({ ...modal, modalOpen: !modal.modalOpen });
      const newInput = inputs.map((item) => {
        if (item.type === "group-input") {
          return {
            ...item,
            groupInput: [
              [
                {
                  name: "file_pendukung",
                  type: "file",
                  value: "",
                  data: null,
                  fullWidth: true,
                  link: "",
                },
              ],
            ],
          };
        }
        if (item.type === "search-select") {
          return { ...item, disabled: false, value: "" };
        }
        return { ...item, value: "" };
      });
      setInputs(newInput);
    }
  };

  const onEdit = (item) => {
    if (inputs[4].option.length > 0) {
      setModal({ ...modal, modalOpen: !modal.modalOpen });
      const newInput = inputs.map((input) => {
        if (input.type === "search-select") {
          return {
            ...input,
            value: item[input.name].map((item) => ({
              value: item.id,
              label: item.nama,
            })),
          };
        }
        if (input.type === "file") {
          return {
            ...input,
            data: null,
            link: item.file ? baseurl + item.file : "",
          };
        }
        return { ...input, value: item[input.name] };
      });
      setInputs(newInput);
    }
  };

  const doDelete = (item) => {
    deleteData(
      { dispatch, redux: penugasanReducer },
      item.id,
      API_URL_edeltugas,
      "DELETE_TUGAS"
    );
  };

  const doDetail = useCallback(async (detail) => {
    try {
      const res = await axiosAPI.post(API_URL_getriwayattugas, {
        tugas_id: detail.id,
      });
      setDataRiwayat(res.data);
      setDetail(detail);
      setModalDetail({
        ...modalDetail,
        modalOpen: !modalDetail.modalOpen,
        modalTitle: detail.judul,
      });
    } catch (error) {
      console.log(error);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const doSubmit = (e) => {
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      const penerima = [];
      const formData = new FormData();
      formData.append("pengirim", isAuthenticated().user_id);
      formData.append("judul", inputs[1].value);
      formData.append("prioritas", inputs[2].value);
      formData.append("deskripsi", inputs[3].value);
      inputs[4].value.forEach((item) => {
        penerima.push(item.value);
      });
      formData.append("penerima", JSON.stringify(penerima));
      formData.append("file", inputs[5].data);
      formData.append(
        "start_date",
        moment(inputs[6].value).format("YYYY-MM-DD")
      );
      formData.append("end_date", moment(inputs[7].value).format("YYYY-MM-DD"));
      if (inputs[0].value === "") {
        addFormData(
          { dispatch, redux: penugasanReducer },
          formData,
          API_URL_createtugas,
          "ADD_TUGAS"
        );
      } else {
        updateFormData(
          { dispatch, redux: penugasanReducer },
          formData,
          API_URL_edeltugas,
          "ADD_TUGAS",
          inputs[0].value
        );
      }
    }
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: penugasanReducer },
        param,
        API_URL_gettugas,
        "GET_TUGAS"
      );
    },
    [dispatch]
  );

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    get(param);
    setPageActive(e.selected);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
    setPageActive(0);
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };
  const handleSearchSelect = (value, index) => {
    const newInputs = [...inputs];
    console.log(value);
    newInputs[4].value = value;
    setInputs(newInputs);
  };

  // Action Button
  const [actions] = useState([
    {
      name: "detail",
      icon: icons.aifilleye,
      color: "text-grey-700",
      func: doDetail,
    },
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  const fetchData = useCallback(
    async (param = false) => {
      setModal({ ...modal, modalOpen: false });
      setFirstFetch(true);
      get(param ? param : { param: "?limit=" + limit });
      try {
        const newInputs = [...inputs];
        const res = await axiosAPI.get(`${API_URL_getpegawai}?nama=`);

        const data = res.data.map((item) => ({
          value: item.id,
          label: item.nama,
        }));
        newInputs[4].option = data.filter(
          (item) => item.value !== isAuthenticated().user_id
        );

        setInputs(newInputs);
      } catch (error) {
        console.log(error);
      }
    },
    [modal, limit, inputs, get]
  );

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addTugasResult || deleteTugasResult) {
      const offset = pageActive * limit;
      const param =
        search === ""
          ? { param: "?limit=" + limit + "&offset=" + offset }
          : {
              param:
                "?search=" + search + "&limit=" + limit + "&offset=" + offset,
            };
      fetchData(param);
    }
  }, [addTugasResult, deleteTugasResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (firstFetch) {
      const getData = setTimeout(() => {
        get({ param: "?search=" + search });
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [search]);

  return (
    <div>
      <CompCardContainer>
        <CompTableController
          title="Penugasan"
          onAdd={onAdd}
          doSearch={doSearch}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={
              getTugasResult.count > 0 ? getTugasResult.results : null
            }
            isLoading={getTugasLoading}
            isError={getTugasError}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={getTugasResult.count > 0 ? getTugasResult.count : 0}
          limit={limit}
          setLimit={handleSelect}
          pageActive={pageActive}
        />
      </CompCardContainer>

      {/* Modal */}
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form
            onKeyUp={(e) =>
              e.key === "Enter" && !addTugasLoading && doSubmit(e)
            }
          >
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
                handleSearchSelect={handleSearchSelect}
              />
            </CompCardContainer>
          </form>
          <br />
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={doSubmit}
            onLoading={addTugasLoading}
          />
        </div>
      </CompModal>

      {/* Modal Detail */}
      <CompModal modal={modalDetail} setModal={setModalDetail}>
        <div className="w-full">
          <CompCardContainer>
            <div className="mb-2 flex justify-between">
              <div>
                <div className="font-medium">Pengirim</div>
                <div>
                  {detail?.pengirim?.nama ? detail?.pengirim?.nama : "-"}
                </div>
              </div>
              <div>
                {detail.status === 0 ? (
                  <Chip
                    variant="gradient"
                    value="Menunggu Persetujuan"
                    color="amber"
                  />
                ) : detail.status === 1 ? (
                  <Chip variant="gradient" value="Proses" color="blue" />
                ) : detail.status === 2 ? (
                  <Chip
                    variant="gradient"
                    value="Meminta Ulasan"
                    color="amber"
                  />
                ) : detail.status === 3 ? (
                  <Chip variant="gradient" value="Selesai" color="green" />
                ) : detail.status === 4 ? (
                  <Chip variant="gradient" value="Ditolak" color="red" />
                ) : null}
              </div>
            </div>

            <div className="mb-2">
              <div className="font-medium">Penerima</div>
              <div className="">
                {detail?.penerima?.map((item, itemIdx) => {
                  if (itemIdx === detail?.penerima.length - 1) {
                    return item.nama;
                  } else {
                    return item.nama + ", ";
                  }
                })}
              </div>
            </div>

            <div className="mb-2">
              <div className="font-medium">Deskripsi</div>
              <div className="">
                {detail?.deskripsi ? detail?.deskripsi : "-"}
              </div>
            </div>

            <div className="mb-2">
              <div className="font-medium">Prioritas</div>
              <div className="">
                {detail?.prioritas === "1"
                  ? "Tinggi"
                  : detail?.prioritas === "2"
                  ? "Sedang"
                  : "Rendah"}
              </div>
            </div>

            <div className="mb-2">
              <div className="font-medium">Waktu</div>
              <div className="">
                {detail?.start_date
                  ? moment(detail?.start_date).format("DD MMMM YYYY")
                  : "-"}{" "}
                -{" "}
                {detail?.end_date
                  ? moment(detail?.end_date).format("DD MMMM YYYY")
                  : "-"}
              </div>
            </div>

            <div className="mb-2">
              <div className="font-medium">Lampiran</div>
              {detail.file && (
                <a
                  href={baseurl + detail.file}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block"
                >
                  <img
                    className="object-cover w-20 h-20 rounded-md "
                    src={baseurl + detail.file}
                    alt="keluar"
                  />
                </a>
              )}
            </div>
          </CompCardContainer>
          <br />
          <CompCardContainer>
            <div className="mb-2">
              <div className="font-medium mb-2">Riwayat</div>
              <div>
                {dataRiwayat?.map((item, itemIdx) => (
                  <div key={itemIdx} className="flex min-h-[100px]">
                    <div className="relative w-10 flex flex-col justify-center items-center">
                      <div className="absolute top-0 z-10 w-full h-8 flex justify-center items-center bg-transparen rounded-full">
                        <div className="w-3 h-3 bg-grey-800 rounded-full" />
                      </div>
                      <div className="w-1 h-full bg-grey-800/70" />
                    </div>
                    <div className="flex-1">
                      <div className="h-8 flex items-center">
                        {moment(item.created_at).format("DD MMMM YYYY, H:MM")}{" "}
                        {`(${item.user.nama})`}
                      </div>
                      <div className="font-medium">{item.title}</div>
                      <div className="text-sm mb-2">{item.deskripsi}</div>
                      <div className="flex flex-wrap gap-2 mb-4">
                        {item.image &&
                          item.image.map((itemImage, itemIdx) => (
                            <a
                              key={itemIdx}
                              href={baseurl + itemImage}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-block"
                            >
                              <img
                                className="object-cover w-20 h-20 rounded-md"
                                src={baseurl + itemImage}
                                alt="detail"
                              />
                            </a>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CompCardContainer>
          <br />
        </div>
        <></>
      </CompModal>
    </div>
  );
};

export default PenugasanPage;
