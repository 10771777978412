import React from "react";

const CompCardContainer2 = ({ children, title, icon }) => {
  return (
    <div className={`w-full p-3`}>
      {title && (
        <div className="flex justify-between items-center">
          <div className="flex items-center text-sm font-medium">
            {icon && <span className="mr-2 p-3 rounded-xl">{icon}</span>}
            <span className="pt-2 pb-6">{title}</span>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default CompCardContainer2;
