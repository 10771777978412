import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const CompTabs = ({ tabs }) => {
  return (
    <Fragment>
      <ul className="nav flex flex-row flex-wrap list-none" role="tablist">
        {tabs.listTabs.map((tab, tabIdx) => (
          <li
            key={tabIdx}
            className="nav-item flex-auto sm:flex-none text-center"
            role="presentation"
          >
            <Link
              to={`#${tab.linkTabs}`}
              className={`nav-link rounded-t-xl block text-xs p-3 ${
                tabIdx === 0 ? "active" : ""
              }`}
              data-bs-toggle="pill"
              data-bs-target={`#${tab.linkTabs}`}
            >
              {tab.nameTabs}
            </Link>
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {tabs.listTabs.map((content, contentIdx) => (
          <div
            key={contentIdx}
            className={`tab-pane fade shadow-md sm:rounded-xl rounded-b-xl ${
              contentIdx === 0
                ? "show active rounded-tr-xl rounded-tl-none sm:rounded-tl-none"
                : ""
            } ${
              contentIdx === tabs.listTabs.length - 1 ? "rounded-tr-none" : ""
            } ${
              contentIdx !== tabs.listTabs.length - 1 || contentIdx !== 0
                ? "rounded-t-xl"
                : ""
            }
            `}
            id={content.linkTabs}
            role="tabpanel"
          >
            {content.contentTabs}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default CompTabs;
