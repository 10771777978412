import { isAuthenticated } from "authentication/authenticationApi";
import { CompButton, CompCardContainer, CompForm } from "components";
import { handleInputError, updateFormData } from "pages/actions";
import { API_URL_edeluser } from "pages/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pegawaiReducer } from "reducers/kepegawaianReducers";

const EditProfilePendidikanPage = () => {
  const { addPegawaiResult, addPegawaiLoading } = useSelector(
    (state) => state.kepegawaian
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [inputsDataPendidikan, setInputsDataPendidikan] = useState(
    location.state.data
  );

  //   Funtion
  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsDataPendidikan);
    setInputsDataPendidikan(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (isAuthenticated().user_id !== "") {
        const formData = new FormData();
        formData.append("user_id", isAuthenticated().user_id);
        formData.append(
          "formal",
          JSON.stringify(
            inputsDataPendidikan[0].groupInput.map((item) => ({
              asal_sekolah: item[0].value,
              masa_waktu: item[1].value,
              keterangan_pendidikan: item[2].value,
            }))
          )
        );
        formData.append(
          "non_formal",
          JSON.stringify(
            inputsDataPendidikan[1].groupInput.map((item) => ({
              nama_lembaga: item[0].value,
              tahun_lulus: item[1].value,
              sertifikat: item[2].data
                ? ""
                : item[2].link
                ? item[2].link
                : null,
            }))
          )
        );
        inputsDataPendidikan[1].groupInput.map((item) =>
          formData.append(
            "sertifikat",
            item[2].data ? item[2].data : item[2].link
          )
        );
        updateFormData(
          { dispatch, redux: pegawaiReducer },
          formData,
          API_URL_edeluser,
          "ADD_PEGAWAI",
          "datapendidikan"
        );
      }
    }
  };

  useEffect(() => {
    if (addPegawaiResult) {
      dispatch(
        pegawaiReducer({
          type: "ADD_PEGAWAI",
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      navigate("/profil");
    }
  }, [addPegawaiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer>
        <span
          onClick={() => navigate(-1)}
          className="px-3 py-2 items-center rounded-lg bg-[#f3f4f6] text-xs cursor-pointer text-grey-600"
        >
          Back
        </span>
        <form className="mt-4" onSubmit={doSubmit}>
          <CompForm
            inputs={inputsDataPendidikan}
            setInputs={setInputsDataPendidikan}
          />
          <div className="flex justify-end items-center mt-4">
            <CompButton
              btnName={"Save"}
              doClick={doSubmit}
              onLoading={addPegawaiLoading}
            />
          </div>
        </form>
      </CompCardContainer>
    </div>
  );
};

export default EditProfilePendidikanPage;
