import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import {
  CompButton,
  CompCardContainer,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import { icons } from "atoms/icons";

// functions
import {
  addData,
  convertJSON,
  deleteData,
  getData,
  handleInputError,
  updateData,
} from "pages/actions";
import {
  API_URL_changekey,
  API_URL_createapiclient,
  API_URL_edelapiclient,
  API_URL_getapiaccess,
  API_URL_getapiclient,
  API_URL_getcabang,
} from "pages/constants";
import { apiReducer } from "reducers/apiReducers";
import axiosAPI from "authentication/axiosApi";

const ApiPage = () => {
  const {
    getApiResult,
    getApiLoading,
    getApiError,
    addApiResult,
    addApiLoading,
    deleteApiResult,
  } = useSelector((state) => state.api);
  const dispatch = useDispatch();

  // pagination
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  // States & Variables
  const [detail, setDetail] = useState({});
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "API",
  });
  const [modalDetail, setModalDetail] = useState({
    modalOpen: false,
    modalTitle: "Detail API",
  });
  const [dataColumns] = useState([
    { name: "No", value: "no" },
    { name: "Nama Client", value: "nama_client" },
    { name: "Keterangan", value: "keterangan" },
    { name: "Perusahaan Access", value: "perusahaan", array: "nama" },
    { name: "API Access", value: "api", array: "api" },
    { name: "Active", value: "is_active", switch: true },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "ID",
      name: "id",
      type: "hidden",
      value: "",
    },
    {
      label: "Nama Client",
      name: "nama_client",
      type: "text",
      placeholder: "Input Nama Client",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Keterangan",
      name: "keterangan",
      type: "text",
      placeholder: "Input Keterangan",
      value: "",
      error: "",
    },
    {
      label: "Perusahaan Access",
      name: "perusahaan",
      type: "search-select",
      placeholder: "Input Perusahaan Access",
      value: "",
      error: "",
      option: [],
      isMulti: true,
      required: true,
    },
    {
      label: "API Access",
      name: "api",
      type: "search-select",
      placeholder: "Input API Access",
      value: "",
      error: "",
      option: [],
      isMulti: true,
      required: true,
    },
  ]);

  // Function
  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    get({ param: "?search=" + value });
  };

  const onAdd = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs(
      inputs.map((item) => {
        if (item.type === "hidden") {
          return { ...item, value: "" };
        } else {
          return { ...item, value: "", error: "" };
        }
      })
    );
  };

  const onEdit = (item) => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    const newInput = inputs.map((input, index) => {
      if (index === 3) {
        return {
          ...input,
          value: item[input.name].map((item) => ({
            value: item.id,
            label: item.nama,
          })),
        };
      }
      if (index === 4) {
        return {
          ...input,
          value: item[input.name].map((item) => ({
            value: item.id,
            label: item.api,
          })),
        };
      }
      return { ...input, value: item[input.name] };
    });
    setInputs(newInput);
  };

  const onChangeKey = (item) => {
    addData(
      { dispatch, redux: apiReducer },
      {
        key: item.key,
      },
      API_URL_changekey,
      "ADD_API"
    );
  };

  const onDetail = (item) => {
    setDetail(item);
    console.log(item);
    setModalDetail({
      ...modalDetail,
      modalOpen: !modalDetail.modalOpen,
    });
  };

  const handleSwitch = (e, item, index) => {
    updateData(
      { dispatch, redux: apiReducer },
      {
        pk: item.id,
        nama_client: item.nama_client,
        keterangan: item.keterangan,
        perusahaan: item.perusahaan.map((item) => item.id),
        api: item.api.map((item) => item.id),
        is_active: e.target.checked,
      },
      API_URL_edelapiclient,
      "ADD_API"
    );
  };

  const doDelete = (item) => {
    deleteData(
      { dispatch, redux: apiReducer },
      item.id,
      API_URL_edelapiclient,
      "DELETE_API"
    );
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (inputs[0].value === "") {
        addData(
          { dispatch, redux: apiReducer },
          {
            nama_client: inputs[1].value,
            keterangan: inputs[2].value,
            perusahaan: inputs[3].value.map((item) => item.value),
            api: inputs[4].value.map((item) => item.value),
          },
          API_URL_createapiclient,
          "ADD_API"
        );
      } else {
        updateData(
          { dispatch, redux: apiReducer },
          {
            pk: inputs[0].value,
            nama_client: inputs[1].value,
            keterangan: inputs[2].value,
            perusahaan: inputs[3].value.map((item) => item.value),
            api: inputs[4].value.map((item) => item.value),
          },
          API_URL_edelapiclient,
          "ADD_API"
        );
      }
    }
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: apiReducer },
        param,
        API_URL_getapiclient,
        "GET_API"
      );
    },
    [dispatch]
  );

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? { param: "?limit=" + limit + "&offset=" + offset }
        : {
            param:
              "?search=" + search + "&limit=" + limit + "&offset=" + offset,
          };
    setOffset(offset);
    get(param);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?limit=" + e }
        : {
            param: "?search=" + search + "&limit=" + e,
          };
    get(param);
    setLimit(e);
  };

  const getCabang = async () => {
    try {
      const newInputs = [...inputs];
      const res_cabang = await axiosAPI.get(API_URL_getcabang);
      newInputs[3]["option"] = res_cabang.data.map((item) => ({
        value: item.pk,
        label: item.nama,
      }));

      setInputs(newInputs);
    } catch (error) {
      console.log(error);
    }
  };

  const getApiAccess = async () => {
    try {
      const newInputs = [...inputs];
      const res = await axiosAPI.get(API_URL_getapiaccess);
      newInputs[4]["option"] = res.data.map((item) => ({
        value: item.id,
        label: item.api,
      }));

      setInputs(newInputs);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };
  const handleSearchSelect = (value, index) => {
    const newInputs = [...inputs];
    newInputs[index].value = value;
    setInputs(newInputs);
  };

  const fetchData = useCallback(async () => {
    setModal({ ...modal, modalOpen: false });
    get({ param: "?limit=" + limit });

    getCabang();
    getApiAccess();
  }, [modal, limit, get]);

  // Action Button
  const [actions] = useState([
    {
      name: "edit",
      icon: icons.aifilleye,
      color: "text-grey-700",
      func: onDetail,
    },
    {
      name: "change-key",
      icon: icons.fakey,
      color: "text-yellow-500",
      func: onChangeKey,
    },
    {
      name: "edit",
      icon: icons.fiedit,
      color: "text-blue-500",
      func: onEdit,
    },
    {
      name: "delete",
      icon: icons.rideletebin6line,
      color: "text-red-500",
      func: doDelete,
    },
  ]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (addApiResult) {
      fetchData();
    }
  }, [addApiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (deleteApiResult) {
      fetchData();
    }
  }, [deleteApiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer>
        <CompTableController
          title="Potongan"
          doSearch={doSearch}
          onAdd={onAdd}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={getApiResult.count > 0 ? getApiResult.results : null}
            isLoading={getApiLoading}
            isError={getApiError}
            actions={actions}
            modal={modal}
            offset={offset}
            handleSwitch={handleSwitch}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={getApiResult.count > 0 ? getApiResult.count : 0}
          limit={limit}
          setLimit={handleSelect}
        />
      </CompCardContainer>

      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form
            onKeyPress={(e) =>
              e.key === "Enter" && !addApiLoading && doSubmit(e)
            }
          >
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
                handleSearchSelect={handleSearchSelect}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={doSubmit}
            onLoading={addApiLoading}
          />
        </div>
      </CompModal>

      <CompModal modal={modalDetail} setModal={setModalDetail}>
        <div className="w-full">
          <CompCardContainer>
            <div className="grid grid-cols-2 mb-2">
              <div className="">
                <div className="font-medium">Key</div>
                <div className="">{detail?.key ? detail?.key : "-"}</div>
              </div>
              <div className="">
                <div className="font-medium">Nama Client</div>
                <div className="">
                  {detail?.nama_client ? detail?.nama_client : "-"}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 mb-2">
              <div className="">
                <div className="font-medium">Keterangan</div>
                <div className="">
                  {detail?.keterangan ? detail?.keterangan : "-"}
                </div>
              </div>
              <div className="">
                <div className="font-medium">Perusahaan Access</div>
                <div className="">
                  {detail?.perusahaan?.map(
                    (item, idx) => `${idx !== 0 ? ", " : ""}${item.nama}`
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className="font-medium mb-2">Api Access</div>

              {detail?.api?.map((item, idx) => (
                <div className="border-2 mb-2 p-2 rounded-lg text-sm">
                  <div className="grid grid-cols-2 mb-2">
                    <div>
                      <div className="font-medium">Api</div>
                      <div>{item?.api ? item?.api : "-"}</div>
                    </div>
                    <div>
                      <div className="font-medium">method</div>
                      <div>{item?.method}</div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 mb-2">
                    <div>
                      <div className="font-medium">Url</div>
                      <div>{item?.url ? item?.url : "-"}</div>
                    </div>
                    <div>
                      <div className="font-medium">
                        {convertJSON(item.params).length > 0
                          ? "Params"
                          : "Data"}
                      </div>
                      <div>
                        {convertJSON(item.params).length > 0
                          ? convertJSON(item.params).map(
                              (item, idx) => `${idx !== 0 ? ", " : ""}${item}`
                            )
                          : convertJSON(item.data).map(
                              (item, idx) => `${idx !== 0 ? ", " : ""}${item}`
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </CompCardContainer>
        </div>
        <></>
      </CompModal>
    </div>
  );
};

export default ApiPage;
