import {
  CompButton,
  CompCardContainer,
  CompForm,
  CompModal,
  CompPagination,
  CompTable,
  CompTableController,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { icons } from "atoms/icons";
import { getData, handleInputError } from "pages/actions";
import { kehadiranReducer } from "reducers/kepegawaianReducers";
import {
  API_URL_exportexcelkehadiran,
  API_URL_getdatakehadiran,
} from "pages/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosAPI from "authentication/axiosApi";

const KehadiranPage = () => {
  const { getKehadiranResult, getKehadiranLoading, getKehadiranError } =
    useSelector((state) => state.kepegawaian);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // pagination
  const [limit, setLimit] = useState(10);
  const [firstFetch, setFirstFetch] = useState(false);

  // States & Variables
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState({
    modalOpen: false,
    modalTitle: "Export Excel Kehadiran",
  });
  const [filter, setFilter] = useState(moment(new Date()).format("YYYY-MM"));
  const [dataColumns] = useState([
    { name: "ID", value: "id" },
    { name: "Nama Pegawai", value: "first_name" },
    { name: "Jabatan", value: "jabatan" },
    { name: "Presensi", value: "statistik.hadir" },
    { name: "Cuti", value: "statistik.cuti" },
    { name: "Alfa", value: "statistik.alfa" },
    { name: "Jam Kerja", value: "statistik.jam_kerja" },
    { name: "Jam Terlambat", value: "statistik.jam_terlambat" },
    { name: "Point", value: "statistik.point", progress: true },
  ]);
  const [inputs, setInputs] = useState([
    {
      label: "Dari Tanggal",
      name: "start_date",
      type: "date",
      placeholder: "Input Tanggal",
      value: "",
      error: "",
      required: true,
    },
    {
      label: "Sampai Tanggal",
      name: "end_date",
      type: "date",
      placeholder: "Input Tanggal",
      value: "",
      error: "",
      required: true,
    },
  ]);

  // Function
  const onExport = () => {
    setModal({ ...modal, modalOpen: !modal.modalOpen });
    setInputs(inputs.map((item) => ({ ...item, value: "", error: "" })));
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputs);
    setInputs(newInput);
    const err = newInput.filter((item) => item.error !== "");
    if (err.length === 0) {
      const start = moment(newInput[0].value).format("DD-MM-YYYY");
      const end = moment(newInput[1].value).format("DD-MM-YYYY");
      axiosAPI({
        url: API_URL_exportexcelkehadiran,
        method: "POST",
        data: { start_date: newInput[0].value, end_date: newInput[1].value },
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Data Absensi Pegawai QNN " + start + " - " + end + ".xls"
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
      // const newWindow = window.open(
      //   API_URL_exportexcelkehadiran,
      //   "_blank",
      //   "noopener,noreferrer"
      // );
      // if (newWindow) newWindow.opener = null;
    }
  };

  const doSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLimit(10);
  };

  const doDetail = (item) => {
    navigate("/asesmen/kehadiran/detail", {
      state: { user_id: item.id, date: item.date },
    });
  };

  const get = useCallback(
    async (param) => {
      getData(
        { dispatch, redux: kehadiranReducer },
        param,
        API_URL_getdatakehadiran,
        "GET_KEHADIRAN"
      );
    },
    [dispatch]
  );

  const handleFilterDate = (e) => {
    const param =
      search === ""
        ? { param: "?date-month=" + e + "&limit=" + limit }
        : {
            param: "?search=" + search + "&date-month=" + e + "&limit=" + limit,
          };
    setFilter(e);
    get(param);
  };

  const handlePageClick = (e) => {
    const offset = e.selected * limit;
    const param =
      search === ""
        ? {
            param:
              "?date-month=" + filter + "&limit=" + limit + "&offset=" + offset,
          }
        : {
            param:
              "?search=" +
              search +
              "&date-month=" +
              filter +
              "&limit=" +
              limit +
              "&offset=" +
              offset,
          };
    get(param);
  };

  const handleSelect = (e) => {
    const param =
      search === ""
        ? { param: "?date-month=" + filter + "&limit=" + e }
        : {
            param:
              "?search=" + search + "&date-month=" + filter + "&limit=" + e,
          };
    get(param);
    setLimit(e);
  };

  // Handle Form
  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index]["value"] = e.target.value;
    setInputs(newInputs);
  };

  // Action Button
  const [actions] = useState([
    {
      name: "detail",
      icon: icons.aifilleye,
      color: "text-blue-500",
      func: doDetail,
    },
  ]);

  const fetchData = useCallback(async () => {
    get({ param: "?date-month=" + filter + "&limit=" + limit });
    setFirstFetch(true);
  }, [limit, filter, get]);

  // useEffect
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (firstFetch) {
      const getData = setTimeout(() => {
        get({ param: "?search=" + search + "&date-month=" + filter });
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [search, get]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer>
        <CompTableController
          title="Kehadiran"
          doSearch={doSearch}
          filter={filter}
          onExport={onExport}
          setFilter={handleFilterDate}
        />
        <div className="mt-2">
          <CompTable
            dataColumns={dataColumns}
            dataTables={
              getKehadiranResult.count > 0 ? getKehadiranResult.results : null
            }
            isLoading={getKehadiranLoading}
            isError={getKehadiranError}
            actions={actions}
            modal={modal}
            setModal={setModal}
          />
        </div>
        <CompPagination
          handlePageClick={handlePageClick}
          pageCount={
            getKehadiranResult.count > 0 ? getKehadiranResult.count : 0
          }
          limit={limit}
          setLimit={handleSelect}
        />
      </CompCardContainer>
      <CompModal modal={modal} setModal={setModal}>
        <div className="w-full">
          <form onKeyPress={(e) => e.key === "Enter" && doSubmit(e)}>
            <CompCardContainer>
              <CompForm
                inputs={inputs}
                setInputs={setInputs}
                handleInput={handleInput}
              />
            </CompCardContainer>
          </form>
        </div>
        <div className="flex justify-end items-center">
          <CompButton
            btnName={"Submit"}
            doClick={(e) => doSubmit(e)}
            onLoading={false}
          />
        </div>
      </CompModal>
    </div>
  );
};

export default KehadiranPage;
