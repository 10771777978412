import { isAuthenticated } from "authentication/authenticationApi";
import { CompButton, CompCardContainer, CompForm } from "components";
import { handleInputError, updateData } from "pages/actions";
import { API_URL_edeluser } from "pages/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pegawaiReducer } from "reducers/kepegawaianReducers";

const EditProfilePribadiPage = () => {
  const { addPegawaiResult, addPegawaiLoading } = useSelector(
    (state) => state.kepegawaian
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [inputsDataPribadi, setInputsDataPribadi] = useState(
    location.state.data
  );

  //   Funtion
  const doSubmit = (e) => {
    e.preventDefault();
    const newInput = handleInputError(inputsDataPribadi);
    setInputsDataPribadi(newInput);
    const err = newInput.filter((item) => item.error !== "");

    if (err.length === 0) {
      if (isAuthenticated().user_id) {
        updateData(
          { dispatch, redux: pegawaiReducer },
          {
            pk: "datapribadi",
            user_id: isAuthenticated().user_id,
            nama: inputsDataPribadi[1].value,
            username: inputsDataPribadi[2].value,
            email: inputsDataPribadi[3].value,
            no_identitas: inputsDataPribadi[4].value,
            jenis_kelamin: inputsDataPribadi[5].value,
            no_telepon: inputsDataPribadi[6].value,
            tempat_lahir: inputsDataPribadi[7].value,
            tgl_lahir: inputsDataPribadi[8].value,
            agama: inputsDataPribadi[9].value,
            npwp: inputsDataPribadi[10].value,
            alamat_ktp: inputsDataPribadi[11].value,
            alamat_domisili: inputsDataPribadi[12].value,
            cabang_id: inputsDataPribadi[13].value,
          },
          API_URL_edeluser,
          "ADD_PEGAWAI"
        );
      }
    }
  };

  // Handle Form
  const HandleInputsDataPribadi = (e, index) => {
    const { value, name } = e.target;
    const newInputs = [...inputsDataPribadi];
    if (newInputs[0].value === "") {
      if (name === "nama") {
        newInputs[2]["value"] = value.toLowerCase().replace(" ", "");
      }
    }
    newInputs[index]["value"] = value;
    setInputsDataPribadi(newInputs);
  };

  useEffect(() => {
    if (addPegawaiResult) {
      dispatch(
        pegawaiReducer({
          type: "ADD_PEGAWAI",
          payload: {
            loading: false,
            data: false,
          },
        })
      );
      navigate("/profil");
    }
  }, [addPegawaiResult, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CompCardContainer>
        <span
          onClick={() => navigate(-1)}
          className="px-3 py-2 items-center rounded-lg bg-[#f3f4f6] text-xs cursor-pointer text-grey-600"
        >
          Back
        </span>
        <form className="mt-4" onSubmit={doSubmit}>
          <CompForm
            inputs={inputsDataPribadi}
            setInputs={setInputsDataPribadi}
            handleInput={HandleInputsDataPribadi}
          />
          <div className="flex justify-end items-center mt-4">
            <CompButton
              btnName={"Save"}
              doClick={doSubmit}
              onLoading={addPegawaiLoading}
            />
          </div>
        </form>
      </CompCardContainer>
    </div>
  );
};

export default EditProfilePribadiPage;
