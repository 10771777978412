import React, { Fragment } from "react";
import PropTypes from "prop-types";

// components
import { CompCardContainer } from "components";

const propTypes = {
	nodeData: PropTypes.object.isRequired,
};

const CompCardOrgChart = ({ nodeData }) => {
	return (
		<Fragment>
			<div className="flex justify-center text-xs">
				<div className="w-32 rounded">
					<CompCardContainer>
						{nodeData.fotoProfil && (
							<div className="flex mb-2 justify-center w-full h-28 overflow-clip">
								<img
									className="w-full rounded object-cover object-center"
									src={nodeData.fotoProfil}
									alt="Profil"
								/>
							</div>
						)}
						<div className="font-bold">{nodeData.nama}</div>
						{nodeData.jabatan && <span>{nodeData.jabatan}</span>}
					</CompCardContainer>
				</div>
			</div>
		</Fragment>
	);
};

CompCardOrgChart.propTypes = propTypes;

export default CompCardOrgChart;
